import React from "react";
import styles from '../../assets/scss/components/variables.module.scss';

const GlobalVariable = (props) => {
    return (
        <div className={styles.local__variable}>
            <div className="d-flex align-items-center mb-3">
                <h5 className="flex-grow-1">Global Variable</h5>
            </div>
            {   props?.global?.length ?
                props.global.map((variable, index) => {
                    return (
                        <div key={index} className={styles.variable__item}>
                            <p className="mb-0 flex-grow-1">{variable.name}</p>
                        </div>
                    )
                }) :
                <p>No Global Variables Found</p>
            }
        </div>
    )      
}
export default GlobalVariable;