import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist'
import axiosInstance from "../plugins/axios";

const { persistAtom } = recoilPersist();

export const journeyListState = atom({
    key: 'journeyList',
    default: [],
    effects_UNSTABLE: [persistAtom],
});


export const variableListState = atom({
    key: 'VariableList',
    default: [],
    effects_UNSTABLE: [persistAtom],
});

export const getVariableList = selector({
    key: 'getVariableList',
    get: async () => {
        const response = await axiosInstance.get('/api/v1/communications/whatsapp/variables/?is_global=true');
        const data = response.data;
        return data;
    },
});

//create confimation dialog model state
export const confirmationDialogState = atom({
    key: 'confirmationDialog',
    default: {
        open: false,
        title: '',
        message: '',
        path: '/',
        onConfirm: () => {},
        onCancel: () => {}
    }
});

export const AlertOpenState = atom({
    key: 'AlertOpen',
    default: {
        open: false,
        timing: 3000,
        msgType: 'success',
        alertMsg: '',
        position: {vertical: 'top', horizontal: 'right'},
        variant: 'filled'
    }
});

export const getPaginationState = atom({
    key: 'getPagination',
    default: {
        count: 0,
        color: 'primary',
        defaultPage: 1,
        showFirstButton: false,
        showLastButton: false,
        page: 1,
        onPageChange: (event, value) => {
            console.log(event.target.textContent)
            console.log(value)
        }
    }
});

//create journey name state
export const journeyNameState = atom({
    key: 'journeyName',
    default: {
        id: null,
        name: ''
    },
    effects_UNSTABLE: [persistAtom],
})
  

// export const getTriggerConditionalList = selector({
//     key: 'getTriggerConditionalList',
//     get: async () => {
//         const response = await axiosInstance.get('/api/v1/communications/whatsapp/available-intent-for-association/');
//         const data = response.data;
//         return data;
//     },
// });

export const triggerConditionalListState = atom({
    key: 'triggerConditionalList',
    default: [],
});