import React, { useState, useEffect } from 'react';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import { useRecoilState } from 'recoil';
import { isFormSubmittedState, textVariableListState, nodesState, editTypesState } from '../../store/state';
import { debouncedHandleText } from '../../mixins/journey'
import DataObjectIcon from '@mui/icons-material/DataObject';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const MinHeightTextarea = styled(BaseTextareaAutosize)(
  ({ theme, error, isBlurred=false }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${error && isBlurred ? 'red' : (theme.palette.mode === 'dark' ? grey[700] : grey[200])};
    box-shadow: none;
    transition: border-color 0.3s;

    &:hover {
      border-color: ${error && isBlurred ? 'red' : blue[400]};
    }

    &:focus {
      border-color: ${error && isBlurred ? 'red' : blue[400]};
      box-shadow: none;
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

const ErrorText = styled('p')`
  color: red;
  font-size: 0.75rem;
  margin-bottom: 0px;
`;

const VariableList = styled('ul')`
  list-style-type: none;
  padding: 0;
  margin: 0;

  li:first-of-type {
    margin-top: 4px;
  }

  li {
    padding: 6px;
    transition: background-color 0.3s;

    &:hover {
      background-color: ${blue[100]};
      cursor: pointer;
    }
  }
`;

const TextArea = ({ value, onChange, onFocus, onBlur, error, errorMessage, onAddVariable, showVariable=false, ...rest }) => {
	const [isBlurred, setIsBlurred] = useState(false);
	const [formSubmitted, setFormSubmitted] = useRecoilState(isFormSubmittedState);
	const [anchorEl, setAnchorEl] = useState(null);
	const [textVariableList, setTextVariableList] = useRecoilState(textVariableListState);
	const [searchQuery, setSearchQuery] = useState('');
	const [nodes, setNodes] = useRecoilState(nodesState);
	const [editTypes, setEditTypes] = useRecoilState(editTypesState);
	const findIndex = nodes.findIndex(item => item.id === editTypes.selected_id);

	useEffect(() => {
		if (formSubmitted) {
		setIsBlurred(true);
		}
	}, [formSubmitted]);

	const handleBlur = (e) => {
		setIsBlurred(true);
		if (onBlur) onBlur(e);
	};

	const openVariable = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSearch = (e) => {
		setSearchQuery(e.target.value);
	};

	const addTextVariable = ({value}) => {
		const message = nodes[findIndex]?.message_to_send?.template?.message || '';
		const updatedMessage = `${message} ${value} `;
		onAddVariable(updatedMessage);
		debouncedHandleText(findIndex, nodes, setNodes)(updatedMessage); 
		setAnchorEl(null); // Hide the popover after selecting a variable
	};
  

  const filteredVariableList = textVariableList.filter((variable) =>
    variable.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const open = Boolean(anchorEl);
  const id = open ? 'variable-popover' : undefined;

  return (
    <>
      <div style={{ position: 'relative' }}>
        <MinHeightTextarea
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={handleBlur}
          error={error}
          isBlurred={isBlurred}
          {...rest}
          required
        />
        {error && isBlurred && errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        {
          showVariable &&
          <Tooltip title="Add Variable" className="cursor-pointer" style={{ position: 'absolute', right: 10, top: -32 }}>
            <IconButton className="primary_icon cursor-pointer" onClick={openVariable}>
              <DataObjectIcon />
            </IconButton>
          </Tooltip>
        }
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box sx={{ p: 2 }}>
            <input type="text" placeholder="Search..." onChange={handleSearch} />
            <VariableList>
              {filteredVariableList.map((variable, index) => (
                <li key={index} onClick={() => addTextVariable(variable)}>{variable.name}</li>
              ))}
            </VariableList>
          </Box>
        </Popover>
      </div>
    </>
  );
};

export default TextArea;
