import React, { useEffect, Suspense, useState } from "react";
import { useRecoilState } from 'recoil';
import styles from '../../assets/scss/page/optins-page.module.scss';
import Loading from "../../components/basic-components/Loading";
import { Search, X } from 'react-bootstrap-icons';
import { openCreateOptinsBoxState, optinsListState} from '../../store/optins'
import OptinsUpload from "../../components/optins/optins-upload";
import { getPaginationState } from '../../store/journey-list';
import axiosInstance from "../../plugins/axios";
import { debounce } from 'lodash';
import BasicPagination from "../../components/basic-components/basic-pagination";
import { useNavigate } from 'react-router-dom';
import OptInList from "../../components/optins/optin-lists";
import Cookies from 'js-cookie';

const OptIns = () => {
	const [search, setSearchText] = useState('');
	const [openDialog, setOpenDialog] = useRecoilState(openCreateOptinsBoxState);
	const [loading, setLoading] = useState(false);
	const [list, setList] = useRecoilState(optinsListState);
	const [pagination, setPagination] = useRecoilState(getPaginationState);

	const navigate = useNavigate();

    const query = new URLSearchParams(location.search);
	const page = parseInt(query.get('page') || '1', 10);

	useEffect(() => {
        try {
            const token = Cookies.get('Token');
            if(!token){
                localStorage.removeItem('Token');
                window.location.href = '/login'
            }
            else{
                const fetchData = async () => {
                    try {
                        setLoading(true);
                        const {data} = await axiosInstance.get(`/api/v1/communications/whatsapp/users/optins/?limit=20&offset=${(page - 1) * 20}`);
                        setPagination({
                            ...pagination,
                            count: data.count,
                            onPageChange: (event, value) => onPageChange(event, value),
                            defaultPage: page,
                            page: page,
                            path: '/opt-ins/?'
                        })
                        setList(data.results);
                        setLoading(false);
                    } 
                    catch (error) {
                        setLoading(false);
                        console.error('Error fetching data:', error);
                    }
                };
                fetchData();  
            }
        } 
        catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
        }
    }, []);

	const onPageChange = async (event, value) => {
        try{
            setLoading(true);
            setSearchText('');
            const res = await axiosInstance.get(`/api/v1/communications/whatsapp/users/optins/?limit=20&offset=${(value-1) * 20}`);
            setPagination({
                ...pagination,
                page: value,
                count: res.data.count,
                path: '/opt-ins/?',
                onPageChange: (event, value) => onPageChange(event, value)
            })
            setList(res.data.results);
            setLoading(false);
        }catch(error){
            setLoading(false);
            console.log(error)
        }
    }

	const handleSearchDebounce = debounce( async(e) => {
        try {
            setLoading(true);
            if(e.target.value === '') return handleResetSearch();
            //go to page 1st
            navigate(`/opt-ins/?page=1`);
            const { data } = await axiosInstance.get(`/api/v1/communications/whatsapp/users/optins/?name=${e.target.value}`)
            setPagination({
                ...pagination,
                count: data.count,
                onPageChange: (event, value) => onPageChange(event, value),
                defaultPage: page,
                page: page,
                path: '/opt-ins/?'
            })
            setList(data.results);
            setLoading(false);
        } 
        catch (error) {
            setLoading(false);
            console.log(error)
        }
    }, 300)


	const handleSearch = (e) => {
        try {
            setLoading(true);
            setSearchText(e.target.value);
            if(e.target.value === '') {
                handleResetSearch()
            }else{
                handleSearchDebounce(e)
            }
        } 
        catch (error) {
            setLoading(false);
            console.log(error)
        }
    };
	
	const handleResetSearch = async () => {
		setSearchText('')
		try {
			const {data} = await axiosInstance.get(`/api/v1/communications/whatsapp/users/optins/?limit=20&offset=${(page - 1) * 20}`);
			setPagination({
				...pagination,
				count: data.count,
				onPageChange: (event, value) => onPageChange(event, value),
				defaultPage: page,
				page: page,
				path: '/opt-ins/?'
			})
			setList(data.results);
			setLoading(false);
		} 
		catch (error) {
			setLoading(false);
			console.error('Error fetching data:', error);
		}  
		setLoading(false);
	}

	const handleCreateNew = () => {
		setOpenDialog({
			open: true,
			type: 'create',
			title: 'Add New Opt-Ins',
		});
	}
  	return (
		<>
			<div className={styles.model__list__container}>
				<h4>Opt-Ins</h4>
				<p>Opt-in the user numbers, once user has opted out user can't be opted-in again until user asks.</p>
				<div className="d-flex align-items-center">
					<div className="flex-grow-1">
						<div className={styles.optins__search}>
							<div>
								<input 
									type="text" 
									placeholder="Search Opt-Ins" 
									onChange={handleSearch}
									value={search}
								/>
							</div>
							<div className="cursor-pointer">
								{
									search.length > 0 &&
									<X size={30} onClick={handleResetSearch} />
								}
							</div>
							{search.length === 0 && <div>
								<Search />
							</div>}
						</div>
					</div>
					<div>
						<button  className={styles.create__optins__btn} onClick={handleCreateNew}>Add Opt-Ins</button>
					</div>
				</div>
			</div>
			<div className={styles.model__list__container} style={{ marginTop: '20px' }}>
				{ !loading ? list.length ? 
					<div className="">
						{list.map((item, index) => (
							<div key={item.id} className="mb-2">
								<OptInList item={item} index={index} />
							</div>
						))}
					</div> : 
					<p className="text-center">No Opt-Ins Found</p>
				: <p className="text-center">Loading...</p> }
				<div className="mt-4">
					<BasicPagination />
				</div>
			</div>
		</>
	);
}


const SuspenseOptinsList = () => (
    <Suspense 
        fallback={ <Loading /> }
    >
        <OptIns />
        <OptinsUpload />
    </Suspense>
);

export default SuspenseOptinsList;