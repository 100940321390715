import React, { useEffect, Suspense, useState } from "react";
import { useRecoilState } from 'recoil';
import styles from '../../assets/scss/page/template-page.module.scss';
import Loading from "../../components/basic-components/Loading";
import { templateListState, hiddenState } from '../../store/template'
import { getPaginationState } from '../../store/journey-list';
import axiosInstance from "../../plugins/axios";
import { debounce } from 'lodash';
import BasicPagination from "../../components/basic-components/basic-pagination";
import TemplateList from "../../components/template/templateList";
import { openCreateTemplateBoxState } from '../../store/template';
import TemplateCreateModal from "../../components/template/template-create-modal";
import { Search, X } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Cookies from 'js-cookie';

const TemplatePage = () => {
    const [list, setList] = useRecoilState(templateListState);
    const [search, setSearchText] = useState('');
    const [pagination, setPagination] = useRecoilState(getPaginationState);
    const [openDialog, setOpenDialog] = useRecoilState(openCreateTemplateBoxState);
    const [loading, setLoading] = useState(false);
    const [tabs, setTabs] = useState('active');
    const [hidden, setHidden] = useRecoilState(hiddenState);

    const navigate = useNavigate();

    const query = new URLSearchParams(location.search);
	const page = parseInt(query.get('page') || '1', 10);

    const handleTabChange = (event, newValue) => {
        setTabs(newValue);
        // getTemplateListData()
    }
    
    useEffect(() => {
        const token = Cookies.get('Token');
        if(!token){
            localStorage.removeItem('Token');
            window.location.href = '/login'
        }
        else{
            setHidden(tabs === 'hidden')
        }
    }, [tabs]);

    useEffect(() => {
        const token = Cookies.get('Token');
        if(!token){
            localStorage.removeItem('Token');
            window.location.href = '/login'
        }
        else{
            getTemplateListData();
        }
    }, [hidden]);

    useEffect(() => {
        try {
            const fetchData = async () => {
                try {
                    setLoading(true);
                    // const {data} = await axiosInstance.get(`/api/v1/communications/whatsapp/campaigns/templates/list/?limit=20&offset=${(page - 1) * 20}&is_hidden=${hidden}`);
                    // setPagination({
                    //     ...pagination,
                    //     count: data.count,
                    //     onPageChange: (event, value) => onPageChange(event, value),
                    //     defaultPage: page,
                    //     page: page,
                    //     path: '/templates/?'
                    // })
                    // setList(data.results);
                    getTemplateListData();
                    setLoading(false);
                } 
                catch (error) {
                    setLoading(false);
                    console.error('Error fetching data:', error);
                }
            };
            fetchData();
        } 
        catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
        }
    }, []);

    const getTemplateListData = async () => {
        const {data} = await axiosInstance.get(`/api/v1/communications/whatsapp/campaigns/templates/list/?limit=20&offset=${(page - 1) * 20}&is_hidden=${hidden}`);
        setPagination({
            ...pagination,
            count: data.count,
            onPageChange: (event, value) => onPageChange(event, value),
            defaultPage: page,
            page: page,
            path: '/templates/?'
        })
        setList(data.results);
    }

    const onPageChange = async (event, value) => {
        try{
            setLoading(true);
            setSearchText('');
            const res = await axiosInstance.get(`/api/v1/communications/whatsapp/campaigns/templates/list/?limit=20&offset=${(value-1) * 20}&is_hidden=${hidden}`);
            setPagination({
                ...pagination,
                page: value,
                count: res.data.count,
                path: '/templates/?',
                onPageChange: (event, value) => onPageChange(event, value)
            })
            setList(res.data.results);
            setLoading(false);
        }catch(error){
            setLoading(false);
            console.log(error)
        }
    }

    const handleSearchDebounce = debounce( async(e) => {
        try {
            setLoading(true);
            if(e.target.value === '') return handleResetSearch();
            //go to page 1st
            navigate(`/templates/?page=1`);
            const { data } = await axiosInstance.get(`/api/v1/communications/whatsapp/campaigns/templates/list/?name=${e.target.value}&is_hidden=${hidden}`)
            setPagination({
                ...pagination,
                count: data.count,
                onPageChange: (event, value) => onPageChange(event, value),
                defaultPage: page,
                page: page,
                path: '/templates/?'
            })
            setList(data.results);
            setLoading(false);
        } 
        catch (error) {
            setLoading(false);
            console.log(error)
        }
    }, 300)

    const handleSearch = (e) => {
        try {
            setLoading(true);
            setSearchText(e.target.value);
            if(e.target.value === '') {
                handleResetSearch()
            }else{
                handleSearchDebounce(e)
            }
        } 
        catch (error) {
            setLoading(false);
            console.log(error)
        }
    };

    const handleResetSearch = async () => {
        try {
            setSearchText('');
            try {
                const {data} = await axiosInstance.get(`/api/v1/communications/whatsapp/campaigns/templates/list/?limit=20&offset=${(page - 1) * 20}&is_hidden=${hidden}`);
                setPagination({
                    ...pagination,
                    count: data.count,
                    onPageChange: (event, value) => onPageChange(event, value),
                    defaultPage: page,
                    page: page,
                    path: '/templates/?'
                })
                setList(data.results);
                setLoading(false);
            } 
            catch (error) {
                setLoading(false);
                console.error('Error fetching data:', error);
            }  
            setLoading(false);
        } 
        catch (error) {
            setLoading(false);
            console.log(error)
        }
    };
    const handleCreateNew = () => {
        setOpenDialog({
            open: true,
            type: 'create',
            title: 'Add New Template',
            id: '',
        });
    }

    return (
        <>
            <div className={styles.model__list__container}>
                <h4>Template List</h4>
                <p>View the list of templates you will be using while sending out broadcast/campaign. The template should be registered with WhatsApp/Meta.</p>
                <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                        <div className={styles.template__search}>
                            <div>
                                <input 
                                    type="text" 
                                    placeholder="Search Template" 
                                    onChange={handleSearch}
                                    value={search}
                                />
                            </div>
                            <div className="cursor-pointer">
                                {
                                    search.length > 0 &&
                                    <X size={30} onClick={handleResetSearch} />
                                }
                            </div>
                            {search.length === 0 && <div>
                                <Search />
                            </div>}
                        </div>
                    </div>
                    <div>
                        <button onClick={handleCreateNew} className={styles.create__template__btn}>Create Template</button>
                    </div>
                </div>
            </div>
            <div className={styles.model__list__container} style={{ marginTop: '20px' }}>
            <Tabs   value={tabs} 
                    onChange={handleTabChange} 
                    aria-label="Template List"
                    className='mb-2' 
                    style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff' }}
                    >
                    <Tab value="active" label="Active" />
                    <Tab value="hidden" label="Hidden" />
            </Tabs>
                { !loading ? list.length ? 
                    <div className="mt-4">
                        {list.map((item, index) => (
                            <div key={item.id} className="mb-2">
                                <TemplateList item={item} index={index} />
                            </div>
                        ))}
                    </div> : 
                    <p className="text-center mt-4">No Template Found</p>
                 : <p className="text-center mt-4">Loading...</p> 
                }
                {/* {tabs === 'hidden' && (
                <div><h4>Hidden</h4></div> 
                )} */}
                <div className="mt-4">
                    <BasicPagination />
                </div>
            </div>
        </>
    );
};

const SuspenseTemplateList = () => (
    <Suspense 
        fallback={ <Loading /> }
    >
        <TemplatePage />
        <TemplateCreateModal />
    </Suspense>
);

export default SuspenseTemplateList;
