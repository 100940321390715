import React, { useState } from "react";
import styles from '../../assets/scss/components/login.module.scss';
import axiosInstance from "../../plugins/axios";
import Cookies from 'js-cookie';
import { AlertOpenState } from '../../store/journey-list';
import { useRecoilState } from 'recoil';
import { userState, credentialState } from '../../store/auth';
import AlertDialog from '../../components/basic-components/alert-dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import logo from '../../assets/images/logo.webp';

const LoginPage = () =>{
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [values, setValues] = useRecoilState(AlertOpenState);
    const [user, setUser] = useRecoilState(userState);
    const [crediential, setCredential] = useRecoilState(credentialState);
    const [projectType, setProjectType] = useState(true);
    
    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            if(projectType){
                localStorage.setItem('project_type', 'Prod')
            }
            else{
                localStorage.setItem('project_type', 'Dev')
            }
            setLoading(true);
            const payload = {
                email: email,
                password: password
            }
            let url = projectType ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_DEV
            const res = await axiosInstance.post(`${url}/api/v1/accounts/login/`, payload);    
            const { data } = res        
            if(res.status === 200 || res.status === 200){
                localStorage.setItem('Token', data.key)
                Cookies.set('Token', data.key, { expires: 1 });
                setCredential(data)

                const res1 = await axiosInstance.get(`${url}/api/v1/admin_custom/`, {
                    headers: {
                        Authorization: `Token ${data.key}`
                    }
                });
                setUser(res1.data);
                setLoading(false);
                window.location.href = '/';
            }
            else{
                setLoading(false);
                setValues({ ...values, open: true, msgType: 'error', alertMsg: 'Something went wrong.' })
            }
        } 
        catch (error) {
            console.log(error)
            setLoading(false);
            setValues({ ...values, open: true, msgType: 'error', alertMsg: 'Something went wrong.' })
        }
    }

    const handleShowPassword = () => {
        const passwordInput = document.getElementById('password');
        if(showPassword){
            passwordInput.type = 'password';
            setShowPassword(false);
        }
        else{
            passwordInput.type = 'text';
            setShowPassword(true);
        }
    }

    const handleProjectType = (e) => {
        setProjectType(e.target.checked)
        if(e.target.checked === true){
            localStorage.setItem('project_type', 'Prod')
        }
        else{
            localStorage.setItem('project_type', 'Dev')
        }
    }


    return (
        <>
            <div className={styles.login__container}>
                <div className="d-flex flex-column align-items-center">
                    <img src={logo} alt="logo" className={styles.logo} style={{ 'width': '150px'}} /> 
                    <span className='ml-2 '>Engage</span>
                </div>

                <h2 className="mt-3">Login</h2>

                <input 
                    type="email" 
                    placeholder="Email" 
                    required 
                    className={styles.email__input}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input 
                    type="password" 
                    id="password" 
                    placeholder="Password" 
                    required 
                    className={styles.password__input}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <div className={styles.show__password}>
                    <Tooltip title="Select a login type: Development or Production." arrow placement="top">
                        <FormControlLabel
                            control={
                                <IOSSwitch 
                                    sx={{ m: 1 }} 
                                    defaultChecked 
                                    onChange={handleProjectType}
                                />
                            }
                            label={projectType ? 'Prod' : 'Dev'}
                        />
                    </Tooltip>
                    <div>
                        <input 
                            type="checkbox" 
                            id="showPassword" 
                            className={styles.show__password__checkbox}
                            onChange={handleShowPassword}
                        />
                        <label htmlFor="showPassword" className={styles.show__password__label}>
                            Show Password
                        </label>
                    </div>
                </div>

                <button 
                    type="submit"
                    className={styles.submit__btn}
                    onClick={handleSubmit}
                >
                    {
                        loading ? 
                        <div className="spinner-border" role="status"></div> : 
                        'Login'
                    }
                </button>
            </div>
            <AlertDialog />
        </>
    );
}

export default LoginPage;



const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }
));