import React, { useEffect, Suspense, useState } from "react";
import styles from '../../assets/scss/page/broadcasts.module.scss';
import Loading from "../../components/basic-components/Loading";
import { useRecoilState } from 'recoil';
import { broadcastListState, openCreateBroadcastBoxState } from '../../store/broadcasts';
import { getPaginationState } from '../../store/journey-list'
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../plugins/axios';
import CreateBroadcasts from "../../components/broadcasts/create-broadcast";
import { debounce } from 'lodash';
import BasicPagination from "../../components/basic-components/basic-pagination";
import BroadcastList from "../../components/broadcasts/broadcast-list";
import Cookies from 'js-cookie';

const Broadcasts = () => {
    const [list, setList] = useRecoilState(broadcastListState);
    const [search, setSearchText] = useState('');
    const [pagination, setPagination] = useRecoilState(getPaginationState);
    const [openDialog, setOpenDialog] = useRecoilState(openCreateBroadcastBoxState);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const query = new URLSearchParams(location.search);
	const page = parseInt(query.get('page') || '1', 10);

    useEffect(() => {
        try {
            const token = Cookies.get('Token');
            if(!token){
                localStorage.removeItem('Token');
                window.location.href = '/login'
            }
            else{
                const fetchData = async () => {
                    try {
                        setLoading(true);
                        const {data} = await axiosInstance.get(`/api/v1/communications/whatsapp/campaigns/?limit=20&offset=${(page - 1) * 20}`);
                        setPagination({
                            ...pagination,
                            count: data.count,
                            onPageChange: (event, value) => onPageChange(event, value),
                            defaultPage: page,
                            page: page,
                            path: '/broadcasts/?'
                        })
                        console.log(data)
                        setList(data.results);
                        setLoading(false);
                    } 
                    catch (error) {
                        setLoading(false);
                        console.error('Error fetching data:', error);
                    }
                };
                fetchData();  
            }
        } 
        catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
        }
    }, []);

    const handleSearchDebounce = debounce( async(e) => {
        try {
            setLoading(true);
            if(e.target.value === '') return handleResetSearch();
            //go to page 1st
            navigate(`/broadcasts/?page=1`);
            const { data } = await axiosInstance.get(`/api/v1/communications/whatsapp/campaigns/?name=${e.target.value}`)
            setPagination({
                ...pagination,
                count: data.count,
                onPageChange: (event, value) => onPageChange(event, value),
                defaultPage: page,
                page: page,
                path: '/broadcasts/?'
            })
            setList(data.results);
            setLoading(false);
        } 
        catch (error) {
            setLoading(false);
            console.log(error)
        }
    }, 300)

    const handleSearch = (e) => {
        try {
            setLoading(true);
            setSearchText(e.target.value);
            if(e.target.value === '') {
                handleResetSearch()
            }else{
                handleSearchDebounce(e)
            }
        } 
        catch (error) {
            setLoading(false);
            console.log(error)
        }
    };

    const handleResetSearch = async () => {
        try {
            setSearchText('');
            try {
                const {data} = await axiosInstance.get(`/api/v1/communications/whatsapp/campaigns/?limit=20&offset=${(page - 1) * 20}`);
                setPagination({
                    ...pagination,
                    count: data.count,
                    onPageChange: (event, value) => onPageChange(event, value),
                    defaultPage: page,
                    page: page,
                    path: '/broadcasts/?'
                })
                setList(data.results);
                setLoading(false);
            } 
            catch (error) {
                setLoading(false);
                console.error('Error fetching data:', error);
            }  
            setLoading(false);
        } 
        catch (error) {
            setLoading(false);
            console.log(error)
        }
    };

    const handleCreateNew = () => {
		setOpenDialog({
			open: true,
			type: 'create',
			title: 'Add New Broadcasts',
		});
	}

    return (
        <>
            <div className={styles.broadcast__list__container}>
                <h4>Broadcasts</h4>
                <p>Send or schedule a broadcast/campaign.</p>
                <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                        <div className={styles.broadcasts__search}>
                            <div>
                                <input 
                                    type="text" 
                                    placeholder="Search Broadcasts" 
                                    onChange={handleSearch}
                                    value={search}
                                />
                            </div>
                            <div className="cursor-pointer">
                                {/* {
                                    search.length > 0 &&
                                    <X size={30} onClick={handleResetSearch} />
                                } */}
                            </div>
                            {/* {search.length === 0 && <div>
                                <Search />
                            </div>} */}
                        </div>
                    </div>
                    <div>
                        <button  className={styles.create__broadcasts__btn} onClick={handleCreateNew}>Add Broadcasts</button>
                    </div>
                </div>
            </div>
            <div className={styles.broadcast__list__container} style={{ marginTop: '20px' }}>
                { !loading ? list.length ? 
                    <div className="">
                        {list.map((item, index) => (
                            <div key={item.id} className="mb-2">
                                <BroadcastList item={item} index={index} />
                            </div>
                        ))}
                    </div> : 
                    <p className="text-center">No Broadcast Found</p>
                 : <p className="text-center">Loading...</p> }
                <div className="mt-4">
                    <BasicPagination />
                </div>
            </div>
        </>
    )
}

const SuspenseBroadcastList = () => (
    <Suspense 
        fallback={ <Loading /> }
    >
        <Broadcasts />
        <CreateBroadcasts />
    </Suspense>
);

export default SuspenseBroadcastList;

