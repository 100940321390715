import React from 'react'
import styles from '../../assets/scss/components/optins-status.module.scss';
import { Whatsapp } from 'react-bootstrap-icons'
import AdsClickIcon from '@mui/icons-material/AdsClick';
import { useTheme } from '@mui/material/styles';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const OptInStatus = ({item}) => {
    const theme = useTheme();

    const downloadFile = () => {
        const link = document.createElement('a');
        link.href = item.file;
        link.download = ''; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    
    return (
        <>
            <div className={styles.optins__grid}>
                <div className={styles.optins__source}>
                    Whatsapp <Whatsapp className='' color='#4bcb59' />
                </div>
                <div className={styles.optins__status}>
                    <div className='' style={{borderRight: '1px solid #e5e7eb', paddingRight: '1rem'}}>
                        {/* <div className={styles.optins__layout}>
                        </div> */}
                        <div className="status_card mt-2">
                            <div className='d-flex p-2 justify-content-between align-items-center'>
                                <div className={styles.optins__number}>{item.target}</div>
                                <div className={styles.optins__icon}>
                                    <AdsClickIcon  style={{ color: theme.palette.primary.dark, fontSize: '2.5rem', backgroundColor: theme.palette.primary.extraLight, borderRadius: '50%', padding: '4px'}} />
                                </div>
                            </div>
                            <div className={styles.optins__title}>
                                Targeted
                            </div>
                        </div>
                        <div className="status_card mt-2">
                            {/* Start Date: <br />
                            <small>{item.start_date}</small> */}
                            <div className='d-flex p-2 justify-content-between align-items-center'>
                                <div className={styles.optins__text}>START DATE:</div>
                                <div className={styles.optins__icon}>
                                    <CalendarMonthIcon  style={{ color: theme.palette.primary.dark, fontSize: '2.5rem', backgroundColor: theme.palette.primary.extraLight, borderRadius: '50%', padding: '4px'}} />
                                </div>
                            </div>
                            <div className={styles.optins__title}>
                                {item.start_date}
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div className="status_card mt-2">
                            <div>
                                <div className='d-flex p-2 justify-content-between align-items-center'>
                                    <div className={styles.optins__text}>{item?.current_request_status}</div>
                                    <div className={styles.optins__icon}>
                                        {
                                            item?.current_request_status === 'SUCCESS' ? <CheckCircleIcon  style={{ color: '#4bcb59', fontSize: '2.5rem', backgroundColor: '#e8f8e8', borderRadius: '50%', padding: '4px'}} /> :
                                            <CheckCircleIcon  style={{ color: '#ff0000', fontSize: '2.5rem', backgroundColor: '#ffe8e8', borderRadius: '50%', padding: '4px'}} />
                                        }
                                    </div>
                                </div>
                                <div className={styles.optins__title}>
                                    Current Status
                                </div>
                            </div>
                        </div>
                        <div className="status_card download_card mt-2">
                            <div className='d-flex p-2 justify-content-between align-items-center cursor-pointer' onClick={downloadFile}>
                                <div style={{color: '#3f3f3f', fontWeight: '600'}}>DOWNLOAD</div>
                                <CloudDownloadIcon style={{ color: theme.palette.primary.dark, fontSize: '2.5rem', backgroundColor: theme.palette.primary.extraLight, borderRadius: '50%', padding: '6px'}} />
                            </div>
                            <div className={styles.optins__title}>
                                Attached File
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div className="status_card mt-2">
                            <div>
                                <div className='d-flex p-2 justify-content-between align-items-center'>
                                    <div className={styles.optins__number}>{item?.data?.opted_in}</div>
                                    <div className={styles.optins__icon}>
                                        <PersonAddAltIcon  style={{ color: '#4bcb59', fontSize: '2.5rem', backgroundColor: '#e8f8e8', borderRadius: '50%', padding: '4px'}} />
                                    </div>
                                </div>
                                <div className={styles.optins__title}>
                                    Opt-in
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div className="status_card mt-2">
                            <div>
                                <div className='d-flex p-2 justify-content-between align-items-center'>
                                    <div className={styles.optins__number}>{item?.data?.opted_out}</div>
                                    <div className={styles.optins__icon}>
                                        <PersonRemoveIcon  style={{ color: '#ff0000', fontSize: '2.5rem', backgroundColor: '#ffe8e8', borderRadius: '50%', padding: '4px'}} />
                                    </div>
                                </div>
                                <div className={styles.optins__title}>
                                    Opt-out
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default OptInStatus
