import React from "react";
import styles from '../../assets/scss/components/logout.module.scss';
import { useRecoilState } from "recoil";
import { userState } from "../../store/auth";
import { AlertOpenState } from '../../store/journey-list'
import axiosInstance from "../../plugins/axios";
import Cookies from 'js-cookie';

const Logout = () => {
    const [user, setUser] = useRecoilState(userState);
    const [values, setValues] = useRecoilState(AlertOpenState);

    const handleLogOut = async () => {
        try {
            const res = await axiosInstance.post(`/api/v1/accounts/logout/`);
            if(res.status === 200 || res.status === 200){
                localStorage.removeItem('Token');
                Cookies.remove('Token');
                setUser(null);
                window.location.href = '/login';
            }
            else{
                setValues({ ...values, open: true, msgType: 'error', alertMsg: 'Something went wrong.' })
            }
        } 
        catch (error) {
            console.log(error)    
            setValues({ ...values, open: true, msgType: 'error', alertMsg: 'Something went wrong.' })
        }
    }

    return (
        <>
            <div className={styles.logout__container}>
                <div className={styles.logout__menu}>
                    <p className="mb-0">{user ? user.user.user_ptr.name : ''}</p>
                    <span onClick={handleLogOut}>Logout</span>
                </div>
            </div>
        </>
    );
}

export default Logout;