import React, { useEffect, useState } from "react";
import { useRecoilState  } from 'recoil';
import axiosInstance from "./../../plugins/axios";
import TextField from '@mui/material/TextField';
import { Input as BaseInput } from '@mui/base/Input';
import { editTypesState, nodesState, triggersState, isFormSubmittedState } from '../../store/state';
import { triggerConditionalListState } from '../../store/journey-list'
import { debounce } from 'lodash';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import { Plus, Trash3 } from 'react-bootstrap-icons'
import { v4 as uuidv4 } from 'uuid'
import { checkStartingNodeValueError, checkConditionalTypeTriggerError } from '../../mixins/errors'

const ErrorText = styled('p')`
  color: red;
  font-size: 0.75rem;
  margin-bottom: 0px;
  margin-left: 10px;
`;

const StartingNodeEdit = () => {
    const [editTypes, setEditTypes] = useRecoilState(editTypesState);
    const [nodes, setNodes] = useRecoilState(nodesState);
    const [triggers, setTriggers] = useRecoilState(triggersState);
    const [initialValues, setInitialValues] = useState([]);
    const [isFormSubmitted, setIsFormSubmitted] = useRecoilState(isFormSubmittedState);
    const [startingNodeValueBlur, setStartingNodeValueBlur] = useState(() => Array(triggers.values.length).fill(false));
    const [triggerConditionalTypeBlur, setTriggerConditionalTypeBlur] = useState(() => Array(triggers.values.length).fill(false));

    const [triggerConditionalList, setTriggerConditionalList] = useRecoilState(triggerConditionalListState);
    // const getTriggerList = useRecoilValue(getTriggerConditionalList);
    const getSelectedType = triggers?.values[0]?.type;

    const triggerUserList = [[
        {
            name: "Exact",
            is_already_associated: false
        },
        {
            name: "Startswith",
            is_already_associated: false
        },
        {
            name: "Endswith",
            is_already_associated: false
        },
        {
            name: "Contains",
            is_already_associated: false
        },
        {
            name: "Any Value",
            is_already_associated: false
        }
    ]];
    
    useEffect(async () => {
        setInitialValues(triggers.values);
        console.log(getSelectedType)
        console.log(triggers)
        if(getSelectedType === "AI Intent"){
            const response = await axiosInstance.get('/api/v1/communications/whatsapp/available-intent-for-association/');
            const data = response.data;
            setTriggerConditionalList(data.result);
        }else{
            setTriggerConditionalList(...triggerUserList);
        }
    }, [editTypes.selected_id]);
    
    const onHandleEvent =  async (e, index) => {
        const { value } = e.target;
        
        if(value === "AI Intent"){
            //delete the conditional type
            let updatedValues = initialValues.map((item) => {
                const { conditional_type, triggering_value, ...rest } = item;
                return {
                    ...rest,
                };
            });

            updatedValues = updatedValues.map((item) => {
                return {
                    ...item,
                    type: value,
                    intent_name: null
                };
            });
            setInitialValues(updatedValues);
            setTriggers({
                ...triggers,
                values: updatedValues
            });
            const response = await axiosInstance.get('/api/v1/communications/whatsapp/available-intent-for-association/');
            const data = response.data;
            setTriggerConditionalList(data.result);
        }else{
            //delete the intent name
            let updatedValues = initialValues.map((item) => {
                const { intent_name, triggering_value, ...rest } = item;
                return {
                    ...rest,
                };
            })
            updatedValues = updatedValues.map((item) => {
                return {
                    ...item,
                    type: value,
                    conditional_type: null,
                    triggering_value: ""
                };
            });
            setInitialValues(updatedValues);
            setTriggers({
                ...triggers,
                values: updatedValues
            });
            setTriggerConditionalList(...triggerUserList);
        }

    };
    

    const onHandleConditionalType = (e, index) => {
        const { value } = e.target;
        let updatedValues = [...initialValues];
        if(getSelectedType === "AI Intent"){
            //delete the conditional type and triggering value
            updatedValues = updatedValues.map((item) => {
                const { conditional_type, triggering_value, ...rest } = item;
                return {
                    ...rest,
                };
            })
            updatedValues[index] = {
                ...updatedValues[index],
                intent_name: value
            }
            setInitialValues(updatedValues);
            setTriggers({
                ...triggers,
                values: updatedValues
            });
            console.log(triggers)
        }else{
            //delete the intent name
            updatedValues = updatedValues.map((item) => {
                const { intent_name, ...rest } = item;
                return {
                    ...rest,
                };
            })
            updatedValues[index] = {
                ...updatedValues[index],
                conditional_type: value
            };
            setInitialValues(updatedValues);
            setTriggers({
                ...triggers,
                values: updatedValues
            });
            console.log(triggers)
        }
    }

    const onHandleValueDebounced = debounce((values) => {
        setTriggers({
            ...triggers,
            values
        });
    }, 300);

    const onHandleChangeText = (e, index) => {
        const { value } = e.target;
        const updatedValues = [...initialValues];
        updatedValues[index] = {
            ...updatedValues[index],
            triggering_value: value
        };
        setInitialValues(updatedValues);
        onHandleValueDebounced(updatedValues);
    }
    
    const onHandleCondition = (e) =>{
        const { value } = e.target;
        setTriggers({
            ...triggers,
            condition: value
        })
    }

    const addExpression = () => {
        if(getSelectedType === "AI Intent"){
            //delete the conditional type
            setInitialValues(initialValues.map((item) => {
                const { conditional_type, triggering_value, ...rest } = item;
                return {
                    ...rest,
                };
            }))
            setInitialValues([...initialValues, {
                uuid: uuidv4(),
                type: getSelectedType,
                intent_name: null
            }])
            setTriggers({
                ...triggers,
                condition: triggers?.condition || "AND",
                values: [...initialValues, {
                    uuid: uuidv4(),
                    type: getSelectedType,
                    intent_name: null
                }]
            })
        }else{
            //delete the intent name
            setInitialValues(initialValues.map((item) => {
                const { intent_name, ...rest } = item;
                return {
                    ...rest,
                };
            }))
            setInitialValues([...initialValues, {
                uuid: uuidv4(),
                type: getSelectedType,
                conditional_type: null,
                triggering_value: ""
            }])
            setTriggers({
                ...triggers,
                condition: triggers?.condition || "AND",
                values: [...initialValues, {
                    uuid: uuidv4(),
                    type: getSelectedType,
                    conditional_type: null,
                    triggering_value: ""
                }]
            })
        }
        
    }

    const handleDeleteButton = (indexToDelete) => {
        const updatedValues = [...initialValues];
        updatedValues.splice(indexToDelete+1, 1);
        setInitialValues(updatedValues);
        //check if updatedValues length is 1
        if(updatedValues.length === 1){
            setTriggers({
                ...triggers,
                condition: null,
                values: updatedValues
            })
        }else{
            setTriggers({
                ...triggers,
                values: updatedValues
            })
        }
    };

    const handleStartingNodeBlur = (index) => {
        const updatedBlurStatus = [...startingNodeValueBlur];
        updatedBlurStatus[index] = true;
        setStartingNodeValueBlur(updatedBlurStatus);
    }

    const handleTriggerConditionalTypeBlur = (index) => {
        const updatedBlurStatus = [...triggerConditionalTypeBlur];
        updatedBlurStatus[index] = true;
        setTriggerConditionalTypeBlur(updatedBlurStatus);
    }

    return (
        <>
            {initialValues.map((value, index) => (
                <div className="" key={index}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="select-event">Select Event</InputLabel>
                        <Select
                            labelId="select-event"
                            id="select-event"
                            value={initialValues[index].type}
                            label="Select Event"
                            onChange={(e)=> onHandleEvent(e, index)}
                        >
                            <MenuItem selected value={'User Input'}>User Input</MenuItem>
                            <MenuItem value={'AI Intent'}>AI Trigger</MenuItem>
                        </Select>
                    </FormControl>
                    <div className="d-flex">
                        <FormControl fullWidth sx={{ mr: 1, minWidth: 120 }} size="small" className="mt-3 mr-2">
                            <InputLabel id="select-event">{initialValues[index].type === 'User Input' ? 'Store Response In' : 'Intent Name'}</InputLabel>
                            <Select
                                labelId={initialValues[index].type === 'User Input' ? "select-type" : "intent-name"}
                                label={initialValues[index].type === 'User Input' ? "Store Response In" : "Intent Name"}
                                id="select-event"
                                value={initialValues[index].type === 'User Input' ? value.conditional_type : value.intent_name}
                                onChange={(e)=> onHandleConditionalType(e, index)}
                                error={(triggerConditionalTypeBlur[index] || isFormSubmitted) && checkConditionalTypeTriggerError(initialValues[index].type === 'User Input' ? value.conditional_type : value.intent_name)}
                                onBlur={() => handleTriggerConditionalTypeBlur(index)}
                            > 
                            {   
                                triggerConditionalList.map((item, index) => (
                                    <MenuItem key={index} value={item.name} disabled={item.is_already_associated}>{item.name}</MenuItem>
                                ))
                            }
                            </Select>
                            {checkConditionalTypeTriggerError(initialValues[index].type === 'User Input' ? value.conditional_type : value.intent_name) && (triggerConditionalTypeBlur[index] || isFormSubmitted) && (
                                <ErrorText error>{checkConditionalTypeTriggerError(initialValues[index].type === 'User Input' ? value.conditional_type : value.intent_name)}</ErrorText>
                            )} 
                        </FormControl>
                        { initialValues[index].type === 'User Input' && <FormControl fullWidth sx={{ mr: 1, minWidth: 120 }} size="small">
                            <TextField
                                fullWidth
                                sx={{ ml: 1, minWidth: 120 }}
                                size="small"
                                className="mt-3"
                                id="outlined-basic"
                                label="Value"
                                variant="outlined"
                                value={initialValues[index].triggering_value}
                                onChange={(e) => onHandleChangeText(e, index)}
                                error={(startingNodeValueBlur[index] || isFormSubmitted) && checkStartingNodeValueError(initialValues[index].triggering_value)}
                                // error={startingNodeValueBlur}
                                onBlur={() => handleStartingNodeBlur(index)} // Wrap the onBlur handler in an arrow function
                            />
                            {checkStartingNodeValueError(initialValues[index].triggering_value) && (startingNodeValueBlur[index] || isFormSubmitted) && (
                                <ErrorText error>{checkStartingNodeValueError(initialValues[index].triggering_value)}</ErrorText>
                            )}
                        </FormControl>}

                    </div>
                    {index === initialValues.length - 1 && (
                        <div className="my-2 d-flex justify-content-end">
                            <Button onClick={addExpression} variant="text" startIcon={<Plus />}>
                                Add Expression
                            </Button>
                        </div>
                    )}
                    {index !== initialValues.length - 1 && (
                        <div className="my-3 d-flex align-items-center justify-content-center">
                            <div className="flex-grow-1 d-flex justify-content-center">
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small" className="mt-3 mr-2">
                                    <Select
                                        id="select-event"
                                        value={triggers.condition}
                                        onChange={onHandleCondition}
                                    >       
                                        <MenuItem disabled value={null}>Select Condition</MenuItem>
                                        <MenuItem value={'AND'}>AND</MenuItem>
                                        <MenuItem value={'OR'}>OR</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="">
                                <Trash3 className="cursor-pointer" onClick={() => handleDeleteButton(index)} color={'#1565c0'} />
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </>
    )
}

export default StartingNodeEdit;
