import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist'
import axiosInstance from "../plugins/axios";

const { persistAtom } = recoilPersist();

export const modelListState = atom({
    key: 'modelList',
    default: [],
    // effects_UNSTABLE: [persistAtom],
});

export const getModelList = selector({
    key: 'getModelListData',
    get: async () => {
        const response = await axiosInstance.get('api/v1/communications/whatsapp/ai-intents/list/');
        const data = response.data;
        return data;
    },
});

export const openCreateDialogBox = atom({
    key: 'openCreateDialogBox',
    default: false,
});

export const AIIntentDataState = atom({
    key: 'AIIntentData',
    default: {},
    effects_UNSTABLE: [persistAtom],
});