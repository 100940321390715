import React, { useCallback, useEffect, Suspense } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../components/basic-components/Loading";
import axiosInstance from "../../plugins/axios";
import { useRecoilState } from "recoil";
import { nodesState, edgesState, isDraggableState, triggersState } from '../../store/state';
import { journeyNameState } from '../../store/journey-list';
import StartNode from '../../components/start/startNode';
import CustomEdge from '../../components/ui-components/CustomEdge';
import ReactFlow, {
    MiniMap,
    Controls,
    Background,
    ReactFlowProvider,
} from 'reactflow';
import Sidebar from '../../components/sidebar/Sidebar';
import Editbar from '../../components/sidebar/editbar';
import Text from "../../components/text/Text";
import UploadFile from "../../components/upload/UploadFile";
import List from "../../components/list/list";
import ReplyButton from '../../components/replyButton/replyButton';
import JourneySave from '../../components/ui-components/journey-save';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

const edgeTypes = {
    'custom-edge': CustomEdge,
}


const JourneyDetails = () => {
    const [nodes, setNodes] = useRecoilState(nodesState);
    const [edges, setEdges] = useRecoilState(edgesState);
    const [triggers, setTriggers] = useRecoilState(triggersState);
    const [isDraggableList, SetIsDraggableList] = useRecoilState(isDraggableState);
    const [journeyName, setJourneyName] = useRecoilState(journeyNameState);
    const params = useParams();
    const journeyID = params.uuid
    useEffect( async () => {
        try {
            const token = Cookies.get('Token');
            if(!token){
                localStorage.removeItem('Token');
                window.location.href = '/login'
            }
            else{
                const response = await axiosInstance.get(`/api/v1/communications/whatsapp/journeys/${params.uuid}/`)
                const data = response.data.result;     
                // console.log(data.messages)     
                let createEdges = [];  
                let payload = [{
                    id: 'starting_node',
                    data: { label: <StartNode nodeId={'starting_node'} /> },
                    deletable: false,
                    sourcePosition: 'right',
                    position: { x: -50, y: 200 },
                    message_to_send: {
                        fallback_node_id: null,
                        id: "starting_node",
                        next_node_id: "",
                        prev_node_id: null,
                        position: {x: -50, y: 200},
                        type: "starting_node",
                        value: ""
                    }
                }]
                setTriggers(data.triggers)
                setJourneyName({
                    id: data.id,
                    name: data.name
                })

                data.messages.map((message) => {
                    if(message.type === 'start') {
                        payload[0].message_to_send.next_node_id = message.id
                        message.prev_node_id = 'starting_node'
                    }

                    let message_payload = {
                        id: message.id,
                        data: {label: typeCheck(message?.template?.msg_type, message.id)},
                        deletable: false,
                        sourcePosition: 'right',
                        targetPosition: 'left',
                        position: { x: message.position.x, y: message.position.y },
                        message_to_send: message,
                    }
                    if(message?.template?.msg_type === 'reply_list' || message?.template?.msg_type === 'reply_button') {
                        message_payload.is_node_switched = (typeof message?.next_node_id === 'object' && message?.next_node_id !== null) ? true : false
                    }
                    payload.push(message_payload)
                })
                
                payload.forEach(item => {
                    const messageToSend = item.message_to_send;
                    const createEdgesForItem = (sourceHandle, targetNodeId) => {
                        const newEdges = {
                            id: uuidv4(),
                            type: 'custom-edge',
                            markerEnd: { type: 'arrowclosed', color: '#5956d6' },
                            style: { stroke: '#5956d6', strokeWidth: 2 },
                            source: item.id,
                            target: targetNodeId,
                            sourceHandle: sourceHandle,
                            targetHandle: null,
                        };
                        createEdges.push(newEdges);
                    };
                
                    if (typeof messageToSend.next_node_id === 'object' && messageToSend.next_node_id !== null) {
                        if (messageToSend.fallback_node_id !== null) {
                            createEdgesForItem('fallback', messageToSend.fallback_node_id);
                        }
                        Object.entries(messageToSend.next_node_id).forEach(([key, value]) => {
                            createEdgesForItem(key, value);
                        });
                    } 
                    else {
                        if(messageToSend.fallback_node_id !== null) {
                            createEdgesForItem('fallback', messageToSend.fallback_node_id);
                        }
                        createEdgesForItem(null, messageToSend.next_node_id);
                    }
                });
                setNodes(payload)            
                setEdges(createEdges)
            }
        }
        catch (error) {
            console.log(error)
        }
    }, [setNodes, setEdges]);

    const typeCheck = (type, nodeId) => {
        let component;
        switch (type) {
            case 'text':
                component = <Text className="m-0 p-0" nodeId={nodeId} />;
                break;
            case 'image':
            case 'video':
            case 'document':
                component = <UploadFile nodeId={nodeId} props={{ type }} className="m-0 p-0" />;
                break;
            case 'reply_list':
                component = <List nodeId={nodeId} className="m-0 p-0" />;
                break;
            case 'reply_button':
                component = <ReplyButton nodeId={nodeId} className="m-0 p-0" />;
                break;
            default:
                break;
        }

        return component;
    }

    const handleNodesChange = (event) => {
        try {
            if (isDraggableList && event && event.length && event[0].dragging) {
                const selectedId = event[0].id;
                const updatedNodes = nodes.map(node => {
                    if (node.id === selectedId) {
                        return {
                            ...node,
                            position: event[0].positionAbsolute,
                            message_to_send: {
                                ...node.message_to_send,
                                position: event[0].positionAbsolute // Assuming event is an array with at least one element
                            }
                        };
                    }
                    return node;
                });
                setNodes(updatedNodes);
            }
        } catch (error) {
            console.error("Error in handleNodesChange:", error);
        }
    }; 

    const onConnect = useCallback(
        (params) => {
            try {
                const sourceNodeIndex = nodes.findIndex((node) => node.id === params.source);
                const sourceNode = nodes[sourceNodeIndex];
        
                // Find the target node
                const targetNodeIndex = nodes.findIndex((node) => node.id === params.target);
                const targetNode = nodes[targetNodeIndex];

                if(params.sourceHandle !== 'fallback'){
                    if(params.source === params.target){
                        console.error('Cannot connect source node to itself');
                        return
                    }
                }

                // Update source node
                let updatedNodes = [...nodes]; // Create a copy of the nodes array
                if(params.sourceHandle === 'fallback'){
                    if(updatedNodes[sourceNodeIndex].message_to_send.fallback_node_id !== null){
                        console.error('Cannot connect source node to another source node again.1');
                        return
                    }
                }else{
                    if(updatedNodes[sourceNodeIndex].is_node_switched){
                        if(updatedNodes[sourceNodeIndex].message_to_send?.next_node_id &&  updatedNodes[sourceNodeIndex].message_to_send?.next_node_id[params.sourceHandle] && updatedNodes[sourceNodeIndex].message_to_send?.next_node_id[params.sourceHandle] !== null){
                            console.error('Cannot connect source node to another source node again.2');
                            return;
                        }
                    }else{
                        
                        const isSameSource = nodes.some(node => params.source === node.message_to_send.prev_node_id)
                        if(isSameSource){
                            console.error('Cannot connect source node to another source node again.3');
                            return;
                        }
                    }
                }

                const edgeId = uuidv4();
                const newEdges = {
                    id: edgeId,
                    // type: 'smoothstep',
                    type: 'custom-edge',
                    markerEnd: { type: 'arrowclosed', color: '#5956d6' },
                    style: { stroke: '#5956d6', strokeWidth: 2 },
                    source: params.source,
                    target: params.target,
                    sourceHandle: params.sourceHandle,
                    targetHandle: params.targetHandle,
                };

                const sourceHandle = params.sourceHandle;
        
                // Add the edge with modified params
                setEdges((nds) => nds.concat(newEdges));
        
    
                const targetId = targetNode ? targetNode.id : null;
                if(sourceHandle !== 'fallback'){
                    if (updatedNodes[sourceNodeIndex].is_node_switched) {
                        updatedNodes[sourceNodeIndex] = {
                            ...updatedNodes[sourceNodeIndex],
                            message_to_send: {
                                ...updatedNodes[sourceNodeIndex].message_to_send,
                                next_node_id: {
                                    ...(updatedNodes[sourceNodeIndex].message_to_send.next_node_id || {}),
                                    [targetId ? sourceHandle : null]: targetId || null
                                }
                            }
                        };
                    } else {
                        if (sourceNode) {
                            updatedNodes[sourceNodeIndex] = {
                                ...updatedNodes[sourceNodeIndex],
                                message_to_send: {
                                    ...updatedNodes[sourceNodeIndex].message_to_send,
                                    next_node_id: targetId
                                }
                            };
                        }
                    }
                }else{
                    updatedNodes[sourceNodeIndex] = {
                        ...updatedNodes[sourceNodeIndex],
                        message_to_send: {
                            ...updatedNodes[sourceNodeIndex].message_to_send,
                            fallback_node_id: targetId
                        }
                    }
                }
        
                // Update target node
                if (targetNode) {
                    if(sourceNode.id !== targetNode.id){
                        // Update the target node with the source node's ID
                        updatedNodes[targetNodeIndex] = {
                            ...updatedNodes[targetNodeIndex],
                            message_to_send: {
                                ...updatedNodes[targetNodeIndex].message_to_send,
                                prev_node_id: sourceNode.id,
                            },
                        };
                    }
                }
        
                // Set the updated nodes array
                setNodes(updatedNodes);
            } catch (error) {
                console.error("Error in onConnect:", error);
            }
        },
        [nodes, setEdges, setNodes]
    );

    const onDrop = async (event) => {
        try {
            event.preventDefault();
            const type = event.dataTransfer.getData("application/reactflow");
            const nodeId = uuidv4();
            const lastNode = nodes[nodes.length - 1];
            const position = lastNode ? { x: lastNode.position.x + 200, y: lastNode.position.y } : { x: 0, y: 0 };
            let component;
            switch (type) {
                case 'text':
                    component = <Text className="m-0 p-0" nodeId={nodeId} />;
                    break;
                case 'image':
                case 'video':
                case 'document':
                    component = <UploadFile nodeId={nodeId} props={{ type }} className="m-0 p-0" />;
                    break;
                case 'reply_list':
                    component = <List nodeId={nodeId} className="m-0 p-0" />;
                    break;
                case 'reply_button':
                    component = <ReplyButton nodeId={nodeId} className="m-0 p-0" />;
                    break;
                default:
                    break;
            }
        
            const newNode = {
                id: nodeId,
                type,
                position,
                data: { label: component },
                deletable: true,
                sourcePosition: 'right',
                targetPosition: 'left',
                message_to_send: {
                    id: nodeId,
                    type: 'mid',
                    value: '',
                    template: {
                        id: uuidv4(),
                        msg_type: type,
                        message: '',
                        value: '',
                        header: '',
                        footer: '',
                        media_url: null,
                        content_type: null,
                        filename: null
                    },
                    // prev_node_id: lastNode ? lastNode.id : null,
                    prev_node_id: null,
                    next_node_id: "",
                    fallback_node_id: null,
                    store_response_in: null,
                    storing_values: {},
                    position
                },
            };

            if(type === 'reply_list'){
                const listId = uuidv4();
                const listId2 = uuidv4();
                newNode.message_to_send = {
                    ...newNode.message_to_send,
                    storing_values: {
                        [listId]: null,
                        [listId2]: null
                    },
                    template: {
                        ...newNode.message_to_send.template,
                        // button_name: '',
                        value: {
                            button: '',
                            sections: [
                                {
                                    title: "",
                                    rows: [
                                        {
                                            id: listId,
                                            title: "",
                                        },
                                        {
                                            id: listId2,
                                            title: "",
                                        }
                                    ]
                                },
                            ]

                        },
                    }
                }
            }

            if(type === 'reply_button'){
                const buttonId = uuidv4();
                const buttonId2 = uuidv4();
                newNode.message_to_send = {
                    ...newNode.message_to_send,
                    storing_values: {
                        [buttonId]: null,
                        [buttonId2]: null
                    },
                    template: {
                        ...newNode.message_to_send.template,
                        value: {
                            buttons: [
                                {
                                    type: 'reply',
                                    reply: {
                                        id: buttonId,
                                        title: ""
                                    }
                                },
                                {
                                    type: 'reply',
                                    reply: {
                                        id: buttonId2,
                                        title: ""
                                    }
                                },
                            ]
                        },
                    }
                }
            }
            setNodes((nds) => [...nds, newNode]);
        } catch (error) {
            console.error("Error in onDrop:", error);
        }
    };

    const onDragOver = (event) => {
        try {
            event.preventDefault();
            event.dataTransfer.dropEffect = "move";
        } catch (error) {
            console.error("Error in onDragOver:", error);
        }
    };

    return (
        <div>
            <div className='mb-3'>
                {/* <div>
                    <TextField 
                        id="outlined-basic" 
                        label="Journey name" 
                        variant="outlined" 
                        value={journeyName.name}
                        onChange={handleJourneyName}
                    />
                </div> */}
                <JourneySave 
                    journeyID={journeyID}
                />
            </div>
            {/* <h1>Journey Details</h1> */}
            <div className="dndflow">
                <ReactFlowProvider>
                    <Sidebar />
                    <div
                        className="reactflow-wrapper"
                        style={ { height: "86vh", width: "100%", background: "#fff" }}
                    >
                        <ReactFlow
                            nodes={ nodes }
                            edges={ edges }
                            edgeTypes={edgeTypes}
                            onNodesChange={ handleNodesChange }
                            // onEdgesChange={ handleEdgesChange }
                            onConnect={ onConnect }
                            onDrop={ onDrop }
                            onDragOver={ onDragOver }
                        >
                            <Controls />
                            <MiniMap />
                            <Background variant="dots" gap={ 12 } size={ 1 } />
                        </ReactFlow>
                    </div>
                </ReactFlowProvider>
                <Editbar />
            </div>
        </div>
    )
}

const SuspenseJourneyDetails = () => (
    <Suspense 
        fallback={ <Loading /> }
    >
        <JourneyDetails />
    </Suspense>
);


export default SuspenseJourneyDetails;