import React, { useEffect, Suspense, useState } from "react";
import { useRecoilState, useRecoilValue } from 'recoil';
import { journeyListState, getPaginationState, variableListState, getVariableList } from '../../store/journey-list';
import Loading from "../../components/basic-components/Loading";
import styles from '../../assets/css/journey-list.module.css';
import { Search, X } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";
import JourneyListCard from "../../components/basic-components/journey-list-card"
import axiosInstance from "../../plugins/axios";
import { v4 as uuidv4 } from 'uuid';
import BasicPagination from "../../components/basic-components/basic-pagination";
import Cookies from 'js-cookie';
// import { debounce } from 'lodash';

function debounce(func, delay) {
    let timeoutId;
    
    return function() {
        const context = this;
        const args = arguments;
      
        clearTimeout(timeoutId);
      
        timeoutId = setTimeout(() => {
            func.apply(context, args);
        }, delay);
    };
}

const JourneyList = () => {

    const [list, setList] = useRecoilState(journeyListState);
    const [search, setSearchText] = useState('');
    const [pagination, setPagination] = useRecoilState(getPaginationState);
    const [variableList, setVariableList] = useRecoilState(variableListState);
    const getVariable = useRecoilValue(getVariableList);

    const query = new URLSearchParams(location.search);
	const page = parseInt(query.get('page') || '1', 10);

    useEffect(() => {
        try {
            const token = Cookies.get('Token');
            if(!token){
                localStorage.removeItem('Token');
                window.location.href = '/login'
            }
            else{
                fetchData(); 
            }
        } 
        catch (error) {
            console.error('Error fetching data:', error);
        }
    }, []);

    const fetchData = async () => {
        try {
            const data1 = await getVariable;
            setVariableList(data1.results);
            const {data} = await axiosInstance.get(`/api/v1/communications/whatsapp/journeys/?limit=20&offset=${(page - 1) * 20}`);
            setPagination({
                ...pagination,
                count: data.count,
                onPageChange: (event, value) => onPageChange(event, value),
                defaultPage: page,
                page: page,
                path: '/journey-list/?'
            })
            setList(data.results);
        } 
        catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const onPageChange = async (event, value) => {
        try{
            setSearchText('');
            const res = await axiosInstance.get(`/api/v1/communications/whatsapp/journeys/?limit=20&offset=${(value-1) * 20}`);
            setPagination({
                ...pagination,
                page: value,
                count: res.data.count,
                path: '/journey-list/?',
                onPageChange: (event, value) => onPageChange(event, value)
            })
            setList(res.data.results);
        }catch(error){
            console.log(error)
        }
    }

    const handleSearachDebounce = debounce( async(e) => {
        try {
            const { data } = await axiosInstance.get(`/api/v1/communications/whatsapp/journeys/?name=${e.target.value}`)
            setList(data.results);
        } 
        catch (error) {
            console.log(error)
        }
    }, 300)

    const handleSearch = (e) => {
        try {
            setSearchText(e.target.value);
            if(e.target.value === '') {
                handleResetSearch()
            }else{
                handleSearachDebounce(e)
            }
        } 
        catch (error) {
            console.log(error)
        }
    };

    const handleResetSearch = async () => {
        try {
            setSearchText('');
            try {
                const {data} = await axiosInstance.get(`/api/v1/communications/whatsapp/journeys/?limit=20&offset=${(page - 1) * 20}`);
                setPagination({
                    ...pagination,
                    count: data.count,
                    onPageChange: (event, value) => onPageChange(event, value),
                    defaultPage: page,
                    page: page,
                    path: '/journey-list/?'
                })
                setList(data.results);
            } 
            catch (error) {
                console.error('Error fetching data:', error);
            }  
        } 
        catch (error) {
            console.log(error)
        }
    };

    return (
        <div className={styles.journey__list__container}>
            <div className={styles.search__container}>
                <h4>Journeys</h4>
                <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                        <div className={styles.journey__search}>
                            <div>
                                <input 
                                    type="text" 
                                    placeholder="Search Journeys" 
                                    onChange={handleSearch}
                                    value={search}
                                />
                            </div>
                            <div className="cursor-pointer">
                                {
                                    search.length > 0 &&
                                    <X size={30} onClick={handleResetSearch} />
                                }
                            </div>
                            {search.length === 0 && <div>
                                <Search />
                            </div>}
                        </div>
                    </div>
                    <div>
                        <Link to={`/create-journey?id=${uuidv4()}`}>
                            <button className={styles.create__journey__btn}>Create Journey</button>    
                        </Link>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                {list.map((item, index) => (
                    <JourneyListCard key={index} journey={item} fetchData={fetchData} />
                ))}
            </div>
            <BasicPagination />
        </div>
    );
}

const SuspenseJourneyList = () => (
    <Suspense 
        fallback={ <Loading /> }
    >
        <JourneyList />
    </Suspense>
);

export default SuspenseJourneyList;