import React, { useEffect } from "react";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useRecoilState } from 'recoil'
import { getPaginationState } from '../../store/journey-list'
import { Link, MemoryRouter, Route, Routes, useLocation } from 'react-router-dom';
import PaginationItem from '@mui/material/PaginationItem';

export default function BasicPagination() {
	const [values, setValues] = useRecoilState(getPaginationState);
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const page = parseInt(query.get('page') || '1', 10);

	return (
		<div className="d-flex justify-content-center">
			<Stack spacing={2}>
				<Pagination  page={values.page} onChange={values.onPageChange} count={Math.ceil(values.count / 20)} color={values.color} defaultPage={values.defaultPage} showFirstButton={values.showFirstButton} showLastButton={values.showLastButton} renderItem={(item) => (
					<PaginationItem
					component={Link}
					to={`${values.path}page=${item.page}`}
					{...item}
					/>
					// ${query.toString() ? '&' : '?'}
				)} />
			</Stack>
		</div>
	);
}

// export default function PaginationLink() {
// 	return (
// 	  <MemoryRouter initialEntries={['/inbox']} initialIndex={0}>
// 		<Routes>
// 		  <Route path="*" element={<Content />} />
// 		</Routes>
// 	  </MemoryRouter>
// 	);
//   }