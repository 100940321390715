import React, { Suspense, useEffect } from 'react';
import Loading from "../../components/basic-components/Loading";
import AllChats from '../../components/chats/all-chats';
import { useRecoilState, useRecoilValue } from 'recoil';
import { allChatsState, getAllChats, chatsNextURLState, broadcastIdState } from '../../store/agent-chats';
import Cookies from 'js-cookie';

const Chats = () => {
    const [allChats, setAllChats] = useRecoilState(allChatsState);
    const [chatsNextURL, setChatsNextURL] = useRecoilState(chatsNextURLState)
    const [ broadcastId, setBroadcastId ] = useRecoilState(broadcastIdState);
    const getChats = useRecoilValue(getAllChats);
    useEffect(() => {
        const token = Cookies.get('Token');
        if(!token){
            localStorage.removeItem('Token');
            window.location.href = '/login'
        }
        else{
            fetchAllChats();
        }
    }, [])

    const fetchAllChats = async () => {
        try {
            const data = await getChats;
            setAllChats(data.results);
            setChatsNextURL(data.next)
        } 
        catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            {/* <div className={styles.chat__heading}>
                <h5>Chats</h5>
            </div> */}
            <div>
                <AllChats />
            </div>
        </>
    );
}

const SuspenseChats = () => (
    <Suspense 
        fallback={ <Loading /> }
    >
        <Chats />
    </Suspense>
);

export default SuspenseChats;