import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist'
// import axiosInstance from "../plugins/axios";

const { persistAtom } = recoilPersist();

export const broadcastListState = atom({
    key: 'broadcastList',
    default: [],
    effects_UNSTABLE: [persistAtom],
});

export const openCreateBroadcastBoxState = atom({
    key: 'openCreateBroadcastBox',
    default: {
        open: false,
        type: '',
        title: '',
        id: '',
    },
});

export const openRescheduleBroadcastBoxState = atom({
    key: 'openRescheduleBroadcastBox',
    default: {
        open: false,
        type: '',
        title: '',
        id: '',
    },
});
