import React, { useState, useEffect } from 'react';
import {
    BaseEdge,
    EdgeLabelRenderer,
    getSmoothStepPath,
    getBezierPath,
} from 'reactflow';
import { Trash3 } from 'react-bootstrap-icons';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useRecoilState } from 'recoil';
import { edgesState, nodesState } from '../../store/state';

export default function CustomEdge({ id, sourceX, sourceY, targetX, targetY, steps = [], sourcePosition, targetPosition, markerEnd, source, target }) {
    const [edges, setEdges] = useRecoilState(edgesState);
    const [nodes, setNodes] = useRecoilState(nodesState);
    const [isTargetSame, setIsTargetSame] = useState(false);
    const radiusX = (sourceX - targetX) * 0.8;
    const radiusY = 80;
    const edgePathCircle = `M ${sourceX} ${sourceY} A ${radiusX} ${radiusY } 0 1 0 ${targetX} ${targetY}`;

    const [edgePath, labelX, labelY] = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    useEffect(() => {
        if (source === target) {
            setIsTargetSame(true);
        }
    }, []);

    const arrowStyle = {
        fill: '#5956d6', // Arrow color
        strokeWidth: 2,
    };

    const lineStyle = {
        stroke: '#5956d6', // Line color
        strokeWidth: 2,
    };

    const handleDeleteEdge = (id) => {
        const findEdge = edges.find((edge) => edge.id === id);
        const prevNodeId = findEdge.source;
        const nextNodeIds = findEdge.target;
        const isFallback = (nodes.find((node) => node.id === prevNodeId)?.message_to_send?.fallback_node_id === nextNodeIds)

        let updatedNodes = nodes.map((node) => {
            if (node.id === prevNodeId) {
                return {
                    ...node,
                    message_to_send: {
                        ...node.message_to_send,
                        next_node_id: node.is_node_switched
                            ? Object.entries(node.message_to_send.next_node_id)
                                .reduce((obj, [key, value]) => {
                                    if (value !== nextNodeIds) {
                                        obj[key] = value;
                                    }
                                    return obj;
                                }, {})
                            : node.message_to_send.next_node_id === nextNodeIds ? null : node.message_to_send.next_node_id
                    }
                };
            }
            return node;
        });
        
        if(prevNodeId !== nextNodeIds) {
            updatedNodes = updatedNodes.map((node) => {
                if (nextNodeIds.includes(node.id)) {
                    return {
                        ...node,
                        message_to_send: {
                            ...node.message_to_send,
                            prev_node_id: '',
                        },
                    };
                }
                return node;
            });
        }
        if (isFallback) {
            updatedNodes = updatedNodes.map((node) => {
                return {
                    ...node,
                    message_to_send: {
                        ...node.message_to_send,
                        fallback_node_id: null,
                    },
                };
            })
        }
        setNodes(updatedNodes);
        setEdges((eds) => eds.filter((edge) => edge.id !== id));
    };

    return (
        <>
            <BaseEdge
                id={id}
                path={isTargetSame ? edgePath : edgePath}
                arrowHead={arrowStyle}
                style={lineStyle}
                markerEnd={markerEnd}
            />
            <EdgeLabelRenderer>
                <Tooltip title="Delete" style={{
                    position: 'absolute',
                    transform: `translate(-50%, -50%) translate(${isTargetSame ? sourceX+25 : labelX}px,${isTargetSame ? sourceY : labelY}px)`,
                    pointerEvents: 'all',
                    backgroundColor: '#5956d6',
                    boxShadow: '0 0 0 0.25rem #c5cad9',
                    padding: '5px'
                }}
                    className="nodrag nopan">
                    <IconButton onClick={() => handleDeleteEdge(id)}>
                        <Trash3 size={14} color='#ffffff' fontWeight={800} />
                    </IconButton>
                </Tooltip>
            </EdgeLabelRenderer>
        </>
    );
}
