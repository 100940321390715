import React, { useCallback, useEffect, Suspense } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReactFlow, {
    MiniMap,
    Controls,
    Background,
    ReactFlowProvider,
} from 'reactflow';
import 'reactflow/dist/style.css';
import "../../assets/scss/styles.scss";
import Sidebar from '../../components/sidebar/Sidebar';
import Editbar from '../../components/sidebar/editbar';
import Text from "../../components/text/Text";
import UploadFile from "../../components/upload/UploadFile";
import List from "../../components/list/list";
import ReplyButton from '../../components/replyButton/replyButton';
import { isDraggableState, nodesState, edgesState, triggersState, generateInitialNodesState  } from '../../store/state';
import { getVariableList, variableListState, journeyNameState } from '../../store/journey-list'
import { useRecoilState, useRecoilValue } from 'recoil';
import Loading from "../../components/basic-components/Loading";
import { useSearchParams } from "react-router-dom";
import JourneySave from '../../components/ui-components/journey-save';
import CustomEdge from '../../components/ui-components/CustomEdge';
import Cookies from 'js-cookie';

// Define nodeTypes and edgeTypes outside of the component
const nodeTypes = {
    
};

const edgeTypes = {
    'custom-edge': CustomEdge
}

const CreateJourney = () => {
    const [nodes, setNodes] = useRecoilState(nodesState);
    const [edges, setEdges] = useRecoilState(edgesState);
    const [isDraggableList, SetIsDraggableList] = useRecoilState(isDraggableState);
    const [journeyName, setJourneyName] = useRecoilState(journeyNameState);
    const [triggers, setTriggers] = useRecoilState(triggersState);
    const getVariable = useRecoilValue(getVariableList);
    const [variableList, setVariableList] = useRecoilState(variableListState);

    const queryParams = useSearchParams()
    const journeyID = queryParams[0].get('id')

    useEffect(() => {
        try {
            const token = Cookies.get('Token');
            if(!token){
                localStorage.removeItem('Token');
                window.location.href = '/login'
            }
            else{
                const fetchData = async () => {
                    try {
                        const data = await getVariable;
                        setVariableList(data.results);
                        const newNodesState = generateInitialNodesState();
                        setNodes(newNodesState);
                        setTriggers({
                            condition: null,
                            values: [
                                {
                                    uuid: uuidv4(),
                                    type: "User Input",
                                    conditional_type: "Exact",
                                    triggering_value: ""
                                },
                            ]
                        });
                        setJourneyName({id: null, name: ''});
                    } 
                    catch (error) {
                        console.error('Error fetching data:', error);
                    }
                };
                fetchData();       
            }
        } 
        catch (error) {
            console.error('Error fetching data:', error);
        }
    }, []);

    const handleNodesChange = (event) => {
        try {
            if (isDraggableList && event && event.length && event[0].dragging) {
                const selectedId = event[0].id;
                const updatedNodes = nodes.map(node => {
                    if (node.id === selectedId) {
                        return {
                            ...node,
                            position: event[0].positionAbsolute,
                            message_to_send: {
                                ...node.message_to_send,
                                position: event[0].positionAbsolute // Assuming event is an array with at least one element
                            }
                        };
                    }
                    return node;
                });
                setNodes(updatedNodes);
            }
        } catch (error) {
            console.error("Error in handleNodesChange:", error);
        }
    }; 

    // const handleEdgesChange = (event) => {
    //     try {
    //         console.log(event);
            
    //         // setEdges(event);
    //     } catch (error) {
    //         console.error("Error in handleEdgesChange:", error);
    //     }
    // };
    
    const onConnect = useCallback(
        (params) => {
            try {
                const sourceNodeIndex = nodes.findIndex((node) => node.id === params.source);
                const sourceNode = nodes[sourceNodeIndex];
        
                // Find the target node
                const targetNodeIndex = nodes.findIndex((node) => node.id === params.target);
                const targetNode = nodes[targetNodeIndex];

                if(params.sourceHandle !== 'fallback'){
                    if(params.source === params.target){
                        console.error('Cannot connect source node to itself');
                        return
                    }
                }

                // Update source node
                let updatedNodes = [...nodes]; // Create a copy of the nodes array
                if(params.sourceHandle === 'fallback'){
                    if(updatedNodes[sourceNodeIndex].message_to_send.fallback_node_id !== null){
                        console.error('Cannot connect source node to another source node again.1');
                        return
                    }
                }else{
                    if(updatedNodes[sourceNodeIndex].is_node_switched){
                        if(updatedNodes[sourceNodeIndex].message_to_send?.next_node_id &&  updatedNodes[sourceNodeIndex].message_to_send?.next_node_id[params.sourceHandle] && updatedNodes[sourceNodeIndex].message_to_send?.next_node_id[params.sourceHandle] !== null){
                            console.error('Cannot connect source node to another source node again.2');
                            return;
                        }
                    }else{
                        const isSameSource = nodes.some(node => params.source === node.message_to_send.prev_node_id)
                        if(isSameSource){
                            console.error('Cannot connect source node to another source node again.3');
                            return;
                        }
                    }
                }

                const edgeId = uuidv4();
                const newEdges = {
                    id: edgeId,
                    // type: 'smoothstep',
                    type: 'custom-edge',
                    markerEnd: { type: 'arrowclosed', color: '#5956d6' },
                    style: { stroke: '#5956d6', strokeWidth: 2 },
                    source: params.source,
                    target: params.target,
                    sourceHandle: params.sourceHandle,
                    targetHandle: params.targetHandle,
                };

                const sourceHandle = params.sourceHandle;
        
                // Add the edge with modified params
                setEdges((nds) => nds.concat(newEdges));
        
    
                const targetId = targetNode ? targetNode.id : null;
                if(sourceHandle !== 'fallback'){
                    if (updatedNodes[sourceNodeIndex].is_node_switched) {
                        updatedNodes[sourceNodeIndex] = {
                            ...updatedNodes[sourceNodeIndex],
                            message_to_send: {
                                ...updatedNodes[sourceNodeIndex].message_to_send,
                                next_node_id: {
                                    ...(updatedNodes[sourceNodeIndex].message_to_send.next_node_id || {}),
                                    [targetId ? sourceHandle : null]: targetId || null
                                }
                            }
                        };
                    } else {
                        if (sourceNode) {
                            updatedNodes[sourceNodeIndex] = {
                                ...updatedNodes[sourceNodeIndex],
                                message_to_send: {
                                    ...updatedNodes[sourceNodeIndex].message_to_send,
                                    next_node_id: targetId
                                }
                            };
                        }
                    }
                }else{
                    updatedNodes[sourceNodeIndex] = {
                        ...updatedNodes[sourceNodeIndex],
                        message_to_send: {
                            ...updatedNodes[sourceNodeIndex].message_to_send,
                            fallback_node_id: targetId
                        }
                    }
                }
                

        
                // Update target node
                if (targetNode) {
                    // Update the target node with the source node's ID
                    updatedNodes[targetNodeIndex] = {
                        ...updatedNodes[targetNodeIndex],
                        message_to_send: {
                            ...updatedNodes[targetNodeIndex].message_to_send,
                            prev_node_id: sourceNode.id,
                        },
                    };
                }
        
                // Set the updated nodes array
                setNodes(updatedNodes);
            } catch (error) {
                console.error("Error in onConnect:", error);
            }
        },
        [nodes, setEdges, setNodes]
    );

    const onDrop = async (event) => {
        try {
            event.preventDefault();
            const type = event.dataTransfer.getData("application/reactflow");
            const nodeId = uuidv4();
            const lastNode = nodes[nodes.length - 1];
            const position = lastNode ? { x: lastNode.position.x + 200, y: lastNode.position.y } : { x: 0, y: 0 };
            let component;
            switch (type) {
                case 'text':
                    component = <Text className="m-0 p-0" nodeId={nodeId} />;
                    break;
                case 'image':
                case 'video':
                case 'document':
                    component = <UploadFile nodeId={nodeId} props={{ type }} className="m-0 p-0" />;
                    break;
                case 'reply_list':
                    component = <List nodeId={nodeId} className="m-0 p-0" />;
                    break;
                case 'reply_button':
                    component = <ReplyButton nodeId={nodeId} className="m-0 p-0" />;
                    break;
                default:
                    break;
            }
        
            const newNode = {
                id: nodeId,
                type,
                position,
                data: { label: component },
                deletable: true,
                sourcePosition: 'right',
                targetPosition: 'left',
                message_to_send: {
                    id: nodeId,
                    type: 'mid',
                    value: '',
                    template: {
                        id: uuidv4(),
                        msg_type: type,
                        message: '',
                        value: '',
                        header: '',
                        footer: '',
                        media_url: null,
                        content_type: null,
                        filename: null
                    },
                    // prev_node_id: lastNode ? lastNode.id : null,
                    prev_node_id: null,
                    next_node_id: "",
                    fallback_node_id: null,
                    store_response_in: null,
                    storing_values: {},
                    position
                },
            };

            if(type === 'reply_list'){
                const listId = uuidv4();
                const listId2 = uuidv4();
                newNode.message_to_send = {
                    ...newNode.message_to_send,
                    storing_values: {
                        [listId]: null,
                        [listId2]: null
                    },
                    template: {
                        ...newNode.message_to_send.template,
                        // button_name: '',
                        value: {
                            button: '',
                            sections: [
                                {
                                    title: "",
                                    rows: [
                                        {
                                            id: listId,
                                            title: "",
                                        },
                                        {
                                            id: listId2,
                                            title: "",
                                        }
                                    ]
                                },
                            ]

                        },
                    }
                }
            }

            if(type === 'reply_button'){
                const buttonId = uuidv4();
                const buttonId2 = uuidv4();
                newNode.message_to_send = {
                    ...newNode.message_to_send,
                    storing_values: {
                        [buttonId]: null,
                        [buttonId2]: null
                    },
                    template: {
                        ...newNode.message_to_send.template,
                        value: {
                            buttons: [
                                {
                                    type: 'reply',
                                    reply: {
                                        id: buttonId,
                                        title: ""
                                    }
                                },
                                {
                                    type: 'reply',
                                    reply: {
                                        id: buttonId2,
                                        title: ""
                                    }
                                },
                            ]
                        },
                    }
                }
            }

            console.log(newNode)
            
            setNodes((nds) => [...nds, newNode]);
        } catch (error) {
            console.error("Error in onDrop:", error);
        }
    };
    

    const onDragOver = (event) => {
        try {
            event.preventDefault();
            event.dataTransfer.dropEffect = "move";
        } catch (error) {
            console.error("Error in onDragOver:", error);
        }
    };

    // const handleJourneyName = (event) => {
    //     try {
    //         let obj = {
    //             id: journetID,    
    //             name: event.target.value
    //         }
    //         setjourneyName(obj);
    //     } 
    //     catch (error) {
    //         console.error("Error in handleJourneyName:", error);
    //     }
    // }

    // const deleteListNode = useCallback((nodeIdToDelete) => {
    //     try {
    //         // Filter out the node with the specified id
    //         const updatedNodes = nodes.filter(node => node.id !== nodeIdToDelete);
    //         // Update the nodes state
    //         setNodes(updatedNodes);
    //     } catch (error) {
    //         console.error("Error in deleteListNode:", error);
    //     }
    // }, [nodes]);

    return (
        <>
            <div className='mb-3'>
                {/* <div>
                    <TextField 
                        id="outlined-basic" 
                        label="Journey name" 
                        variant="outlined" 
                        value={journeyName.name}
                        onChange={handleJourneyName}
                    />
                </div> */}
                <JourneySave 
                    journeyID={journeyID}
                />
            </div>
            <div className="dndflow">
                <ReactFlowProvider>
                    <Sidebar />
                    <div
                        className="reactflow-wrapper"
                        style={ { height: "86vh", width: "100%", background: "#fff" } }
                    >
                        <ReactFlow
                            nodes={ nodes }
                            edges={ edges }
                            nodeTypes={nodeTypes}
                            edgeTypes={edgeTypes}
                            onNodesChange={ handleNodesChange }
                            // onEdgesChange={ handleEdgesChange }
                            onConnect={ onConnect }
                            onDrop={ onDrop }
                            onDragOver={ onDragOver }
                        >
                            <Controls />
                            <MiniMap />
                            <Background variant="dots" gap={ 12 } size={ 1 } />
                        </ReactFlow>
                    </div>
                </ReactFlowProvider>
                <Editbar />
            </div>
        </>
       
    );
}


const SuspenseCreateJourney = () => (
    <Suspense 
        fallback={ <Loading /> }
    >
        <CreateJourney />
    </Suspense>
);

export default SuspenseCreateJourney;