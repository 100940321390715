import { Handle, Position } from 'reactflow';
import React, { useState } from 'react';
import styles from '../../assets/css/start-node.css';
import { useRecoilState } from 'recoil';
import { Trash3 } from 'react-bootstrap-icons';
import { editBarOpenState, editTypesState } from '../../store/state';

function StartNode({ isConnectable, nodeId }) {
    const [editBarOpen, setEditBarOpen] = useRecoilState(editBarOpenState);
    const [editTypes, setEditTypes] = useRecoilState(editTypesState);
    // const nodeId = 'starting_node';
    const opensideBar = () => {
        setEditBarOpen(!editBarOpen);
        setEditTypes({ selected_id: nodeId, selected_type: 'start_node' });
    };

    return (
        <>
        <div className='text-updater-node' onClick={opensideBar}>
            <div>
            <div className='heading__div'>
                <h1 className='heading-text'>Starting Node</h1>
            </div>
            <div className='content__div'>Select Event</div>
            </div>
        </div>
        <Handle
            type='source'
            position={Position.Right}
            id='b'
            isConnectable={isConnectable}
            style={{
            backgroundColor: '#5956d6',
            border: '1px solid #5956d6',
            padding: '5px',
            position: 'absolute',
            right: '-8px',
            top: '50%',
            transform: 'translateY(-50%)',
            boxShadow: '0 0 0 0.25rem #c5cad9',
            zIndex: '1',
            }}
        />
        </>
    );
}

export default StartNode;
