import React from "react";
import styles from "../../assets/scss/components/model-list.module.scss";
import { Link } from "react-router-dom";

const AIModelList = (props) => {
    return (
        <div>
            {   props?.list?.length ?
                props.list.map((model, index) => {
                    return (
                        <Link to={`/model-list/${model.id}`} className={styles.card__link}>
                            <div key={index} className={styles.list__card}>
                                <div style={{ backgroundColor: getRandomColor(model.name.charAt(0)) }} className={styles.first__letter}>
                                    <p className="mb-0">{model.name.charAt(0)}</p>
                                </div>
                                <h5 className={styles.card__heading}>{model.name}</h5>
                            </div>
                        </Link>
                    )
                })  : 
                <p>No Intent Detection Found</p>
            }
        </div>
    )
};

export default AIModelList;

const getRandomColor = (firstLetter) => {
    const colorMap = {
        A: "#FF5733",
        B: "#52a836",
        C: "#FF3366",
        D: "#31e0b5",
        E: "#CC33FF",
        F: "#FFCC33",
        G: "#33e4ff",
        H: "#3366FF",
        I: "#FF33CC",
        J: "#d42fc0",
        K: "#afe312",
        L: "#33CCFF",
        M: "#FF6633",
        N: "#33FF33",
        O: "#FF6633",
        P: "#0fccd6",
        Q: "#4091c7",
        R: "#a277e6",
        S: "#ffb833",
        T: "#33CC66",
        U: "#CC6633",
        V: "#3366CC",
        W: "#66CC33",
        X: "#CC3366",
        Y: "#4c9ec7",
        Z: "#ff6699"
    }
    return colorMap[firstLetter.toUpperCase()];
}