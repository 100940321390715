import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../plugins/axios";
import { useRecoilState } from 'recoil';
import { AlertOpenState } from '../../store/journey-list';
import { AIIntentDataState } from '../../store/AI-intent';
import AlertDialog from '../../components/basic-components/alert-dialog';
import styles from '../../assets/scss/components/model-list.module.scss';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import Cookies from 'js-cookie';

const IntentDetails = () => {
    const [values, setValues] = useRecoilState(AlertOpenState);
    const [intentData, setData] = useRecoilState(AIIntentDataState)
    const [editedText, setEditedText] = useState({});
    const [editingId, setEditingId] = useState(null);
    const [addMore , setAddMore] = useState(false);
    const [newText, setNewText] = useState('');
    const [error, setError] = useState(false);

    const param = useParams();

    useEffect(() => {
        try {
            const token = Cookies.get('Token');
            if(!token){
                localStorage.removeItem('Token');
                window.location.href = '/login'
            }
            else{
                fetchDetails();   
            }
        } 
        catch (error) {
            console.log(error)    
        }
    }, [])

    const fetchDetails = async () => {
        try {
            const res = await axiosInstance.get(`/api/v1/communications/whatsapp/ai-intents/${param.id}/`)
            const { data } = res
            if(res.status === 200 || res.status === 201) {
                setData(data)
                
            }
        } 
        catch (error) {
            console.log(error)    
            setValues({ ...values, open: true, msgType: 'error', alertMsg: 'Something went wrong.' })
        }
    };

    const handleEdit = (id, newText) => {
        try {
            setEditingId(id);
            setEditedText({ id, newText });
        } 
        catch (error) {
            console.log(error)    
        }
    };

    const handleChange = (e) => {
        setEditedText({ ...editedText, newText: e.target.value });
    };

    const handleSave = () => {
        const updatedDescriptions = intentData.descriptions.map((desc) => {
            if (desc.id === editingId) {
                return { ...desc, description: editedText.newText };
            }
            return desc;
        })
        setData({ ...intentData, descriptions: updatedDescriptions });

        console.log(intentData)
        setEditingId(null);
    };

    const handleAddMore = () => {
        setAddMore(true);
    };

    const handleSaveAddMore = (e) => {
        setNewText(e.target.value)
        setError(false)
    };

    const handlesaveMore = () => {
        if(newText.trim() !== ''){
            setData(prevState => ({
                ...intentData,
                descriptions: [
                    ...prevState.descriptions,
                    { description: newText }
                ]
            }));
            setAddMore(false);
            setNewText('');
            setError(false)
        }
        else{
            setError(true)
        }
    };

    const handleClose = () => {
        setAddMore(false);
        setNewText('');
    };

    const saveIntentDescription = async () => {
        try {
            const payload = {
                name: intentData.name,
                descriptions: intentData.descriptions
            }
            const res = await axiosInstance.put(`/api/v1/communications/whatsapp/ai-intents/${param.id}/`, payload)
            if(res.status === 200 || res.status === 201) {
                setValues({ ...values, open: true, msgType: 'success', alertMsg: 'Description saved successfully.' })
            }
            else {
                setValues({ ...values, open: true, msgType: 'error', alertMsg: 'Something went wrong.' })
            }
        } 
        catch (error) {
            console.log(error) 
            setValues({ ...values, open: true, msgType: 'error', alertMsg: 'Something went wrong.' })
        }
    };

    const handleDeleteDescription = (id) => {
        setData({
            ...intentData,
            descriptions: intentData.descriptions.filter((desc) => desc.id !== id)
        })
    }

    return (
        <div>
            <div className={styles.intent__details__container}>
                <div className="d-flex align-items-center">
                    <h4 className="flex-grow-1">{intentData.name}</h4>
                    <button onClick={saveIntentDescription} className={styles.add__btn}>Save</button>
                </div>

                <div className="mt-3">
                    {intentData && intentData.descriptions && intentData.descriptions.map((desc, index) => (
                        <div key={index}>
                            {editingId === desc.id ? (
                                <div>
                                    <textarea
                                        className="form-control" 
                                        rows="3"
                                        type="text"
                                        value={editedText.newText}
                                        onChange={handleChange}
                                    />
                                    <div className="d-flex justify-content-end mt-2">
                                        <Tooltip title="Done Edit" arrow placement="top">
                                            <IconButton
                                                className={styles.action__icons} 
                                                onClick={handleSave}
                                            >
                                                <CheckRoundedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            ) : (
                                <div className="d-flex gap-3 align-items-center mt-2 mb-2">
                                    <p className={styles.intent__name}> - {desc.description}</p>
                                    <Tooltip title="Edit Description" arrow placement="top">
                                        <IconButton
                                            className={styles.action__icons} 
                                            onClick={() => handleEdit(desc.id, desc.description)}
                                        >
                                            <ModeEditOutlineOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete Description" arrow placement="top">
                                        <IconButton
                                            className={styles.action__icons} 
                                            onClick={() => handleDeleteDescription(desc.id)}
                                        >
                                            <DeleteOutlineRoundedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                <div>
                    { addMore && (
                        <>
                            <textarea
                                className={ error ? 'form-control is-invalid' : 'form-control'}
                                rows="3"
                                type="text"
                                onChange={handleSaveAddMore}
                                placeholder='Description to train the model to identify this intent. (Multiple descriptions can be added to create new intent only one is needed)'
                            />
                            {error && <small className="text-danger">Please enter description to train the model</small>}
                            <div className="d-flex gap-2 justify-content-end mt-2">
                                <Tooltip title="Done" arrow placement="top">
                                    <IconButton
                                        className={styles.action__icons} 
                                        onClick={handlesaveMore}
                                    >
                                        <CheckRoundedIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Close" arrow placement="top">
                                    <IconButton
                                        className={styles.action__icons} 
                                        onClick={handleClose}
                                    >
                                        <CloseRoundedIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </>
                    )}
                </div>
                <div className="mt-4">
                    <button className={styles.add__btn} onClick={handleAddMore}>Add More</button>
                </div>
            </div>
            <AlertDialog />
        </div>
    );
}

export default IntentDetails;