import React from 'react'
import Card from '@mui/material/Card';
import styles from '../../assets/scss/page/broadcasts.module.scss';
import { useNavigate } from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
const BroadcastList = ({item, index}) => {

  	const query = new URLSearchParams(location.search);
    const page = parseInt(query.get('page') || '1', 10);

    const navigate = useNavigate();
	
	return (
		<Card variant="outlined" onClick={() => navigate(`/broadcasts/${item.id}`)} className={styles.broadcast__card}>
			<div className="d-flex justify-content-between w-100">
				<div>
					{((page-1)*20)+index+1}. {item.name} 
				</div>
				{/* <div>{item.is_pause ? 'Paused' : 'Active'}</div> */}
				<small className='text-secondary'>{item.schedule_time} <span style={{marginLeft: '6px'}}>{item.is_pause ? <CircleIcon fontSize='8px' sx={{ color: 'red' }} /> : <CircleIcon fontSize='8px' sx={{ color: 'white' }} />}</span></small>
			</div>
		</Card>
	)
}

export default BroadcastList
