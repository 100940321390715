import { StrictMode } from "react";
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.css';
import { RecoilRoot } from 'recoil';
import '@fontsource/roboto';  
import App from "./App";
import "./assets/scss/main.scss"

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
      <RecoilRoot>
        <App />
      </RecoilRoot>
  </StrictMode>,
  rootElement
);
