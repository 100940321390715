import React from "react";
import styles from '../../assets/scss/components/chats.module.scss';

const NoChats = () => {
    return(
        <>
            <div className={styles.no__chats__div}>
                <div>
                    <h4>To display messages, select chat.</h4>
                    <p>To initiate a conversation with a user, click on any chat.</p>
                </div>
            </div>
        </>
    )
}

export default NoChats;