import React, { useEffect, useState } from 'react'
import styles from '../../assets/scss/page/optins-page.module.scss';
import axiosInstance from '../../plugins/axios';
import { useParams, useNavigate } from 'react-router-dom';
import OptInStatus from '../../components/optins/optin-status';
import Cookies from 'js-cookie';
import Button from '@mui/material/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const EditOptIn = () => {
    const { id } = useParams();
    const [data, setData] = useState({});
    const navigate = useNavigate();

    useEffect(async () => {
        try{
            const token = Cookies.get('Token');
            if(!token){
                localStorage.removeItem('Token');
                window.location.href = '/login'
            }
            else{
                const response = await axiosInstance.get(`/api/v1/communications/whatsapp/users/optin/${id}/`);
                setData(response.data.result)
            }
        }
        catch(error){
            console.log(error)
        }
    },[])

    const handleBack = () => {
        navigate(-1)
    };

    return (
        <>
            <div className={styles.model__list__container}>
                <Button size='small' color="primary" startIcon={<KeyboardBackspaceIcon />} className='mb-1' onClick={handleBack}>
                    Back
                </Button>
                
                <h4>Opt-Ins</h4>
                <p>Analytics of opt-in request.</p>
                <p className={styles.optins__name}>{data.name}</p>
                <p className={styles.optins__id}>ID: #{data.request_id}</p>
            </div>
            <OptInStatus item={data} />
        </>
    )
}

export default EditOptIn
