import { atom, selector } from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import StartNode from '../components/start/startNode';
import { recoilPersist } from 'recoil-persist'
import axiosInstance from '../plugins/axios';

const { persistAtom } = recoilPersist();

export const editBarOpenState = atom({
    key: 'editBarOpen',
    default: false,
});

export const editTypesState = atom({
    key: 'editTypes',
    default:{
        selected_id: '',
        selected_type: ''
    }
});

export const editListState = atom({
    key: 'editList',
    default: ''
});

export const isDraggableState = atom({
    key: 'isDraggableList',
    default: true
});

// Function to generate initial nodes state
export const generateInitialNodesState = () => {
    return [
        { 
            id: 'starting_node', 
            data: { label: <StartNode nodeId={'starting_node'} /> }, 
            deletable: false, 
            sourcePosition: 'right', 
            position: { x: 300, y: 200 }, 
            message_to_send: {
                id: 'starting_node',
                type: 'starting_node',
                value: '',
                prev_node_id: null,
                next_node_id: '',
                fallback_node_id: null
            } 
        }
    ];
};

// Function to generate initial edges state
const generateInitialEdgesState = () => {
    return [];
};

export const nodesState = atom({
    key: 'nodesState',
    default: generateInitialNodesState(), // Call the function to generate initial state
});

export const edgesState = atom({
    key: 'edgesState',
    default: generateInitialEdgesState(), // Call the function to generate initial state
});

export const triggersState = atom({
    key: 'triggersState',
    default: {
        condition: null,
        values: [
            {
                uuid: uuidv4(),
                type: "User Input",
                conditional_type: "Exact",
                triggering_value: ""
            },
        ]
    }
})

export const isFormSubmittedState = atom({
    key: 'isFormSubmitted',
    default: false
})

export const textVariableListState = atom({
    key: 'textVariableList',
    default: [
        {
            name: "Parent's Name",
            value: "{{parent_name}}"
        },
        {
            name: "Parent's Email",
            value: "{{parent_email}}"
        },
        {
            name: "Parent's All Numbers",
            value: "{{parent_numbers}}"
        },
        {
            name: "Parent's Number",
            value: "{{parent_number}}"
        },
        {
            name: "Children's Names",
            value: "{{parent_childs}}"
        },
        {
            name: "Children's Classes",
            value: "{{child_classes}}"
        },
        {
            name: "Parent's Budget",
            value: "{{parent_budget}}"
        },
    ]
})
