import React from "react";
import ChatsNumber from "./chats";
import ChatDetails from "./chat-details";
import { useRecoilState, useRecoilValue } from 'recoil';
import styles from '../../assets/scss/components/chats.module.scss';
import { allChatsState, chatIDState } from '../../store/agent-chats';
import AlertDialog from '../../components/basic-components/alert-dialog';
import NoChats from '../../components/chats/no-chats';

const AllChats = () => {
    const [allChats, setAllChats] = useRecoilState(allChatsState);
    const allChatsData = useRecoilValue(allChatsState);
    const [chatID, setChatID] = useRecoilState(chatIDState);

    const scrollBottom = () => {
        // const chatList = document.getElementById('chat__list');
        // chatList.scrollTop = chatList.scrollHeight;
        // console.log('scroll')
        const chatList = document.getElementById('last_element');
        chatList.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }

    return(
        <div className={styles.chat__main__div}>
            <div className={styles.chat__first__div}>
                <ChatsNumber 
                    allChats={allChatsData}
                    scrollToBottom={scrollBottom}
                />
            </div>
            <div className={styles.chat__second__div}>
                {chatID ? <ChatDetails /> : <NoChats />} 
            </div>
            <AlertDialog />
        </div>
    )
}

export default AllChats;