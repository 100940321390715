import React, { useState, useRef, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { openCreateTemplateBoxState } from '../../store/template';
import { useRecoilState } from 'recoil';
import styles from '../../assets/scss/page/template-page.module.scss';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import axiosInstance from '../../plugins/axios';
import FormControl, { useFormControl } from '@mui/material/FormControl';
// import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import AlertDialog from '../../components/basic-components/alert-dialog';
import { AlertOpenState } from '../../store/journey-list';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const IsTemplateSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&::before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&::after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
}));

const ErrorText = styled('p')`
  color: red;
  font-size: 0.75rem;
  margin-bottom: 0px;
  margin-top: 2px;
`;

function MyFormHelperText() {
    const { focused } = useFormControl() || {};
  
    const helperText = React.useMemo(() => {
      if (focused) {
        return 'This field is being focused';
      }
  
      return 'Helper text';
    }, [focused]);
  
    return <FormHelperText>{helperText}</FormHelperText>;
}

const TemplateCreateModal = () => {
    const [openDialog, setOpenDialog] = useRecoilState(openCreateTemplateBoxState);
    const [templateName, setTemplateName] = useState('');
    const [whatsappTemplateName, setWhatsappTemplateName] = useState('');
    const [message, setMessage] = useState('');
    const [messageBlur, setMessageBlur] = useState(false);
    const [header, setHeader] = useState('');
    const [footer, setFooter] = useState('');
    const [mediaUrl, setMediaUrl] = useState('');
    const [messageType, setMessageType] = useState('text');
    const [mediaType, setMediaType] = useState('');
    const [fileName, setFileName] = useState('');
    const [isTemplate, setIsTemplate] = useState(false);
    const [values, setValues] = useRecoilState(AlertOpenState);
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const inputRef = useRef(null);

    useEffect(async () => {
        try{
            if(openDialog.id){
                const res = await getTemplates();
                setTemplateName(res.name);
                setWhatsappTemplateName(res.whatsapp_template_name);
                setMessage(res.message);
                setHeader(res.header);
                setFooter(res.footer);
                setMediaType(res.content_type);
                setFileName(res.filename);
                setMessageType(res.msg_type);
                setMediaUrl(res.media_url);
                setIsTemplate(res.is_template);
            }else{
                setTemplateName('');
                setWhatsappTemplateName('');
                setMessage('');
                setHeader('');
                setFooter('');
                setMediaType('');
                setFileName('');
                setMessageType('text');
                setMediaUrl('');
                setIsTemplate(false);
                setMessageBlur(false);
            }
        }catch(err){
            console.log(err);
        }
    }, [openDialog]);

    const getTemplates = async () => {
        try{
            const {data} = await axiosInstance.get(`/api/v1/communications/whatsapp/campaigns/template/?id=${openDialog.id}`);
            return data.result
        }catch(err){
            console.log(err);
        }
    }

    const handleClose = () => {
        setOpenDialog({
            open: false,
            id: '',
            type: '',
            title: ''
        })
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            // Perform validation
            if (
                handleTemplateValidation() !== '' ||
                handleWhatsappTemplateValidation() !== '' ||
                handleMessageValidation() !== '' ||
                handleHeaderFooterValidation() !== ''
            ) {
                // If any validation fails, display error messages and return
                setMessageBlur(true);
                setLoading(false);
                return;
            }
            // console.log(messageType)
            if(messageType !== 'text'){
                // Check if media url is not empty
                if(!mediaUrl){
                    setValues({
                        ...values,
                        open: true,
                        msgType: 'error',
                        alertMsg: 'Please upload a media file.'
                    });
                    setLoading(false);
                    return;
                }
            }
            const payload = {
                name: templateName,
                whatsapp_template_name: whatsappTemplateName,
                message: message,
                header: header,
                footer: footer,
                media_url: mediaUrl,
                msg_type: messageType,
                content_type: mediaType,
                filename: fileName,
                is_template: isTemplate
            }
            
            let response = ''
            if(openDialog.type === 'edit' && openDialog.id){
                payload['id'] = openDialog.id
                response = await axiosInstance.put('/api/v1/communications/whatsapp/campaigns/template/', payload);
            }else{
                response = await axiosInstance.post('/api/v1/communications/whatsapp/campaigns/template/', payload);
            }
            if (response.status === 201 || response.status === 200) {
                setOpenDialog(false);   
                window.location.reload();
            }else{
                setValues({
                    message: 'Something went wrong. Please try again.',
                    severity: 'error',
                    open: true
                })
                setLoading(false);
            }

            
        } 
        catch (error) {
            console.log(error)
            setLoading(false);
            setValues({
                message: 'Something went wrong. Please try again.',
                severity: 'error',
                open: true
            })
        }        
    }

    const uploadMedia = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('mime_type', file.type);
        return axiosInstance.post('/api/v1/communications/whatsapp/campaigns/upload-media/', formData);
    }
    
    const onChangeFileHandler = async (e) => {
        try{
            setLoading(true);
            const file = e.target.files[0];
            if(file){
                if(file.name.length > 50){
                    setValues({ ...values, open: true, msgType: 'error', alertMsg: 'File name should not exceed 50 characters' })
                    setLoading(false);
                    return;
                }
                if(file.size > 10240000){
                    setValues({ ...values, open: true, msgType: 'error', alertMsg: 'File size should not exceed 10 MB' })
                    setLoading(false);
                    return;
                }
                if(messageType === 'image'){
                    console.log(file)
                    if(file.type !== 'image/jpeg' && file.type !== 'image/png'){
                        setValues({ ...values, open: true, msgType: 'error', alertMsg: 'Please upload files in JPEG or PNG format only.' })
                        setLoading(false);
                        return
                    }
                }else if(messageType === 'video'){
                    if(file.type !== 'video/mp4' && file.type !== 'video/3gpp'){
                        setValues({ ...values, open: true, msgType: 'error', alertMsg: 'Please upload files in MP4 or 3GPP format only.' })
                        setLoading(false);
                        return
                    }
                }else if (messageType === 'audio'){
                    if(file.type !== 'audio/acc' && file.type !== 'audio/mp4' && file.type !== 'audio/amr' && file.type !== 'audio/mpeg' && file.type !== 'audio/ogg'){
                        setValues({ ...values, open: true, msgType: 'error', alertMsg: 'Please upload files in ACC, MP4, AMR, MP3 or OGG format only.' })
                        setLoading(false);
                        return
                    }
                }
                const res = await uploadMedia(file);
                if(res.status === 201 || res.status === 200){
                    setValues({ ...values, open: true, msgType: 'success', alertMsg: 'File uploaded successfully' })
                    setMediaUrl(res.data.url);
                    setFileName(file.name);
                    setMediaType(file.type);
                    setLoading(false);
                    return
                }else{
                    setValues({ ...values, open: true, msgType: 'error', alertMsg: 'Error uploading file' })
                    setLoading(false);
                    return
                }
            }
            setLoading(false);
        }catch (error) {
            setLoading(false);
            console.log(error)
        }
    }

    const handleTemplateValidation = () => {
        if(templateName.length > 50){
            return 'Template name should not exceed 50 characters'
        }
        return ''
    }

    const handleWhatsappTemplateValidation = () => {
        if(whatsappTemplateName.length > 65){
            return 'Whatsapp template name should not exceed 65 characters'
        }
        return ''
    }

    const handleMessageValidation = () => {
        if(message === ''){
            return 'Message is required'
        }else if(message.length > 900){
            return 'Message should not exceed 900 characters'
        }
        return ''
    }

    const handleHeaderFooterValidation = () => {
        if(header.length > 60){
            return 'Header should not exceed 60 characters'
        }
        return ''
    }

    const isFormValid = () => {
        return templateName.trim() !== '' && description.trim() !== '';
    };

    const resetFileData = () => {
        setMediaUrl('');
        setFileName('');
        setMediaType('');
        setValues({ ...values, open: true, msgType: 'success', alertMsg: 'Media removed successfully' })
    }

    const viewFile = () => {
        window.open(mediaUrl, '_blank');
    }

    const handleMessageTypeChange = (e) => {
        setMessageType(e.target.value)
        setMediaUrl('');
        setFileName('');
        setMediaType('');
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={openDialog.open}
                onClose={handleClose}
            >
                <div className={styles.dialog__container}>
                    <Stack direction="row" spacing={2} className='mt-1 d-flex justify-content-between align-items-center'>
                        <h4>{openDialog.title}</h4>
                        <div>
                            <FormControl>
                                <div className='d-flex'>
                                    <FormControlLabel
                                        control={
                                            <IsTemplateSwitch 
                                                checked={isTemplate} 
                                                onChange={(e) => setIsTemplate(e.target.checked)} 
                                            />
                                        }
                                        label="Is Template"
                                    />
                                    <Tooltip title="Enable in case of interactive template or if template has header or footer.">
                                        <IconButton>
                                            <InfoOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </FormControl>
                            <IconButton onClick={handleClose}>
                                <CloseOutlinedIcon 
                                    className={styles.close__btn} 
                                /> 
                            </IconButton>
                        </div>
                    </Stack>
                    <div>
                        <form noValidate autoComplete="off" onSubmit={handleSubmit} className='mt-4 w-100'>
                            <Stack direction="row" spacing={2} >
                                <FormControl fullWidth>
                                    <TextField
                                        id="template-name"
                                        label="Template Name"
                                        style={{ color: theme.palette.primary.dark }}
                                        value={templateName}
                                        onChange={(e) => setTemplateName(e.target.value)}
                                        size='small'
                                        onBlur={handleTemplateValidation}
                                        error={handleTemplateValidation() !== '' && templateName !== ''}
                                        />
                                    {handleTemplateValidation() !== '' && templateName !== '' ?  
                                        <ErrorText>
                                            {handleTemplateValidation()}
                                        </ErrorText>:
                                        <FormHelperText id="component-error-text" style={{ marginLeft: '4px' }}>Note: Name for the template</FormHelperText>
                                    }
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField id="whatsapp-template-name" 
                                        label="Whatsapp Template Name" 
                                        style={{ color: theme.palette.primary.dark }} 
                                        value={whatsappTemplateName} 
                                        onChange={(e) => setWhatsappTemplateName(e.target.value)} 
                                        size='small' 
                                        onBlur={handleWhatsappTemplateValidation}
                                        error={handleWhatsappTemplateValidation() !== '' && whatsappTemplateName !== ''}
                                    />
                                    {
                                        handleWhatsappTemplateValidation() !== '' && whatsappTemplateName !== '' ? 
                                        <ErrorText>{handleWhatsappTemplateValidation()}</ErrorText> : 
                                        <FormHelperText id="component-error-text" style={{ marginLeft: '4px' }}>Note: Name for the template registered at WhatsApp/Meta</FormHelperText>
                                    }
                                </FormControl>
                            </Stack>
                            <FormControl fullWidth className='mt-3'>
                                <TextField id="message" 
                                    label="Message" rows={3} 
                                    style={{ color: theme.palette.primary.dark }}
                                    multiline 
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)} 
                                    onBlur={() => setMessageBlur(true)} 
                                    size='small'
                                    error={messageBlur && handleMessageValidation() !== ''}
                                />
                                {   messageBlur && handleMessageValidation() !== '' ? 
                                    <ErrorText>{handleMessageValidation()}</ErrorText> :
                                    <FormHelperText id="component-error-text" style={{ marginLeft: '4px' }}></FormHelperText>
                                }
                            </FormControl>
                            <Stack direction="row" spacing={2} >
                                <FormControl fullWidth className='mt-3'>
                                    <TextField id="header" 
                                        label="Header" 
                                        style={{ color: theme.palette.primary.dark }} 
                                        value={header} 
                                        onChange={(e) => setHeader(e.target.value)} 
                                        size='small' 
                                        onBlur={handleHeaderFooterValidation}
                                        error={handleHeaderFooterValidation() !== '' && header !== ''}
                                    />
                                    {handleHeaderFooterValidation() !== '' && header !== '' ?  
                                        <ErrorText>
                                            {handleHeaderFooterValidation()}
                                        </ErrorText>:
                                        <FormHelperText id="component-error-text" style={{ marginLeft: '4px' }}>Note: Header if any.</FormHelperText>
                                    }
                                </FormControl>
                                <FormControl fullWidth className='mt-3'>
                                    <TextField id="footer" 
                                        label="Footer" 
                                        style={{ color: theme.palette.primary.dark }} 
                                        value={footer} 
                                        onChange={(e) => setFooter(e.target.value)} 
                                        size='small'
                                        onBlur={handleHeaderFooterValidation}
                                        error={handleHeaderFooterValidation() !== '' && footer !== ''}
                                    />
                                    {handleHeaderFooterValidation() !== '' && footer !== '' ?  
                                        <ErrorText>
                                            {handleHeaderFooterValidation()}
                                        </ErrorText>:
                                        <FormHelperText id="component-error-text" style={{ marginLeft: '4px' }}>Note: Footer if any.</FormHelperText>
                                    }
                                </FormControl>
                            </Stack>
                            <Stack direction="row" spacing={2} className='mt-3'>
                                <div className="w-50">
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="message-type">Message Type</InputLabel>
                                        <Select
                                            labelId="message-type"
                                            id="message-type"
                                            value={messageType}
                                            label="Message Type"
                                            onChange={(e) => handleMessageTypeChange(e)}
                                        >
                                            {/* <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem> */}
                                            <MenuItem value={'text'}>Text</MenuItem>
                                            <MenuItem value={'image'}>Image</MenuItem>
                                            <MenuItem value={'video'}>Video</MenuItem>
                                            <MenuItem value={'document'}>Document</MenuItem>
                                            <MenuItem value={'audio'}>Audio</MenuItem>
                                        </Select>
                                        <FormHelperText id="component-error-text" style={{ marginLeft: '4px' }}>Note: Select text in case of interactive template.</FormHelperText>
                                    </FormControl>
                                </div>
                                {messageType !== 'text' && <div className="w-50">
                                    <FormControl sx={{ minWidth: 200 }}>
                                        {!fileName ? <Button
                                            component="label"
                                            role={undefined}
                                            variant="contained"
                                            tabIndex={-1}
                                            startIcon={<CloudUploadIcon />}
                                            disabled={loading}
                                            >
                                            Upload file
                                            <VisuallyHiddenInput 
                                                type="file" 
                                                accept={messageType === "image" ? "image/jpeg, image/png" : messageType === "video" ? "video/mp4, video/3gp" : messageType === "audio" ? "audio/acc, audio/mp4, audio/amr, audio/mpeg, audio/ogg" : "*"} 
                                                onChange={onChangeFileHandler} 
                                                ref={inputRef}  
                                            />
                                        </Button> : 
                                        <div className='d-flex align-items-center' style={{backgroundColor: theme.palette.primary.light, color: theme.palette.primary.dark, padding: '4px 8px', borderRadius: '20px', marginLeft: '4px'}}>
                                            {fileName}
                                            <Tooltip title="Remove file">
                                                <IconButton size='small' onClick={resetFileData} style={{marginLeft: '2px', cursor: 'pointer'}}>
                                                    <CancelIcon style={{color: theme.palette.primary.dark}} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="View file">
                                                <IconButton size='small' onClick={viewFile} style={{marginLeft: '2px', cursor: 'pointer'}}>
                                                    <RemoveRedEyeIcon style={{color: theme.palette.primary.dark}} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>}
                                    </FormControl>
                                </div>}
                            </Stack>
                            <Stack direction="row" spacing={2} className='mt-3 d-flex justify-content-end align-items-center'>
                                <Button variant="outlined" onClick={() => setOpenDialog({open: false, type: '', title: '', id: ''})} className='d-flex justify-content-center align-items-center' endIcon={<CancelIcon className='mb-1' />}>
                                    Cancel
                                </Button>
                                <Button variant="contained" type='submit' endIcon={<SendIcon />}>
                                    {openDialog.type === 'edit' && openDialog.id ? 'Update' : 'Create'}
                                </Button>
                            </Stack>
                        </form>
                    </div>
                </div>   
            </Dialog>
            <AlertDialog />   
        </>
    )
}

export default TemplateCreateModal;