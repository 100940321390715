import React, { useRef, useEffect } from 'react';

const InfiniteScrollObserver = ({ onIntersect, rootMargin = '0px', threshold = 1.0 }) => {
  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onIntersect();
          }
        });
      },
      {
        root: null,
        rootMargin,
        threshold
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [onIntersect, rootMargin, threshold]);

  return <div ref={targetRef} />;
};

export default InfiniteScrollObserver;
