import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Menubar from '../menubar/menubar'; // Import your Menubar component
import checkAuth from '../../middleware/auth';
import LoginPage from '../../pages/login/login-page';
import { useNavigate } from 'react-router-dom';

const Layout = () => {
  useEffect(() => {
    try {
      if(localStorage.getItem('project_type') === 'Prod'){
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "nq8qrnx420");
      }
      else{
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "nq8qb2m924");
      }
    } 
    catch (error) {
      console.log(error)
    }
    
  }, [])

  const isAuthenticated = checkAuth();
  return (
    <> 
      {
        isAuthenticated ? isLogin() : isNotLogin()
      }
    </>
  );
};

const isLogin = () => {
  const path = window.location.pathname
  return(
    <div style={{ display: 'flex' }}>
      {
        path !== '/login' && <Menubar />
      }
      <main style={{ flexGrow: 1, padding: '1rem' }}>
        <Outlet />
      </main>
    </div>
  )
}

const isNotLogin = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/login')
  }, [navigate]);

  return(
    <LoginPage /> 
  )
}

export default Layout;