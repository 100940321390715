import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import { useRecoilState } from 'recoil';
import { editAddModelState } from '../../store/variables'
import styles from '../../assets/scss/components/variables.module.scss';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import axiosInstance from '../../plugins/axios';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const EditAddModel = () => {
    const [values, setValues] = useRecoilState(editAddModelState);
    const [erroOpen, setErroOpen] = useState(false);
    const [errorMessage, setErrorMessages] = useState({name: ''});
    const [inputError, setInputError] = useState('');
    
    const handleSubmit = async(e) => {
        try {
            e.preventDefault();
            const payload = {
                name: values.formValues.var_name,
                storage_key: values.formValues.var_key,
            }
            if(values.type === 'edit'){
                const res = await axiosInstance.patch(`/api/v1/communications/whatsapp/variables/${values.formValues.id}/`, payload);
                if(res.status === 200 || res.status === 201){
                    window.location.reload();
                }
            }
            else if(values.type === 'add'){
                const res = await axiosInstance.post('/api/v1/communications/whatsapp/variables/', payload);
                if(res.status === 200 || res.status === 201){
                    window.location.reload();
                }
            }
            setValues({...values, open: false})
        } 
        catch (error) {
            console.log(error)
            setErroOpen(true); 
            setErrorMessages({name: error.response.data.name[0]})
        }
    }

    const handleErroClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErroOpen(false);
    }

    //input condition
    const validateInput = (value) => {
        // Allows letters, digits, underscores, and spaces (but not at the start)
        const isValid = /^[a-zA-Z_][a-zA-Z0-9_]*[ ]?[a-zA-Z0-9_ ]*$/.test(value);
        if (!isValid) {
            if (/^[0-9 ]/.test(value)) {
                return "Variable name cannot start with a number or space.";
            }
            if (/[^a-zA-Z0-9_ ]/.test(value)) {
                return "Only letters, digits, underscores, and spaces are allowed.";
            }
        }
        return '';
    }
    

    const handleChange = (e) => {
        const { value } = e.target;
        const errorMessage = validateInput(value);
        setValues({...values, formValues: {...values.formValues, var_name: value}})
        setInputError(errorMessage);
    };

    return (
        <div>
           <Dialog open={values.open}>
                <div className='d-flex justify-content-end px-2 pt-2'>
                    <CloseOutlinedIcon 
                        className={styles.close__btn} 
                        onClick={(e) => setValues({...values, open: false})}
                    />
                </div>
                <div className={styles.edit_add_model_container}>
                    <h5 className='mb-4'>{values.title}</h5>
                    <form>
                        <div className="mb-3">
                            <label htmlFor="var_name" className={styles.form__label}>Variable Name</label>
                            <input 
                                type="text" 
                                className="form-control"
                                placeholder='Variable Name'
                                id="var_name" 
                                value={values.formValues.var_name}
                                onChange={handleChange}
                            />
                            {inputError && (
                                <small style={{ color: 'red', marginTop: '8px' }}>
                                    {inputError}
                                </small>
                            )}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="var_key" className={styles.form__label}>Variable Key</label>
                            <input 
                                type="text" 
                                className="form-control"
                                placeholder='Variable Key' 
                                id="var_key" 
                                value={values.formValues.var_key}
                                onChange={(e) => setValues({...values, formValues: {...values.formValues, var_key: e.target.value}})}
                                disabled={values.type === 'edit'}
                            />
                        </div>
                        <div className='d-flex align-items-center justify-content-end'>
                            <button 
                                type="submit" 
                                className={inputError ? styles.disabled__btn : styles.submit__btn}
                                onClick={(e) => handleSubmit(e)}
                                disabled={inputError}
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>

            <Snackbar 
                open={erroOpen} 
                autoHideDuration={6000} 
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                onClose={handleErroClose}
            >
                <Alert
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                    onClose={handleErroClose}
                >
                    {errorMessage.name}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default EditAddModel;