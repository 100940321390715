import React from "react";
import { useRecoilState } from 'recoil';
import { editBarOpenState, editTypesState } from '../../store/state';
import TextEdit from '../edit-components/text-edit';
import UploadEdit from '../edit-components/upload-edit';
import ListEdit from '../edit-components/list-edit';
import StartingNodeEdit from '../edit-components/starting-node-edit';
import ReplyButtonEdit from '../edit-components/reply-button-edit';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import styles from  '../../assets/css/editbar.module.css';

export default () => {
    const [editBarOpen, setEditBarOpen] = useRecoilState(editBarOpenState);
    const [editTypes, setEditTypes] = useRecoilState(editTypesState)


    const toogleItems = () => (
        <div className={styles.editbar__width}>
            <div className={styles.editbar__header}>
                <h3 className={styles.editbar__heading}>Add/Edit {editTypes.selected_type === 'text' ? 'Text Message' : editTypes.selected_type === 'image' ? 'Image Message' : editTypes.selected_type === 'video' ? 'Video Message' : editTypes.selected_type === 'document' ? 'Document Message' : editTypes.selected_type === 'list' ? 'List Message' : editTypes.selected_type === 'reply-button' ? 'Reply Button Message' : editTypes.selected_type === 'start_node' ? 'Triggering Value' : ''}</h3>
                <Button size="small" onClick={() => setEditBarOpen(!editBarOpen)}>
                    X
                </Button>
            </div>
            <div className={styles.editbar__body}>
                {editTypes.selected_type === 'text' && <TextEdit />}
                {(editTypes.selected_type === 'image' || editTypes.selected_type === 'video' || editTypes.selected_type === 'document') &&  <UploadEdit />}
                {editTypes.selected_type === 'list' && <ListEdit />}
                {editTypes.selected_type === 'reply-button' && <ReplyButtonEdit />}
                {editTypes.selected_type === 'start_node' && <StartingNodeEdit />}
            </div>
        </div>
    )
	return (
        <Drawer 
            open={editBarOpen}
            anchor="right"
        >
            {toogleItems()}
        </Drawer>
	);
};
