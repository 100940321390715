import React, { useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { chatsDetailState, userNameState, chatNextURLState, selectedChatState, templatesState, isOptInState, userCombinedIDState, webSocketState, openChatDocumentDialogState } from '../../store/agent-chats';
import { AlertOpenState } from '../../store/journey-list';
import styles from '../../assets/scss/components/chats.module.scss';
import InfiniteScrollObserver from '../Observer/observer';
import axiosInstance from "../../plugins/axios";
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import TextField from '@mui/material/TextField';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ImageIcon from '@mui/icons-material/Image';
import ArticleIcon from '@mui/icons-material/Article';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ErrorIcon from '@mui/icons-material/Error';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import ChatDocumentModal from './chat-document-modal'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import RefreshIcon from '@mui/icons-material/Refresh';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const ChatDetails = () => {
    const [ username, setUserName ] = useRecoilState(userNameState);
    const [ chatDetails, setChatDetails ] = useRecoilState(chatsDetailState);
    const [ nextURL, setNextURL ] = useRecoilState(chatNextURLState);
    const [values, setValues] = useRecoilState(AlertOpenState);
    const [templates, setTemplates] = useRecoilState(templatesState);
    const [isOptIn, setIsOptIn] = useRecoilState(isOptInState);
    const [openChatDocumentDialog, setOpenChatDocumentDialog] = useRecoilState(openChatDocumentDialogState);
    const [attachmentFiles, setAttachmentFiles] = useState(null);
    const [attachmentNextUrl, setAttachmentNextUrl] = useState(null);
    const [ loading, setLoading ] = useState(false)

    const scrollContainerRef = useRef(null);
    const [message, setMessage] = useState('');
    const [messageBlur, setMessageBlur] = useState(false);
    const [isAttachmentDropdownOpen, setIsAttachmentDropdownOpen] = useState(false);
    const [selectedChat, setSelectedChat] = useRecoilState(selectedChatState);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEmojiEl, setAnchorEmojiEl] = useState(null);
    const [clickedImages, setClickedImages] = useState({});
    
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [mediaUrl, setMediaUrl] = useState(null);

    const [openChatsDialog, setOpenChatsDialog] = useState(false);
    const [chatList, setChatList] = useState(null);

    const [replyMessage, setReplyMessage] = useState(null);
    const [openChatReplyDialog, setOpenChatReplyDialog] = useState(false);

    const [message_type, setMessageType] = useState(null);
    const [openTemplatesDialog , setOpenTemplatesDialog ] = useState(false);

    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [combinedID, setUserCombinedID ] = useRecoilState(userCombinedIDState);
    const [isFetching, setIsFetching] = useState(false);
    const [socket, setSocket] = useRecoilState(webSocketState);
    

    const fetchMoreData = async () => {
        try {
            setIsFetching(true);
            const lastId = `chats-${chatDetails[chatDetails.length - 1]?.id}`;
            if(isFetching){
                if(nextURL === null) return;
                const res = await axiosInstance.get(nextURL.replace('http://', 'https://'));
                const data = res.data;
                if(chatDetails && chatDetails.length > 0) {
                    setChatDetails([...chatDetails, ...data.results]);
                    setNextURL(data.next);
                }
                //scroll to last chat id
                const lastChatId = document.getElementById(lastId);
                if(lastChatId) {
                    lastChatId.scrollIntoView({ behavior: 'auto', block: 'end', inline: 'nearest'});
                }
                setIsFetching(false);
            }
        } 
        catch (error) {
            setIsFetching(false);
            console.log(error)    
        }
    }

    const handleChange = (e) => {
        e.preventDefault();
        setMessage(e.target.value);
    };
    
    const handleKeyDown = async (e) => {
        if(message === '') return;
        if (e.key === 'Enter' && !e.shiftKey) {
            await sendMessage();
        }
    };

    const sendMessage = async () => {
        try {
            if(message === '') {
                setMessageBlur(true);
                return;
            }
            let payload = {
                message: message,
                combined_data_id: selectedChat?.data?.combined_data_id,
                message_type: 'text',
                number: selectedChat?.number,
                type: 'two_way',
                country_code: selectedChat?.country_code
            }

            if(selectedFile) {
                payload = {
                    ...payload,
                    filename: selectedFile.name,
                    media_url: mediaUrl.url,
                    message_type: message_type,
                    mime_type: selectedFile?.type
                }
            }
            const res = await axiosInstance.post('/api/v1/communications/whatsapp/send/?send_via=engage', payload);

            if(res.status === 201 || res.status === 200) {
                setMessage('');
                setMessageBlur(false);
                setSelectedFile(null);
                setMediaUrl(null);
                setMessageType(null);

                socket.onmessage = (event) => {
                    const receivedMessage = JSON.parse(event.data);
                    
                    if (receivedMessage) {
                        setChatDetails(prevChatDetails => [receivedMessage.message, ...prevChatDetails]);      
                    }

                    const audioElement = document.getElementById('notification-sound');
                    if (audioElement && receivedMessage.message.conversation_way=="incoming") {
                        audioElement.play();
                    }
                };
            }
        } 
        catch (error) {
            console.log(error)    
        }
    };

    const toggleAttachmentDropdown = () => {
        setIsAttachmentDropdownOpen(!isAttachmentDropdownOpen);
    };

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleEmojiPopoverOpen = (event) => {
        setAnchorEmojiEl(event.currentTarget);
    }

    const handleEmojiPopoverClose = () => {
        setAnchorEmojiEl(null);
    }

    const onEmojiSelect = (emoji) => {
        setMessage(message + emoji.native);
    }

    const handleClickImage = (chatId) => {
        setClickedImages({ ...clickedImages, [chatId]: true });
    };

    const handleAttachmentChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return; // Exit function if no file is selected

        try {
            setIsUploading(true);
            // Check file type and perform actions accordingly
            if(file){

                if (file.type.startsWith('image')) {
                    const allowedExtensions = ['jpeg', 'png'];
                    const fileExtension = getFileExtension(file?.name);
                    console.log(fileExtension);
                    if (!allowedExtensions.includes(fileExtension)) {
                        setIsUploading(false);
                        setValues({ ...values, open: true, msgType: 'error', alertMsg: `Please upload jpeg or png file instead of ${fileExtension}` })
                    }
                    else{
                        await uploadFile(file);
                    }
                    setMessageType('image');
                } 
                else if (file.type.startsWith('audio')) {
                    // Validate file extensions
                    const allowedExtensions = ['acc', 'mp4', 'amr', 'mpeg', 'ogg'];
                    const fileExtension = getFileExtension(file?.name);
                    if (!allowedExtensions.includes(fileExtension)) {
                        setIsUploading(false);
                        setValues({ ...values, open: true, msgType: 'error', alertMsg: `Please upload acc, mp4, amr, mpeg or ogg file instead of ${fileExtension}` })
                    }
                    else{
                        await uploadFile(file);
                    }
                    setMessageType('audio');
                }
                else if(file.type.startsWith('video')) {
                    const allowedExtensions = ['mp4', '3gpp'];
                    const fileExtension = getFileExtension(file?.name);
                    if (!allowedExtensions.includes(fileExtension)) {
                        setIsUploading(false);
                        setValues({ ...values, open: true, msgType: 'error', alertMsg: `Please upload mp4 or 3gpp file instead of ${fileExtension}` })
                    }
                    else{
                        await uploadFile(file);
                    }
                    setMessageType('video');
                }
                else{
                    await uploadFile(file);
                    setMessageType('document');
                }
                
                setSelectedFile(file);
            }
            else{
                setIsUploading(false);
                setValues({ ...values, open: true, msgType: 'error', alertMsg: 'Please select a file' })
            }
            
        } 
        catch (error) {
            setIsUploading(false);
            console.error('Error handling file attachment:', error);
            // Handle error (e.g., show toast message)
        }
    };

    const uploadFile = async (file) => {
        try {
            // Add other form data as needed
            const formData = new FormData();
            formData.append('file', file);
            formData.append('number', selectedChat.number);
            formData.append('mime_type', file.type);
            formData.append('country_code', selectedChat.country_code);
            // Perform the file upload
            const response = await axiosInstance.post('/api/v1/communications/whatsapp/upload-media/', formData)
            console.log(response)
            setMediaUrl(response.data);
            if(response.status === 201 || response.status === 200) {
                setValues({ ...values, open: true, msgType: 'success', alertMsg: 'File uploaded successfully' })
            }
            setIsUploading(false);
            // Handle successful upload (e.g., display success message)
        } catch (error) {
            setIsUploading(false);
            console.error('Error uploading file:', error);
            // Handle error (e.g., show toast message)
        }
    };

    const getFileExtension = (fileName) => {
        return fileName.includes('.') ? fileName.split('.').pop() : '';
    };
    
    const handleClickList = (chat) => {
        setChatList(chat);
        setOpenChatsDialog(true);
    };

    const handleCloseChatsDialog = () => {
        setOpenChatsDialog(false);
    };

    const handleReplyMessage = async (message) => {
        try{
            const res = await axiosInstance.get(`/api/v1/communications/whatsapp/view/message/${message.original_external_id}/`)
            console.log(res.data)
            setReplyMessage(res.data)
            setOpenChatReplyDialog(true)
        }
        catch (error) {
            console.log(error)
        }
    };

    const handleCloseReplyDialog = () => {
        setOpenChatReplyDialog(false)
    }

    const openTemplateModals = () => {
        setOpenTemplatesDialog(true)
    }

    const handleTemplateDialog = () => {
        setOpenTemplatesDialog(false)
    }

    const handleSlectedTemplateSearch = async (e) => {
        try {
            const number = `${selectedChat?.country_code.replace('-', '').replace('+','')}${selectedChat?.number}`
            const res = await axiosInstance.get(`/api/v1/communications/whatsapp/template/list/${number}/?name=${e.target.value}&combined_data_id=${selectedChat?.data?.combined_data_id}`);
            setTemplates(res.data.results);
        } 
        catch (error) {
            console.log(error)
        }
    }

    const sendTemplateMessage = async () => {
        try {
            console.log(selectedChat)
            const payload = {
                combined_data_id: combinedID,
                template_id: selectedTemplate.id,
                number: selectedChat?.number,
                country_code: selectedChat?.country_code,
            }
            const res = await axiosInstance.post('api/v1/communications/whatsapp/template/send/', payload);
            if(res.status === 201 || res.status === 200) {
                setValues({ ...values, open: true, msgType: 'success', alertMsg: 'Template message sent successfully' })
                setSelectedTemplate(null);
                setOpenTemplatesDialog(false);
            }
        } 
        catch (error) {
            console.log(error)
            setValues({ ...values, open: true, msgType: 'error', alertMsg: 'Something went wrong' })
        }
    };

    const openAttachment = Boolean(anchorEl);
    const openEmoji = Boolean(anchorEmojiEl);

    const fetchDocumentAPI = async (type) => {
        try{
            const number = `${selectedChat?.country_code.replace('-', '').replace('+','')}${selectedChat?.number}`
            const res = await axiosInstance.get(`api/v1/communications/whatsapp/chat/attachment/${number}/?type=${type}`)
            setAttachmentFiles(res.data.results)
            setAttachmentNextUrl(res.data.next)
        }
        catch(error){
            console.log(error)
        }
    }

    const fetchNextAttachmentAPI = async () => {
        try{
            if(attachmentNextUrl){
                const res = await axiosInstance.get(attachmentNextUrl.replace('http://', 'https://'))
                setAttachmentFiles([...attachmentFiles, ...res?.data?.results])
                setAttachmentNextUrl(res?.data?.next)
            }
        }catch(error){
            console.log(error)
        }
    }

    const handleClickSeeDoc = async () => {
        try{
            setOpenChatDocumentDialog(true);
            fetchDocumentAPI('media');
        }catch(error){
            console.log(error)
        }
    }

    const formattedMessage = (message) => {
        // Replace * with <strong> tags for bold
        let formatted = message.replace(/\*([^*]+)\*/g, '<strong>$1</strong>');
        // Replace _ with <em> tags for italic
        formatted = formatted.replace(/_([^_]+)_/g, '<em>$1</em>');
        return formatted.replace(/\n/g, '<br>'); // Convert newlines to <br> tags
    };

    const handleRefreshChats = async () => {
        setLoading(true)
        try {
            const number = `${selectedChat?.country_code.replace('-', '').replace('+','')}${selectedChat?.number}`
            const response = await axiosInstance.get(`/api/v1/communications/whatsapp/messages/${number}/`);
            if(response.status === 200 || response.status === 201){
                const data = response.data;
                setChatDetails(data.results);
                setNextURL(data.next);
            }
            setLoading(false)
        } 
        catch (error) {
            console.log(error)   
            setLoading(false) 
        }
    }
    
    return(
        <>
            <div className={styles.chat__details__header}>
                <div className="flex-grow-1 d-flex gap-3">
                    <p 
                        className={styles.chat__details__header__avatar}
                        style={{ backgroundColor: getRandomColor(username && username?.charAt(0)?.toUpperCase() ? username?.charAt(0)?.toUpperCase() : selectedChat?.number?.charAt(0) )}}
                    >
                        { username && username?.charAt(0)?.toUpperCase() ? username?.charAt(0)?.toUpperCase() : selectedChat?.number?.charAt(0) }
                    </p>
                    {
                        selectedChat && selectedChat?.data?.name !== 'N/A' && selectedChat?.number ? <div className={styles.chat__details__user__name}>
                            <div className="">{selectedChat?.data?.name}</div>
                            <small className="" style={{ 'fontWeight': '400'}}>{selectedChat?.number.length === 12 && selectedChat?.number.startsWith("91") ? selectedChat?.number.substring(2) : selectedChat?.number}</small>
                        </div> : <div className={styles.chat__details__user__name}>{username}</div>
                    }
                </div>
                <Button 
                    size="small"
                    variant="contained" 
                    startIcon={<AttachFileRoundedIcon />} 
                    onClick={handleClickSeeDoc}
                    >View Attachments
                </Button>
                <Tooltip title="Refresh Chats" arrow placement="top">
                    <IconButton
                        onClick={handleRefreshChats}
                        className="mr-2"
                    >
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <div className="position-relative w-100">
                <div className={styles.chat__details__chats} style={{ 'paddingBottom': '100px'}} ref={scrollContainerRef}>
                    <InfiniteScrollObserver onIntersect={fetchMoreData} />
                    {
                        chatDetails && chatDetails.length ? chatDetails.slice().reverse().map((chat, index) => {
                            return(
                                <div 
                                    key={index}
                                    id={'chats-' + chat?.id}
                                    className={
                                        chat?.conversation_way === 'outgoing'
                                            ? styles.sent_msg_container
                                            : styles.received_msg_container
                                    }
                                >    
                                    <div className={chat?.conversation_way === 'outgoing' ? styles.sent_message__container : styles.received_message__container}>
                                        <div
                                            key={index}
                                            id={'chats-' + chat?.id}
                                        >   
                                            <div className={
                                                chat?.conversation_way === 'outgoing'
                                                    ? styles.sent_msg
                                                    : styles.received_msg
                                            }>  
                                                <div className={styles.message_container}>
                                                    { chat?.reply_to_message && 
                                                        <div 
                                                            onClick={() => handleReplyMessage(chat?.reply_to_message)} 
                                                            className={styles.reply_to_msg}
                                                        >
                                                            {chat?.reply_to_message?.original_message?.msg}
                                                        </div>
                                                    }
                                                    {   chat?.media && !clickedImages[chat?.id] && chat?.media.media_type !== 'document' ? 
                                                        (
                                                            <button 
                                                                className={styles.chat__image} 
                                                                onClick={() => handleClickImage(chat?.id)}
                                                            >
                                                                Click to load {chat?.media?.media_type === 'video' ? 'video' : 'image'}
                                                            </button>
                                                        ) : 
                                                        chat?.media && chat?.media.media_type === 'image' ? 
                                                        (
                                                            <img src={chat?.media.media_url} className={styles.chat__image} alt="chat media" />
                                                        ) : 
                                                        chat?.media && chat?.media.media_type === 'video' ? (
                                                            <video src={chat?.media.media_url} className={styles.chat__image} controls />
                                                        ) : 
                                                        chat?.media && chat?.media.media_type === 'audio' ? (
                                                            <audio src={chat?.media.media_url} className={styles.chat__image} controls />
                                                        ) : 
                                                        chat?.media && chat?.media.media_type === 'document' ? (
                                                            <a href={chat?.media.media_url} target="_blank" download>Download Attachment</a>
                                                        ) : 
                                                        null
                                                    }
                                                    <div dangerouslySetInnerHTML={{ __html: formattedMessage(chat?.msg?.message) }} />
                                                    <div 
                                                        className={chat?.conversation_way === 'outgoing' ? 'pr-1 position-relative' : 'pl-1 position-relative'}
                                                    >
                                                        <div className="date_status d-flex justify-content-between align-items-center pt-1">
                                                            {
                                                                chat?.details && chat?.details.sent_by === 'Automated' ? 
                                                                (
                                                                    <SmartToyIcon style={{ fontSize: '16px' }} />
                                                                ) : 
                                                                (
                                                                    <small className={styles.from_counsellor}>
                                                                        {chat?.details && chat?.details.sent_by}
                                                                    </small>
                                                                )
                                                            }
                                                            <span 
                                                                className="d-flex align-items-center" 
                                                                style={{ marginLeft: chat?.details && chat?.details.sent_by ? '6px' : '' }}
                                                            >
                                                                <small className={styles.message_time}>{chat?.message_time}</small>
                                                                {
                                                                    chat?.details && chat?.details.status === 'pending' && 
                                                                    (
                                                                        <AccessTimeIcon 
                                                                            style={{ fontSize: '16px', marginLeft: '6px', marginRight: '6px' }} 
                                                                        />
                                                                    )
                                                                }
                                                                {
                                                                    chat?.details && chat?.details.status === 'sent' && 
                                                                    (
                                                                        <DoneAllIcon 
                                                                            style={{ fontSize: '16px', marginLeft: '6px', marginRight: '6px' }} 
                                                                        />
                                                                    )
                                                                }
                                                                {
                                                                    (chat?.details && chat?.details.status === 'delivered') || (chat?.details && chat?.details.status === 'success') && 
                                                                    (
                                                                        <DoneAllIcon 
                                                                            style={{ fontSize: '16px', marginLeft: '6px', marginRight: '6px' }} 
                                                                        />
                                                                    )
                                                                }
                                                                {   chat?.details && chat?.details.status === 'read' && 
                                                                    (
                                                                        <DoneAllIcon 
                                                                            style={{ fontSize: '16px', marginLeft: '6px', marginRight: '6px', color: '#4dabf7' }} 
                                                                        />
                                                                    )
                                                                }
                                                                {
                                                                    chat?.details && chat?.details.status === 'failed' && 
                                                                    (
                                                                        <ErrorIcon 
                                                                            style={{ fontSize: '16px', marginLeft: '6px', marginRight: '6px', color: '#dc3545' }} 
                                                                        />
                                                                    )
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={styles.arrow}
                                                    style={{
                                                        borderColor: chat?.conversation_way === 'outgoing' ? 'transparent transparent transparent #dcf8c6' : 'transparent #ffffff transparent transparent'
                                                    }}
                                                ></div>
                                                {
                                                    chat?.msg?.type === 'list' && 
                                                    (
                                                        <div 
                                                            className="" 
                                                            style={{ borderTop: '1px solid #ffff', marginTop: '6px', marginRight: '6px', textAlign: 'center'}}
                                                        >
                                                            <div 
                                                                className={styles.list_button} 
                                                                onClick={() => handleClickList(chat?.msg?.values)}
                                                            >
                                                                <FormatListBulletedIcon style={{ marginRight: '5px', color: '#4dabf7'}} />
                                                                {chat?.msg?.values?.name}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            { 
                                                chat?.msg?.values?.length > 0 && 
                                                (
                                                    <div 
                                                        style={{ marginTop: '6px', marginRight: '6px' }} 
                                                        className={styles.chat__details__chats__sent}
                                                    >
                                                        {
                                                            chat?.msg?.type === 'buttons' && 
                                                            ( 
                                                                <>
                                                                {
                                                                    chat?.msg.values.map((value, index) => {
                                                                        return(
                                                                            <div key={index} className="mb-4">
                                                                                <button className={styles.reply__button}>{value}</button>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        )}
                                                        {
                                                            chat?.msg?.type === 'quick_reply' && 
                                                            (
                                                                <>
                                                                    { 
                                                                        chat?.msg.values.map((value, index) => {
                                                                            return(
                                                                                <div key={index} className="mb-4">
                                                                                    <button className={styles.reply__button}>{value}</button>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : 'No messages'
                    }
                    <div className="" id="last_element"></div>
                </div>
                <div style={{ position: 'absolute', bottom: '0', width: '100%'}}>
                    {
                        (isUploading || mediaUrl) && 
                        <div className={styles.chat__details__footer}>
                            <div className={styles.chat__details__close} onClick={() => setMediaUrl(null)}>X</div>
                            {!isUploading && mediaUrl ? 
                                <div className={styles.chat__details__image}>
                                    {
                                        selectedFile && selectedFile?.type.startsWith('image') && <img className={styles.media_size} src={mediaUrl.url} alt="image" />
                                    }
                                    {
                                        selectedFile && selectedFile?.type.startsWith('video') && <video className={styles.media_size} src={mediaUrl.url} controls />
                                    }
                                    {
                                        selectedFile && selectedFile?.type.startsWith('audio') && <audio className={styles.media_size} src={mediaUrl.url} controls />
                                    }
                                    <p>{selectedFile?.name}</p>
                                </div> : 
                                <>Loading...</>
                            }
                        </div>
                    }
                    <div className={styles.chat__details__footer}>
                        <TextField
                            fullWidth
                            id="message"
                            multiline
                            maxRows={2}
                            minRows={2}
                            value={message}
                            placeholder={isOptIn.can_send_two_way ? "Type a message..." : "It's been more than 24 hours since the last customer message. According to Whatsapp guidelines, you can only reach out to customers from the source after the 24 hours customer care window. You should be able to reply from here once you get reply from the customer."}
                            onBlur={() => setMessageBlur(true)}
                            error={messageBlur && message === ''}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            disabled={!isOptIn.can_send_two_way}
                        />
                        <button 
                            className={styles.chat__details__footer__send}
                            onClick={sendMessage}
                        >
                            <SendRoundedIcon />
                        </button>
                        <button 
                            onClick={handlePopoverOpen}
                            className={styles.chat__details__footer__send}
                            disabled={!isOptIn.can_send_two_way}
                        >
                            <AttachFileRoundedIcon />
                        </button>
                        <Popover
                            id="attachment-popover"
                            open={openAttachment}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <List>
                                <ListItemButton onClick={() => document.getElementById('audioInput').click()}>
                                    <ListItemIcon>
                                        <AudiotrackIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Audio" />
                                    <input
                                        type="file"
                                        id="audioInput"
                                        accept="audio/*"
                                        style={{ display: 'none' }}
                                        onChange={handleAttachmentChange}
                                    />
                                </ListItemButton>
                                <ListItemButton onClick={() => document.getElementById('videoInput').click()}>
                                    <ListItemIcon>
                                        <PlayCircleIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Video" />
                                    <input
                                        type="file"
                                        id="videoInput"
                                        accept="video/*"
                                        style={{ display: 'none' }}
                                        onChange={handleAttachmentChange}
                                    />
                                </ListItemButton>
                                <ListItemButton onClick={() => document.getElementById('imageInput').click()}>
                                    <ListItemIcon>
                                        <ImageIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Image" />
                                    <input
                                        type="file"
                                        id="imageInput"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={handleAttachmentChange}
                                    />
                                </ListItemButton>
                                <ListItemButton onClick={() => document.getElementById('documentInput').click()}>
                                    <ListItemIcon>
                                        <ArticleIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Document" />
                                    <input
                                        type="file"
                                        id="documentInput"
                                        accept=".doc, .docx, .pdf"
                                        style={{ display: 'none' }}
                                        onChange={handleAttachmentChange}
                                    />
                                </ListItemButton>
                            </List>
                        </Popover>
                        <button 
                            className={styles.chat__details__footer__send}
                            onClick={handleEmojiPopoverOpen}
                            disabled={!isOptIn.can_send_two_way}
                        >
                            <EmojiEmotionsOutlinedIcon />
                        </button>
                        <Popover
                            id="attachment-popover"
                            open={openEmoji}
                            anchorEl={anchorEmojiEl}
                            onClose={handleEmojiPopoverClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <Picker data={data} onEmojiSelect={onEmojiSelect} />
                        </Popover>
                        <button 
                            className={styles.chat__details__footer__send}
                            onClick={openTemplateModals}
                        >
                            <FormatListBulletedIcon />
                        </button>
                    </div>
                </div>
                <Backdrop
                    sx={{ 
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'transparent',
                        backdropFilter:'blur(5px)',
                        zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                    <CircularProgress color="primary" />
                </Backdrop>
            </div>

            {/* chat list dialog box */}
            <Dialog
                open={openChatsDialog}
                onClose={handleCloseChatsDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {chatList?.name}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ minWidth: '400px' }}>
                        {
                            chatList?.sections.map((section, index) => {
                                return(
                                    <div key={index + '-sections'}>
                                        <p style={{ fontWeight: 'bold', color: '#212529', borderBottom: '1px dotted #212529' }}>{section.title}</p>
                                        {
                                            section.buttons.map((value, index) => {
                                                return(
                                                    <div key={index+ '-buttons'}>
                                                        <p>{value.title}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseChatsDialog} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            
            {/* chat reply dialog box */}
            <Dialog
                open={openChatReplyDialog}
                onClose={handleCloseReplyDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Previous Message
                </DialogTitle>
                <DialogContent style={{ backgroundColor: '#ece5dd', padding: '12px' }}>
                    <DialogContentText id="alert-dialog-description" style={{ minWidth: '400px' }}>
                        {replyMessage && (
                            <div 
                                key={replyMessage?.id}
                                id={'chats-' + replyMessage?.id}
                                className={
                                    replyMessage?.conversation_way === 'outgoing'
                                        ? styles.sent_msg_container
                                        : styles.received_msg_container
                                }
                            >    
                                <div 
                                    className={replyMessage?.conversation_way === 'outgoing' ? styles.sent_message__container : styles.received_message__container}
                                >
                                    <div
                                        key={replyMessage.id}
                                        id={'chats-' + replyMessage.id}
                                    >   
                                        <div className={
                                            replyMessage.conversation_way === 'outgoing'
                                                ? styles.sent_msg
                                                : styles.received_msg
                                        }>  
                                            <div className={styles.message_container}>
                                                { replyMessage?.reply_to_message && <div onClick={() => handleReplyMessage(replyMessage?.reply_to_message)} className={styles.reply_to_msg}>
                                                    {replyMessage?.reply_to_message?.original_message?.msg}
                                                </div>}
                                                {replyMessage.media && !clickedImages[replyMessage.id] ? (
                                                    <button className={styles.chat__image} onClick={() => handleClickImage(replyMessage.id)}>Click to load image</button>
                                                ) : replyMessage.media && replyMessage.media.media_type === 'image' ? (
                                                    <img src={replyMessage.media.media_url} className={styles.chat__image} alt="chat media" />
                                                ) : replyMessage.media && replyMessage.media.media_type === 'video' ? (
                                                    <video src={replyMessage.media.media_url} className={styles.chat__image} controls />
                                                ) : 
                                                replyMessage.media && replyMessage.media.media_type === 'audio' ? (
                                                    <audio src={replyMessage.media.media_url} className={styles.chat__image} controls />
                                                ) : replyMessage.media && replyMessage.media.media_type === 'document' ? (
                                                    <a href={replyMessage.media.media_url} download>Download Attachment</a>
                                                ) : null}
                                                <div dangerouslySetInnerHTML={{ __html: formattedMessage(replyMessage?.msg?.message) }} />
                                                <div className={replyMessage.conversation_way === 'outgoing' ? 'pr-1 position-relative' : 'pl-1 position-relative'}>
                                                    <div className="date_status d-flex justify-content-between align-items-center pt-1">
                                                        {replyMessage.details && replyMessage.details.sent_by === 'Automated' ? (
                                                            <SmartToyIcon style={{ fontSize: '16px' }} />
                                                        ) : (
                                                            <span className={styles.from_counsellor}>{replyMessage.details && replyMessage.details.sent_by}</span>
                                                        )}
                                                        <span className="d-flex align-items-center" style={{ marginLeft: replyMessage.details && replyMessage.details.sent_by ? '6px' : '' }}>
                                                            <span className={styles.message_time}>{replyMessage.message_time}</span>
                                                            {replyMessage.details && replyMessage.details.status === 'pending' && (
                                                                <AccessTimeIcon style={{ fontSize: '16px', marginLeft: '6px', marginRight: '6px' }} />
                                                            )}
                                                            {replyMessage.details && replyMessage.details.status === 'sent' && (
                                                                <DoneAllIcon style={{ fontSize: '16px', marginLeft: '6px', marginRight: '6px' }} />
                                                            )}
                                                            {(replyMessage.details && replyMessage.details.status === 'delivered') || (replyMessage.details && replyMessage.details.status === 'success') && (
                                                                <DoneAllIcon style={{ fontSize: '16px', marginLeft: '6px', marginRight: '6px' }} />
                                                            )}
                                                            {replyMessage.details && replyMessage.details.status === 'read' && (
                                                                <DoneAllIcon style={{ fontSize: '16px', marginLeft: '6px', marginRight: '6px', color: '#4dabf7' }} />
                                                            )}
                                                            {replyMessage.details && replyMessage.details.status === 'failed' && (
                                                                <ErrorIcon style={{ fontSize: '16px', marginLeft: '6px', marginRight: '6px', color: '#dc3545' }} />
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div
                                                className={styles.arrow}
                                                style={{
                                                    borderColor: replyMessage.conversation_way === 'outgoing' ? 'transparent transparent transparent #dcf8c6' : 'transparent #ffffff transparent transparent'
                                                }}
                                            ></div>
                                            {
                                                replyMessage.msg?.type === 'list' && (
                                                    <div className="" style={{ borderTop: '1px solid #ffff', marginTop: '6px', marginRight: '6px', textAlign: 'center'}}>
                                                        <div className={styles.list_button} onClick={() => handleClickList(replyMessage?.msg?.values)}>
                                                            <FormatListBulletedIcon style={{ marginRight: '5px', color: '#4dabf7'}} />
                                                            {replyMessage?.msg?.values?.name}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        { replyMessage?.msg?.values?.length > 0 && (
                                        <div style={{ marginTop: '6px', marginRight: '6px' }} className={styles.chat__details__chats__sent}>
                                            {replyMessage.msg?.type === 'buttons' && ( 
                                                <>
                                                    {replyMessage.msg.values.map((value, index) => {
                                                        return(
                                                            <div key={index} className="mb-4">
                                                                <button className={styles.reply__button}>{value}</button>
                                                            </div>
                                                        )
                                                    })}
                                                </>
                                            )}
                                            {replyMessage.msg?.type === 'quick_reply' && (
                                                <>
                                                    {replyMessage.msg.values.map((value, index) => {
                                                        return(
                                                            <div key={index} className="mb-4">
                                                                <button className={styles.reply__button}>{value}</button>
                                                            </div>
                                                        )
                                                    })}
                                                </>
                                            )}
                                        </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseReplyDialog} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            
            {/* template dialog box */}

            <Dialog
                open={openTemplatesDialog}
                onClose={handleTemplateDialog}
                fullWidth={true}
                maxWidth='sm'
            >
                <div className={styles.template__container}>
                    <h5>Templates</h5>

                    <div className="mt-4 mb-4">
                        <FormControl fullWidth>
                            <Autocomplete
                                id="combo-box-demo"
                                options={templates}
                                getOptionLabel={(option) => option.name}
                                size='small'
                                value={selectedTemplate || null}
                                onChange={(event, newValue) =>  setSelectedTemplate(newValue)}
                                renderInput={(params) => <TextField  onChange={(e) => handleSlectedTemplateSearch(e)}{...params} label="Template List"/>}
                            />
                        </FormControl>
                        <p className={styles.template__preview}>{selectedTemplate && selectedTemplate?.message?.text}</p>
                    </div>  
                    <div className={styles.template__action__btns}>
                        <button className={styles.template__close__btn} onClick={handleTemplateDialog}>Close</button>
                        <button className={styles.template__send__btn} onClick={sendTemplateMessage}>Send</button>
                    </div>
                </div>
            </Dialog>

            <ChatDocumentModal 
                selectedNumber={selectedChat?.number} 
                attachmentFiles={attachmentFiles} 
                fetchDocumentAPI={fetchDocumentAPI} 
                fetchNextAPI={fetchNextAttachmentAPI}  
            />
            
            <audio 
                id="notification-sound" 
                src="https://dwrqao8j794zb.cloudfront.net/for-static-use/4ac131d8-0779-11ef-8bd2-0da35c508ab3-cace1367-d7fb-4009-8bba-1141394b33b7-notification_sound.wav" 
                preload="auto" 
                className="d-none"  
            ></audio>
        </>
    )
}

export default ChatDetails;

const getRandomColor = (firstLetter) => {
    const colorMap = {
        A: "#FF5733",
        B: "#52a836",
        C: "#FF3366",
        D: "#31e0b5",
        E: "#CC33FF",
        F: "#FFCC33",
        G: "#33e4ff",
        H: "#3366FF",
        I: "#FF33CC",
        J: "#d42fc0",
        K: "#afe312",
        L: "#33CCFF",
        M: "#FF6633",
        N: "#33FF33",
        O: "#FF6633",
        P: "#0fccd6",
        Q: "#4091c7",
        R: "#a277e6",
        S: "#ffb833",
        T: "#33CC66",
        U: "#CC6633",
        V: "#3366CC",
        W: "#66CC33",
        X: "#CC3366",
        Y: "#4c9ec7",
        Z: "#ff6699",
        0: "#FF5733",
        1: "#52a836",
        2: "#FF3366",
        3: "#31e0b5",
        4: "#CC33FF",
        5: "#FFCC33",
        6: "#33e4ff",
        7: "#3366FF",
        8: "#FF33CC",
        9: "#d42fc0"
    }
    return colorMap[firstLetter?.toUpperCase()];
}