import React, { useEffect, Suspense, useState } from "react";
import Loading from "../../components/basic-components/Loading";
import { useRecoilState, useRecoilValue } from "recoil";
import { getPaginationState } from "../../store/journey-list";
import { globalVarState, localVarState } from "../../store/variables";
import styles from '../../assets/scss/components/variables.module.scss';
import GlobalVariable from '../../components/variables/global-variable';
import LocalVariable from '../../components/variables/local-variable'
import { Search, X } from 'react-bootstrap-icons';
import { debounce } from 'lodash';
import axiosInstance from "../../plugins/axios";
import { useNavigate } from "react-router-dom";
import BasicPagination from "../../components/basic-components/basic-pagination";
import Cookies from 'js-cookie';

const VariablesPage = () => {
    const [globalVar, setGlobalVar] = useRecoilState(globalVarState);
    const [localVar, setLocalVar] = useRecoilState(localVarState);
    const [pagination, setPagination] = useRecoilState(getPaginationState);
    const [activeTab, setActiveTab] = useState('Local');
    const [search, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const query = new URLSearchParams(location.search);
    const page = parseInt(query.get('page') || '1', 10);

    let handleActiveTab = async (tab) => {
        tab === 'Local' ? setActiveTab('Local') : setActiveTab('Global');
        setSearchText('')
        navigate(`/variables?${tab === 'Local' ? 'local=true' : 'global=true'}`);
        // await fetchData();
    }

    useEffect(async () => {
        try {
            const token = Cookies.get('Token');
            if(!token){
                localStorage.removeItem('Token');
                window.location.href = '/login'
            }
            else{
                const {data} = await axiosInstance.get(`/api/v1/communications/whatsapp/variables/?is_global=${activeTab === 'Local' ? 'false' : 'true'}&limit=20&offset=${(page - 1) * 20}`);
                setPagination({
                    ...pagination,
                    count: data.count,
                    onPageChange: (event, value) => onPageChange(event, value),
                    defaultPage: page,
                    path: `/variables${activeTab === 'Local' ? '?local=true' : '?global=true'}&`,
                    page: page
                })
                activeTab === 'Local' ? setLocalVar(data.results) : setGlobalVar(data.results);      
            }
        } 
        catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [activeTab]);

    const fetchData = async () => {
        try {
            const {data} = await axiosInstance.get(`/api/v1/communications/whatsapp/variables/?is_global=${activeTab === 'Local' ? 'false' : 'true'}&limit=20&offset=${(page - 1) * 20}`);
            setPagination({
                ...pagination,
                count: data.count,
                onPageChange: (event, value) => onPageChange(event, value),
                defaultPage: page,
                path: `/variables${activeTab === 'Local' ? '?local=true' : '?global=true'}&`,
                page: page
            })
            activeTab === 'Local' ? setLocalVar(data.results) : setGlobalVar(data.results);
        } 
        catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const onPageChange = async (event, value) => {
        try{
            setSearchText('')
            const res = await axiosInstance.get(`/api/v1/communications/whatsapp/variables/?is_global=${activeTab === 'Local' ? 'false' : 'true'}&limit=20&offset=${(value-1) * 20}`);
            setPagination({
                ...pagination,
                page: value,
                count: res.data.count,
                path: `/variables${activeTab === 'Local' ? '?local=true' : '?global=true'}&`,
                onPageChange: (event, value) => onPageChange(event, value)
            })
            activeTab === 'Local' ? setLocalVar(res.data.results) : setGlobalVar(res.data.results);
        }catch(error){
            console.log(error)
        }
    }

    const handleSearchDebounce = debounce( async(e) => {
        try {
            if(activeTab === 'Local') {
                const { data } = await axiosInstance.get(`/api/v1/communications/whatsapp/variables/?is_global=false&name=${e.target.value}`)
                setLocalVar(data.results);
                setLoading(false);
            }else{
                const { data } = await axiosInstance.get(`/api/v1/communications/whatsapp/variables/?is_global=true&name=${e.target.value}`)
                setGlobalVar(data.results);
                setLoading(false);
            }
        } 
        catch (error) {
            setLoading(false);
            console.log(error)
        }
    }, 300)

    const handleResetSearch = async () => {
        try {
            setSearchText('')
            setLoading(true);
            await fetchData();
            setLoading(false);
        } 
        catch (error) {
            setLoading(false);
            console.log(error)
        }
    };

    const handleSearch = (e) => {
        try {
            setLoading(true);
            setSearchText(e.target.value);
            if(e.target.value === '') {
                handleResetSearch()
            }else{
                handleSearchDebounce(e)
            }
        } 
        catch (error) {
            console.log(error)
        }
    };

    return (
        <div className={styles.variable__container}>
            <h4>Variables</h4>
            <p>Variables are used to store data from the journey to the backend, corresponding to their respective fields.</p>
            <div className={styles.tabs}>
                <button 
                    className={activeTab === 'Local' ? styles.active : ''} 
                    onClick={() => handleActiveTab('Local')}
                >
                    Local Variables
                </button>
                <div className="flex-grow-1">
                    <button 
                        className={activeTab === 'Global' ? styles.active : ''}
                        onClick={() => handleActiveTab('Global')}
                    >
                        Global Variables
                    </button>
                </div>
                <div className="d-flex">
                    <div className="flex-grow-1 flex-end">
                        <div className={styles.variable__search}>
                            <div>
                                <input 
                                    type="text" 
                                    placeholder="Search Variables" 
                                    onChange={handleSearch}
                                    value={search}
                                />
                            </div>
                            <div className="cursor-pointer">
                                {
                                    search.length > 0 &&
                                    <X size={30} onClick={handleResetSearch} />
                                }
                            </div>
                            {search.length === 0 && <div>
                                <Search color="#5956d6" />
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            { activeTab === 'Local' ? 
                <div className="mt-5">
                    <div>
                        {!loading ? <LocalVariable local={localVar} /> : <>Loading...</>}
                    </div>
                </div>
                :
                <div className="mt-5">
                    <div>
                        {!loading ? <GlobalVariable global={globalVar} /> : <>Loading...</>}
                    </div>
                </div>
            }
            <BasicPagination />
        </div>
    );
}

const SuspenseVariablesPage = () => {
    return (
        <Suspense 
            fallback={ <Loading /> }
        >
            <VariablesPage />
        </Suspense>
    );
}

export default SuspenseVariablesPage;

