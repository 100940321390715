import axios from 'axios';

let apiUrl;
if(localStorage.getItem('project_type') === 'Prod'){
    apiUrl = process.env.REACT_APP_BASE_URL_PROD;
}
else{
    apiUrl = process.env.REACT_APP_BASE_URL_DEV;
}

const token = localStorage.getItem('Token') ? localStorage.getItem('Token') : '';
const axiosInstance = axios.create({
    baseURL: apiUrl,
    headers: {
        // 'Content-Type': 'application/json',
        ...(token ? { 'Authorization': `Token ${token}` } : {})
        
    },
});    

export default axiosInstance;