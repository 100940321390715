import React, { useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from '../../assets/css/list.module.css';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { ListTask, GripVertical, Trash3 } from 'react-bootstrap-icons';
import { useRecoilState } from 'recoil';
import { editBarOpenState, editTypesState, isDraggableState, nodesState, edgesState } from '../../store/state';
import { handleDeleteDebounced } from '../../mixins/journey'
import { debounce } from 'lodash';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const Row = ({ row, index, draggable, isSwitchChecked }) => {
    return (
        <Draggable draggableId={row.id} index={index} isDragDisabled={!draggable}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={[styles.section_content, 'btn_shadow'].join(' ')}
                    style={{
                        ...provided.draggableProps.style,
                        position: 'relative',
                        overflow: 'visible',
                    }}
                >
                    <div>
                        {row.title ? row.title : `Button ${index + 1}`}
                        {isSwitchChecked && (
                            <Handle
                                id={row.id}
                                type='source'
                                position={Position.Right}
                                style={{
                                    backgroundColor: '#5956d6',
                                    border: '1px solid #5956d6',
                                    padding: '5px',
                                    position: 'absolute',
                                    right: '-23px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    boxShadow: '0 0 0 0.25rem #c5cad9',
                                    zIndex: 1,
                                }}
                            />
                        )}
                    </div>
                    <span {...provided.dragHandleProps}>
                        <GripVertical color='#c5cad9' size={22} />
                    </span>
                </div>
            )}
        </Draggable>
    );
};







const RowList = ({ rows, draggable, isSwitchChecked }) => {
    return rows.map((row, index) => (
        <Row row={row} index={index} key={row.id} draggable={draggable} isSwitchChecked={isSwitchChecked} />
    ));
};

// const label = { inputProps: { 'aria-label': 'Color switch demo' } };

function List({ data, isConnectable, nodeId }) {
    const [showDeleteButton, setShowDeleteButton] = useState(false);
    const [draggable, setDraggable] = useState(true);
    const [isDraggableList, SetIsDraggableList] = useRecoilState(isDraggableState);
    const [nodes, setNodes] = useRecoilState(nodesState);
    const [edges, setEdges] = useRecoilState(edgesState);
    const [editBarOpen, setEditBarOpen] = useRecoilState(editBarOpenState);
    const [editTypes, setEditTypes] = useRecoilState(editTypesState);
    const [isSwitchChecked, setIsSwitchChecked] = useState(false);

   

    const onDragStart = () => {
        SetIsDraggableList(false);
    };
    const sectionTemplate = nodes.find((node) => node.id === nodeId)?.message_to_send?.template
    const listButtonSectionList = sectionTemplate?.value?.sections || [];
    const nodeText = sectionTemplate?.message || 'What’s on your mind?';
    const buttonText = sectionTemplate?.value?.button || 'Button Title';
    const findIndex = nodes.findIndex((node) => node.id === nodeId);
    
    const openSideBar = () => {
        setEditBarOpen(!editBarOpen);
        setEditTypes({selected_id: nodeId, selected_type: 'list'});
    };

    useEffect(() => {
        const targetNode = nodes.find((node) => node.id === nodeId);
        if (!targetNode) {
            console.error("Node not found");
            return;
        }
    
        const nextNodeId = targetNode.is_node_switched;
        if (nextNodeId) {
            setIsSwitchChecked(true);
        } else {
            setIsSwitchChecked(false);
        }
    }, [setIsSwitchChecked])
    
    const onDragEnd = debounce ((result, sectionIndex) => {
        if (!nodes || nodes.length === 0) {
            console.error('Nodes array is empty or undefined.');
            return;
        }
    
        if (!result.destination) {
            return;
        }
    
        if (result.destination.index === result.source.index) {
            return;
        }
    
        if (findIndex === -1) {
            console.error('Node not found.');
            return;
        }
    
        const updatedNodes = nodes.map((node, index) => {
            if (index === findIndex && node.message_to_send && node.message_to_send.template) {
                const { template } = node.message_to_send;
                const { value } = template;
                const sections = [...value.sections];
    
                // Ensure sectionIndex is valid
                if (sectionIndex >= 0 && sectionIndex < sections.length) {
                    const section = sections[sectionIndex];
                    const { rows } = section;
                    const reorderedRows = reorder(rows, result.source.index, result.destination.index);
    
                    // Update the section with reordered rows
                    const updatedSection = {
                        ...section,
                        rows: reorderedRows,
                    };
    
                    // Update the sections array with the updated section
                    const updatedSections = [...sections.slice(0, sectionIndex), updatedSection, ...sections.slice(sectionIndex + 1)];
    
                    // Update the value object with the updated sections array
                    const updatedValue = {
                        ...value,
                        sections: updatedSections,
                    };
    
                    // Update the template object with the updated value object
                    const updatedTemplate = {
                        ...template,
                        value: updatedValue,
                    };
    
                    // Update the node with the updated template object
                    return {
                        ...node,
                        message_to_send: {
                            ...node.message_to_send,
                            template: updatedTemplate,
                        },
                    };
                }
            }
            return node;
        });
    
        setNodes(updatedNodes);
        SetIsDraggableList(true);
    },300);
    
    
    

    const handleMouseEnter = () => {
        setShowDeleteButton(true);
    };    
    
    const handleMouseLeave = () => {
        setShowDeleteButton(false);
    };

    const handleDelete = (event) => {
        event.stopPropagation();
        handleDeleteDebounced(nodes, setNodes, edges, setEdges, nodeId)();
    };  
    

    const handleSwitch = (e) => {
        const is_checked = e.target.checked
        let updatedNodes = [...nodes];
        const targetNode = updatedNodes.find((node) => node.id === nodeId);
        if (!targetNode) {
            console.error("Node not found");
            return;
        }
    
        updatedNodes = updatedNodes.map((node) => {
            if (node.message_to_send.prev_node_id === nodeId) {
                // Update the message_to_send object only if prev_node_id matches nodeId
                return {
                    ...node,
                    message_to_send: {
                        ...node.message_to_send,
                        prev_node_id: null,
                    },
                };
            }
            // Return the node as it is if prev_node_id does not match nodeId
            return node;
        });
        if (is_checked) {
            // For example, if you want to clear next_node_id property
            updatedNodes = updatedNodes.map((node) => {
                if (node.id === nodeId) {
                    return {
                        ...node,
                        is_node_switched: true,
                        message_to_send: {
                            ...node.message_to_send,
                            next_node_id: null,
                            fallback_node_id: null,
                        },
                    };
                }
                return node;
            });
        } else {
            updatedNodes = updatedNodes.map((node) => {
                if (node.id === nodeId) {
                    return {
                        ...node,
                        is_node_switched: false,
                        message_to_send: {
                            ...node.message_to_send,
                            next_node_id: null,
                            fallback_node_id: null,
                        },
                    };
                }
                return node;
            });
        }

        const updatedEdges = edges.filter((edge) => edge.source !== nodeId);
        setIsSwitchChecked(is_checked)
        setEdges(updatedEdges);
        setNodes(updatedNodes); // Update the state with the modified nodes
    }
    
    
    

    return (
        <div className={ styles['list-node'] } 
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave}
        >
            <Handle
                type='target'
                position={Position.Left}
                isConnectable={isConnectable}
                id='a'
            />
            {!isSwitchChecked && (
                <Handle
                    id='success'
                    type='source'
                    position={Position.Right}
                    style={{
                        backgroundColor: '#5956d6',
                        border: '1px solid #5956d6',
                        padding: '5px',
                        position: 'absolute',
                        right: '-8px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        boxShadow: '0 0 0 0.25rem #c5cad9',
                        zIndex: '1',
                        display: isSwitchChecked ? 'none' : 'block' // Conditionally set display property
                    }}
                />
            )}
            <Handle
                id='fallback'
                type='source'
                position={Position.Right}
                style={{
                    backgroundColor: '#ff3964',
                    border: '1px solid #ff3964',
                    padding: '5px',
                    position: 'absolute',
                    right: '-6px',
                    top: '95%',
                    transform: 'translateY(-50%)',
                    boxShadow: '0 0 0 0.25rem #c5cad9',
                }}
            />
            
            <div className=''>
                <div className='heading__div' onClick={() => SetIsDraggableList(true)}>
                    <div className='heading-text'>
                        <span>List</span>
                        <Tooltip title="Button based next message" className="cursor-pointer ">
                            <Switch size='small' onChange={ (e)=>handleSwitch(e) } value={isSwitchChecked} checked={isSwitchChecked} />
                        </Tooltip>
                    </div>
                </div>
                <div className={nodeText === '' ? 'content__div__edit' : 'content__div'} onClick={openSideBar} >
                    <div>{nodeText}</div>
                    <div className='btn_shadow' style={ { marginTop: '10px' } }>
                        <ListTask color='#5956d6' size={ 22 } />
                        <span className='px-2'>{buttonText}</span>
                    </div>
                    <div className={styles.section_box}>
                        {listButtonSectionList.length > 0 ? (
                            listButtonSectionList.map((section, index) => (
                                <div key={index}>
                                    <div className={styles.section_name}>{section.title ? section.title : `Section ${index + 1}`}</div>
                                    <DragDropContext onDragEnd={(result) => onDragEnd(result, index)} onBeforeDragStart={onDragStart}>
                                        <Droppable droppableId={`${index}`}>
                                            {provided => (
                                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                                    <RowList rows={section.rows} draggable={draggable} isSwitchChecked={isSwitchChecked} />
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            ))
                        ) : (
                            <div>No sections available</div>
                        )}
                    </div>

                </div>
            </div>
            {showDeleteButton && (
            <div
                className="deleteButton"
                onClick={handleDelete}
                onMouseEnter={() => setShowDeleteButton(true)}
                onMouseLeave={() => setShowDeleteButton(false)}
            >
                <Trash3 color='#8c8ace' size={20} className='' />
            </div>
            )}
            
        </div>
    );
}

export default List;
