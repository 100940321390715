import React, { useState,useEffect,  memo } from 'react';
import { Handle, Position } from 'reactflow';
import styles from '../../assets/css/reply_button.module.css'
import Button from '@mui/material/Button';
import { Plus, Trash3, GripVertical } from 'react-bootstrap-icons'
import { makeStyles } from '@mui/styles';
import { useRecoilState } from 'recoil';
import { nodesState, edgesState, editBarOpenState, editTypesState, isDraggableState } from '../../store/state';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { handleDeleteDebounced } from '../../mixins/journey'

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

function Section({ section, index, draggable, isSwitchChecked }) {
    return (
        <Draggable draggableId={ index.toString() } index={ index } isDragDisabled={!draggable}>
            { (provided) => (
                <div
                    ref={ provided.innerRef }
                    { ...provided.draggableProps }
                    className='btn_shadow d-flex justify-content-between'
                    style={{
                        ...provided.draggableProps.style,
                        position: 'relative',
                        overflow: 'visible',
                    }}
                >
                    <span>
                        { section.title ? section.title : 'Button Title' }
                        {isSwitchChecked && (
                            <Handle
                                id={section.id}
                                type='source'
                                position={Position.Right}
                                style={{
                                    backgroundColor: '#5956d6',
                                    border: '1px solid #5956d6',
                                    padding: '5px',
                                    position: 'absolute',
                                    right: '-23px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    boxShadow: '0 0 0 0.25rem #c5cad9',
                                    zIndex: 1,
                                }}
                            />
                        )}
                    </span>
                    <span {...provided.dragHandleProps}>
                        <GripVertical color='#c5cad9' size={ 22 } />
                    </span>
                </div>
            ) }
        </Draggable>
    );
}

const SectionList = memo(({ sections, draggable, isSwitchChecked }) => {
    return sections.map((section, index) => (
        <Section key={index} section={section.reply} index={index} draggable={draggable} isSwitchChecked={isSwitchChecked} />
    ));
});

const useStyles = makeStyles((theme) => ({
    button: {
      width: '100%', // Makes the button take the full width
      border: '1px solid #5956d680',
      color: '#5956d6',
      '&:hover': {
        border: '1px solid #5956d6',
        backgroundColor: '#fff', // Changes background color on hover
        color: '#5956d6', // Changes text color on hover
      },
    },
}));

function ReplyButton({ data, isConnectable, nodeId }) {
    const [showDeleteButton, setShowDeleteButton] = useState(false);
    const [nodes, setNodes] = useRecoilState(nodesState);
    const [edges, setEdges] = useRecoilState(edgesState);
    const [editBarOpen, setEditBarOpen] = useRecoilState(editBarOpenState);
    const [editTypes, setEditTypes] = useRecoilState(editTypesState)
    const classes = useStyles();
    const [isSwitchChecked, setIsSwitchChecked] = useState(false);

    useEffect(() => {
        const targetNode = nodes.find((node) => node.id === nodeId);
        if (!targetNode) {
            console.error("Node not found");
            return;
        }
    
        const nextNodeId = targetNode.is_node_switched;
        if (nextNodeId) {
            setIsSwitchChecked(true);
        } else {
            setIsSwitchChecked(false);
        }
    }, [setIsSwitchChecked])

    //draggable
    const [draggable, setDraggable] = useState(true);
    const [isDraggableList, SetIsDraggableList] = useRecoilState(isDraggableState);

    const findIndex = nodes.findIndex(item => item.id === nodeId);
    const replyButton = nodes[findIndex].message_to_send.template.value.buttons;

    const onDragStart = () => {
        SetIsDraggableList(false);
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
    
        if (result.destination.index === result.source.index) {
            return;
        }

        if(findIndex === -1) {
            console.error('Node not found');
            return;
        }
        const updatedNodes = [...nodes];
        const section = updatedNodes[findIndex]
        const reorderedValue = reorder(
            section.message_to_send.template.value.buttons,
            result.source.index,
            result.destination.index
        );
        updatedNodes[findIndex] = {
            ...section,
            message_to_send: {
                ...section.message_to_send,
                template: {
                    ...section.message_to_send.template,
                    value: {
                        ...section.message_to_send.template.value,
                        buttons: reorderedValue
                    }
                }
            }
        }
        setNodes(updatedNodes);
        SetIsDraggableList(true);
    }
    

    const openSideBar = () => {
        setEditBarOpen(!editBarOpen);
        setEditTypes({selected_id: nodeId, selected_type: 'reply-button'});
    };

    const handleMouseEnter = () => {
        setShowDeleteButton(true);
    };
    
    const handleMouseLeave = () => {
        setShowDeleteButton(false);
    };

    const handleDelete = (event) => {
        event.stopPropagation();
        handleDeleteDebounced(nodes, setNodes, edges, setEdges, nodeId)();
    }; 

    const handleAddButton = (event) => {
        event.stopPropagation();
        handleAddButtonDebounced();
    }
    const handleSwitch = (e) => {
        const is_checked = e.target.checked
        let updatedNodes = [...nodes];
        const targetNode = updatedNodes.find((node) => node.id === nodeId);
        if (!targetNode) {
            console.error("Node not found");
            return;
        }
    
        updatedNodes = updatedNodes.map((node) => {
            if (node.message_to_send.prev_node_id === nodeId) {
                // Update the message_to_send object only if prev_node_id matches nodeId
                return {
                    ...node,
                    message_to_send: {
                        ...node.message_to_send,
                        prev_node_id: null,
                    },
                };
            }
            // Return the node as it is if prev_node_id does not match nodeId
            return node;
        });
        if (is_checked) {
            // For example, if you want to clear next_node_id property
            updatedNodes = updatedNodes.map((node) => {
                if (node.id === nodeId) {
                    return {
                        ...node,
                        is_node_switched: true,
                        message_to_send: {
                            ...node.message_to_send,
                            next_node_id: null,
                            fallback_node_id: null,
                        },
                    };
                }
                return node;
            });
        } else {
            updatedNodes = updatedNodes.map((node) => {
                if (node.id === nodeId) {
                    return {
                        ...node,
                        is_node_switched: false,
                        message_to_send: {
                            ...node.message_to_send,
                            next_node_id: null,
                            fallback_node_id: null,
                        },
                    };
                }
                return node;
            });
        }

        const updatedEdges = edges.filter((edge) => edge.source !== nodeId);
        setIsSwitchChecked(is_checked)
        setEdges(updatedEdges);
        setNodes(updatedNodes); // Update the state with the modified nodes
    }

    const handleAddButtonDebounced = debounce(() => {
        const id = uuidv4();
        const newReplyButtonSection = {
            type: 'reply',
            reply: {
                id, // Generate unique ID for the new reply button section
                title: '',
            }
        };
    
        if (findIndex !== -1) {
            const updatedNodes = [...nodes];
            updatedNodes[findIndex] = {
                ...updatedNodes[findIndex],
                message_to_send: {
                    ...updatedNodes[findIndex].message_to_send,
                    template: {
                        ...updatedNodes[findIndex].message_to_send.template,
                        value: {
                            ...updatedNodes[findIndex].message_to_send.template.value,
                            buttons: [
                                ...updatedNodes[findIndex].message_to_send.template.value.buttons,
                                newReplyButtonSection
                            ]
                        }
                    },
                    storing_values: {
                        ...updatedNodes[findIndex].message_to_send.storing_values,
                        [id]: null
                    }
                }
            };
            console.log(updatedNodes);
            setNodes(updatedNodes); 
        }
    }, 300); // Adjust the debounce delay as needed


    const nodeText = nodes.find(node => node.id === nodeId)?.message_to_send?.template?.message || 'What’s on your mind?';

    return (
        <div 
            className="reply-button-node" 
            style={{ marginBottom: '35px', maxWidth: '300px' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Handle type="target" position={Position.Left} isConnectable={isConnectable} />
            <div className="heading__div d-flex justify-content-between">
                <span className='heading-text'>Reply Button</span>
                <Tooltip title="Button based next message" className="cursor-pointer ">
                    <Switch size='small' onChange={  (e)=>handleSwitch(e) } value={isSwitchChecked} checked={isSwitchChecked} />
                </Tooltip>
            </div>
            <div onClick={openSideBar}>
                <div className="content__div">
                    <div style={{ marginBottom: '10px' }}>
                        {nodeText}
                    </div>
                    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                        <Droppable droppableId="reply-button">
                            {provided => (
                                <div ref={provided.innerRef} { ...provided.droppableProps }>
                                    <SectionList sections={replyButton} draggable={draggable} isSwitchChecked={isSwitchChecked} />
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {replyButton.length < 3 && <Button variant="outlined" startIcon={<Plus />} className={classes.button} onClick={(event) => handleAddButton(event)}>Add Button</Button>}
                </div>
            </div>
            {!isSwitchChecked && (
                <Handle
                    id='success'
                    type='source'
                    position={Position.Right}
                    style={{
                        backgroundColor: '#5956d6',
                        border: '1px solid #5956d6',
                        padding: '5px',
                        position: 'absolute',
                        right: '-8px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        boxShadow: '0 0 0 0.25rem #c5cad9',
                        zIndex: '1',
                        display: isSwitchChecked ? 'none' : 'block' // Conditionally set display property
                    }}
                />
            )}
            <Handle
                id='fallback'
                type='source'
                position={Position.Right}
                style={{
                    backgroundColor: '#ff3964',
                    border: '1px solid #ff3964',
                    padding: '5px',
                    position: 'absolute',
                    right: '-6px',
                    top: '95%',
                    transform: 'translateY(-50%)',
                    boxShadow: '0 0 0 0.25rem #c5cad9',
                }}
            />
            {showDeleteButton && (
                <div
                    className="deleteButton"
                    onClick={handleDelete}
                    onMouseEnter={() => setShowDeleteButton(true)}
                    onMouseLeave={() => setShowDeleteButton(false)}
                >
                <Trash3 color='#8c8ace' size={20} className='' />
                </div>
            )}
        </div>
    );
}

export default ReplyButton;
