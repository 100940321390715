import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist();

export const globalVarState = atom({
    key: 'globalVarState',
    default: [],
    effects_UNSTABLE: [persistAtom],
});

export const localVarState = atom({
    key: 'localVarState',
    default: [],
    effects_UNSTABLE: [persistAtom],
});

export const editAddModelState = atom({
    key: 'editAddModelState',
    default: {
        open: false,
        title: '',
        formValues: {
            var_name: '',
            var_key: '',
            id: ''
        },
        type: ''
    },
    // effects_UNSTABLE: [persistAtom],
});
