import React, {useState} from 'react';
import styles from "../../assets/css/journey-list.module.css";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import Tooltip from '@mui/material/Tooltip';
import { confirmationDialogState, getJourneyList, journeyListState, AlertOpenState } from '../../store/journey-list';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Link } from "react-router-dom";
import axiosInstance from '../../plugins/axios';
import AlertDialog from '../basic-components/alert-dialog';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { v4 as uuidv4 } from 'uuid'

const JourneyListCard = ({ journey, fetchData }) => {
    const[confirm, setConfirm] = useRecoilState(confirmationDialogState);
    const[alertOpen, setAlertOpen] = useRecoilState(AlertOpenState);
    // const [openAlert, setOpenAlert] = useState(false);
    // const [alertMsg, setAlertMsg] = useState('');
    // const [msgType, setMsgType] = useState('error');
    
    const [list, setList] = useRecoilState(journeyListState);

    const deleteJourney = async () => {
        try {
            const response = await axiosInstance.delete(`/api/v1/communications/whatsapp/journeys/${journey.uuid}/`);
            if (response.status === 200) {
                setConfirm({ ...confirm, title: 'Delete Journey', message: `Are you sure you want to delete ${journey.name}?`, open: false })
                setAlertOpen({ ...alertOpen, open: true, msgType: 'success', alertMsg: 'Journey deleted successfully.' })
                fetchData();
            }
        } catch (error) {
            console.error(error);
            setConfirm({ ...confirm, title: 'Delete Journey', message: `Are you sure you want to delete ${journey.name}?`, open: false })
            setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'An error occurred while deleting the journey.' })
        }
    }

    const duplicateJourney = async () => {
        try {
            const response = await axiosInstance.get(`/api/v1/communications/whatsapp/journeys/${journey.uuid}/`);
            if (response.status === 200) {
                const journeyJSON = response.data.result
                const foundUUIDs = extractUUIDs(journeyJSON);
                const uuidMap = new Map();
                foundUUIDs.forEach(uuid => {
                    uuidMap.set(uuid, uuidv4());
                });
                const newJourney = replaceUUIDs(journeyJSON, uuidMap);

                // Making the Journey Name Unique
                const now = new Date();
                const timeString = now.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
                
                const payload = {
                    id: newJourney.id,
                    name: `${newJourney.name} - Copy (${timeString})`,
                    triggers: newJourney.triggers,
                    messages: newJourney.messages
                };
                const res = await axiosInstance.post('/api/v1/communications/whatsapp/journey/create/', payload);
                if (res.status === 200) {
                    fetchData()
                    setAlertOpen({ ...alertOpen, open: true, msgType: 'success', alertMsg: 'Journey duplicated successfully'});
                    setConfirm({ ...confirm, title: 'Delete Journey', message: `Are you sure you want to delete ${journey.name}?`, open: false })
                }
            }
        } catch (error) {
            console.error(error);
            setConfirm({ ...confirm, title: 'Delete Journey', message: `Are you sure you want to delete ${journey.name}?`, open: false })
            setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'An error occurred while duplicating the journey.' })
        }
    }
    
    const handleDeleteJourney = () => {
        setConfirm({
            open: true,
            title: 'Delete Journey',
            message: `Are you sure you want to delete ${journey.name}?`,
            onConfirm: () => deleteJourney(),
            onCancel: () => setConfirm({ ...confirm, title: 'Delete Journey', message: `Are you sure you want to delete ${journey.name}?`, open: false }),
        });
    }

    const handleDuplicateJourney = () => {
        setConfirm({
            open: true,
            title: 'Duplicate Journey',
            message: `Are you sure you want to duplicate "${journey.name}"?`,
            onConfirm: () => duplicateJourney(),
            onCancel: () => setConfirm({ ...confirm, title: 'Duplicate Journey', message: `Are you sure you want to duplicate "${journey.name}"?`, open: false }),
        });
    }


    const extractUUIDs = (data) => {
        const uuidSet = new Set();
    
        const isUUID = (value) => {
            const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
            return uuidRegex.test(value);
        };
    
        const recurse = (data) => {
            if (Array.isArray(data)) {
                data.forEach(item => recurse(item));
            } else if (data !== null && typeof data === 'object') {
                Object.entries(data).forEach(([key, value]) => {
                    if (isUUID(key)) {
                        uuidSet.add(key);
                    }
                    if (typeof value === 'string' && isUUID(value)) {
                        uuidSet.add(value);
                    } else if (typeof value === 'object' || Array.isArray(value)) {
                        recurse(value);
                    }
                });
            }
        };
        recurse(data);
        return Array.from(uuidSet);
    };

    const replaceUUIDs = (data, uuidMap) => {
        const recurseReplace = (data) => {
            if (Array.isArray(data)) {
                return data.map(item => recurseReplace(item));
            } else if (data !== null && typeof data === 'object') {
                const newData = {};
                Object.entries(data).forEach(async ([key, value]) => {
                    const newKey = uuidMap.has(key) ? uuidMap.get(key) : key
                    // if (newKey === 'media_url' && value){
                    //     const res = await uploadMedia(value);
                    //     if (res.status === 201 || res.status === 200) {
                    //         console.log(res.data.url);
                    //         setAlertOpen({ ...alertOpen, open: true, msgType: 'success', alertMsg: 'Files Uploaded successfully.' })
                    //         setMediaType(file.type);
                    //         return
                    //     } else {
                    //         setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Error uploading file' })
                    //         return
                    //     }
                    // }
                    if (typeof value === 'string' && uuidMap.has(value)) {
                        newData[newKey] = uuidMap.get(value);
                    } else if (typeof value === 'object' || Array.isArray(value)) {
                        newData[newKey] = recurseReplace(value);
                    } else {
                        newData[newKey] = value;
                    }
                });
                return newData;
            }
            return data;
        };
        return recurseReplace(data);
    };

    // const uploadMedia = async (fileUrl) => {
    //     const response = await axiosInstance.get("https://dwrqao8j794zb.cloudfront.net/communications/journeys/d8ae4b05-b366-4737-bf34-a7100b500022/image/6aa4d450-f231-4134-8fd3-1a12e63a585e-591e9c07-cdac-5537-8c00-4e7791c2c04c.png");
    //     console.log(response);
    //     const file = new File([response.data], 'filename', {
    //         type: response.type,
    //     });
    //     console.log(file);

    //     // const formData = new FormData();
    //     // formData.append('file', file);
    //     // formData.append('mime_type', file.type);
    //     return response
    // }

    const firstLetter = journey.name.charAt(0);
    const bgColor = getRandomColor(firstLetter);
    return (
        <div className={styles.journey__card}>
            <div className="card-body">
                <div className="d-flex align-items-center gap-5">
                    <div style={{ backgroundColor: bgColor }} className={styles.first__letter}>
                        <p className="mb-0">{firstLetter}</p>
                    </div>
                    <Link className="flex-grow-1 text-decoration-none" key={journey.uuid} to={journey.uuid}>
                        <h5>{journey.name}</h5>
                    </Link>
                    <div>
                        <Tooltip title="Duplicate Journey" arrow placement="top">
                            <ContentCopyIcon className="cursor-pointer" onClick={handleDuplicateJourney} />
                        </Tooltip>
                    </div>
                    <div>
                        <Tooltip title="Delete Journey" arrow placement="top">
                            <DeleteOutlineRoundedIcon className="cursor-pointer" onClick={handleDeleteJourney} />
                        </Tooltip>
                    </div>
                </div>
            </div>
            <AlertDialog />
        </div>
    );
}

const getRandomColor = (firstLetter) => {
    const colorMap = {
        A: "#FF5733",
        B: "#52a836",
        C: "#FF3366",
        D: "#31e0b5",
        E: "#CC33FF",
        F: "#FFCC33",
        G: "#33e4ff",
        H: "#3366FF",
        I: "#FF33CC",
        J: "#d42fc0",
        K: "#afe312",
        L: "#33CCFF",
        M: "#FF6633",
        N: "#33FF33",
        O: "#FF6633",
        P: "#0fccd6",
        Q: "#4091c7",
        R: "#a277e6",
        S: "#ffb833",
        T: "#33CC66",
        U: "#CC6633",
        V: "#3366CC",
        W: "#66CC33",
        X: "#CC3366",
        Y: "#4c9ec7",
        Z: "#ff6699"
    }
    return colorMap[firstLetter.toUpperCase()];
}

export default JourneyListCard;