// Function to check message error
export const checkMessageError = (initialValue) => {
    let errorMessage = '';
    if (initialValue === '') {
        errorMessage = "Message can not be empty.";
    } else if (initialValue.length > 900) {
        errorMessage = "Message should be less than 901 characters";
    }
    console.log(errorMessage)
    return errorMessage;
};

// Function to check header error
export const checkHeaderAndFooterError = (type, initialValue) => {
    let errorMessage = '';
    if (initialValue.length > 60) {
        errorMessage = `${type} should be less than 61 characters`;
    }

    return errorMessage;
};

export const checkButtonTitleError = (initialValue) => {
    let errorMessage = '';
    if (initialValue === '') {
        errorMessage = "Button title can't be empty.";
    } else if (initialValue.length > 20) {
        errorMessage = "Button title should be less than 21 characters";
    }

    return errorMessage;
}

export const checkSectionTitleError = (initialValue) => {
    let errorMessage = '';
    if (initialValue === '') {
        errorMessage = "Section title can't be empty.";
    } else if (initialValue.length > 24) {
        errorMessage = "Section title should be less than 25 characters";
    }

    return errorMessage;
}

export const checkRowTitleError = (initialValue) => {
    let errorMessage = '';
    if (initialValue === '') {
        errorMessage = "Button value can't be empty.";
    } else if (initialValue.length > 24) {
        errorMessage = "Button value should be less than 25 characters";
    }

    return errorMessage;    
}

export const checkDescriptionError = (initialValue) => {
    let errorMessage = '';
    if (initialValue.length > 72) {
        errorMessage = "Description should be less than 73 characters";
    }

    return errorMessage;
}

export const checkVariableError = (initialValue) => {
    let errorMessage = '';
    if (initialValue.length > 150) {
        errorMessage = "Variable should be less than 151 characters";
    }
    return errorMessage;
}

export const checkStartingNodeValueError = (initialValue) => {
    let errorMessage = '';
    if(initialValue === '') {
        errorMessage = "Please enter a message";
    }else if (initialValue.length > 100) {
        errorMessage = "Starting node value should be less than 101 characters";
    }
    return errorMessage;
}

export const checkConditionalTypeTriggerError = (initialValue) => {
    let errorMessage = '';
    if (initialValue === '' || initialValue === null) {
        errorMessage = "Please select from the list";
    }
    return errorMessage;
}