import React, { useState, useRef, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { openCreateOptinsBoxState } from '../../store/optins';
import { AlertOpenState } from '../../store/journey-list';
import { useRecoilState } from 'recoil';
import styles from '../../assets/scss/page/optins-page.module.scss';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import AlertDialog from '../../components/basic-components/alert-dialog';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import SendIcon from '@mui/icons-material/Send';
import FormHelperText from '@mui/material/FormHelperText';
import DownloadIcon from '@mui/icons-material/Download';
import SampleCSVFile from '../../assets/files/sample_file.csv';
import Tooltip from '@mui/material/Tooltip';
import axiosInstance from '../../plugins/axios';

const ErrorText = styled('p')`
  color: red;
  font-size: 0.75rem;
  margin-bottom: 0px;
  margin-top: 2px;
`;

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function MyFormHelperText() {
    const { focused } = useFormControl() || {};
  
    const helperText = React.useMemo(() => {
      if (focused) {
        return 'This field is being focused';
      }
  
      return 'Helper text';
    }, [focused]);
  
    return <FormHelperText>{helperText}</FormHelperText>;
}

const OptinsUpload = () => {
    const [openDialog, setOpenDialog] = useRecoilState(openCreateOptinsBoxState);
    const [templateName, setTemplateName] = useState('');
    const [optInName, setOptInName] = useState('');
    const [optInBlur, setOptInBlur] = useState(false);
    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useRecoilState(AlertOpenState);

    const theme = useTheme();
    const inputRef = useRef(null);

    useEffect(async () => {

    }, []);

    const handleClose = () => {
        setOpenDialog(false)
    }

    const uploadMedia = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            const formData = new FormData();
            formData.append('file', file);
            formData.append('file1', file);
            formData.append('name', optInName);
            const res = await axiosInstance.post('/api/v1/communications/whatsapp/users/option/request/', formData);
            console.log(res);
            if (res.status === 200) {
                setLoading(false);
                setAlertOpen({ ...alertOpen, open: true, msgType: 'success', alertMsg: `${res?.data?.result ? res?.data?.result : 'Opt-in created successfully.'}` });
                setOpenDialog({ ...openDialog, open: false });
                window.location.reload();
                // setOptInName('');
                // setFileName('');
                // setFile(null);
            } else {
                setLoading(false);
                setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: `${res?.data?.result ? res?.data?.result : 'Failed to create opt-in. Please try again.'}` });
            }
        } catch (error) {
            setLoading(false);
            console.log(error.response.data.result)
            setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: `${error?.response?.data?.result ? error?.response?.data?.result : 'Failed to create opt-in. Please try again.'}` });
            console.error('Error fetching data:', error);
        }
    };
    
    
    const onChangeFileHandler = async (e) => {
        try{
            const file = e.target.files[0];
            setFileName(file.name);
            setFile(file);
            // await uploadMedia(file);
        }catch (error) {
            
        }
    }

    const validateOptInName = () => {
        if(optInName === ''){
            return 'Opt-in name is required';
        }else if(optInName.length > 200){
            return 'Opt-in name must be less than 200 characters';
        }   
        return ''; 
    }

    const downloadSampleFile = () => {
        const link = document.createElement('a');
        link.href = SampleCSVFile; // Set the href attribute to the URL of your sample CSV file
        link.download = 'sample_file.csv'; // Set the download attribute to specify the file name
        // Append the link to the document body
        document.body.appendChild(link);
        // Trigger the click event on the link to start the download
        link.click();
        // Remove the link from the document body
        document.body.removeChild(link);
    }

    const resetFileData = () => {
        // setFileName('');
        setFileName('');
        setFile(null);
    }


    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth='sm'
                open={openDialog.open}
                onClose={handleClose}
            >   <div className={styles.dialog__container}>
                    <Stack direction="row" spacing={2} className='mt-1 d-flex justify-content-between align-items-center'>
                        <h4>{openDialog.title}</h4>
                        <div className="">
                            <IconButton onClick={handleClose}>
                                <CloseOutlinedIcon 
                                    className={styles.close__btn} 
                                /> 
                            </IconButton>
                        </div>
                    </Stack>
                    <div>
                        <form noValidate autoComplete="off" onSubmit={uploadMedia} className='mt-4 w-100'>
                            <Stack direction="row" spacing={2} >
                                <div className="w-50">
                                    <FormControl fullWidth>
                                        <TextField id="opt-in-request-name" 
                                            label="Opt-in Request Name" 
                                            style={{ color: theme.palette.primary.dark }} 
                                            value={optInName} 
                                            onChange={(e) => setOptInName(e.target.value)} 
                                            size='small' 
                                            onBlur={() => setOptInBlur(true)}
                                            error={optInBlur && validateOptInName() !== ''}
                                        />
                                        {
                                            optInBlur && validateOptInName() !== '' ? 
                                            <ErrorText>{validateOptInName()}</ErrorText> : 
                                            <FormHelperText id="component-error-text" style={{ marginLeft: '4px' }}>Note: Opt-in request name must be less than 200 characters.</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className="w-50">
                                    <FormControl fullWidth>
                                            {!fileName ? <Button
                                                component="label"
                                                role={undefined}
                                                variant="contained"
                                                tabIndex={-1}
                                                startIcon={<CloudUploadIcon />}
                                                disabled={loading}
                                                >
                                                Upload CSV File
                                                <VisuallyHiddenInput 
                                                    type="file" 
                                                    accept="text/csv" 
                                                    onChange={onChangeFileHandler} 
                                                    ref={inputRef}  
                                                />
                                            </Button> : 
                                            <div className='d-flex align-items-center justify-content-between' style={{backgroundColor: theme.palette.primary.light, color: theme.palette.primary.dark, padding: '4px 8px', borderRadius: '20px', marginLeft: '4px'}}>
                                                <div className="">
                                                    {fileName}
                                                </div>
                                                <div className="">
                                                    <Tooltip title="Remove file">
                                                        <IconButton size='small' onClick={resetFileData} style={{marginLeft: '2px', cursor: 'pointer'}}>
                                                            <CancelIcon style={{color: theme.palette.primary.dark}} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </div>}
                                    </FormControl>
                                </div>
                            </Stack>
                            <div id="component-error-text" style={{ marginLeft: '4px' }}>
                                <Button onClick={downloadSampleFile} variant="outlined" color="primary" startIcon={<DownloadIcon />} className='mt-3'>Download Sample File</Button>
                                <div className="" style={{ fontSize: '14px', color: theme.palette.primary.dark }}>
                                    <ol className={styles.sample__list}>
                                        <li>"number" and "country_code" columns are mandatory and no ‘+’ symbol. Eg. "91" not "+91"</li>
                                        <li>Phone number should not have country code as prefix.</li>
                                        <li>Only CSV format is supported</li>
                                        <li>Maximum 5000 entries allowed</li>
                                    </ol>
                                </div>
                            </div>
                            <Stack direction="row" spacing={2} className='mt-3 d-flex justify-content-end align-items-center'>
                                <Button variant="outlined" onClick={() => setOpenDialog({open: false, type: '', title: '', id: ''})} className='d-flex justify-content-center align-items-center' endIcon={<CancelIcon className='mb-1' />}>
                                    Cancel
                                </Button>
                                <Button variant="contained" type='submit' endIcon={<SendIcon />}>
                                    {openDialog.type === 'edit' && openDialog.id ? 'Update' : 'Create'}
                                </Button>
                            </Stack>
                        </form>
                    </div>   
                </div>
            </Dialog>
            <AlertDialog />   
        </>
    )
}

export default OptinsUpload;