import React, { useEffect, Suspense, useState } from 'react';
import styles from '../../assets/scss/page/broadcasts.module.scss';
import { useRecoilState } from "recoil";
import { allChatsState, broadcastIdState, chatsNextURLState } from "../../store/agent-chats";
import axiosInstance from '../../plugins/axios';
import { useParams, useNavigate } from 'react-router-dom';
import BroadcastStatus from '../../components/broadcasts/broadcast-status';
import RescheduleBroadcast from "../../components/broadcasts/resheduleBroadcast";
import { confirmationDialogState, AlertOpenState } from '../../store/journey-list';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { openRescheduleBroadcastBoxState } from '../../store/broadcasts';
import moment from 'moment';
import Cookies from 'js-cookie';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    backgroundColor: theme.palette.mode === 'dark' ? '#ffc107' : '#ffd54f',
    '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? '#fdd835' : '#fdd835',
    },
}));

const ViewBroadcast = () => {
    const { id } = useParams();
    const [allChat, setAllChats] = useRecoilState(allChatsState);
    const [broadcastId, setBroadcastId] = useRecoilState(broadcastIdState);
    const [chatsNextURL, setChatsNextURL] = useRecoilState(chatsNextURLState);
    const [data, setData] = useState({});
    const [openDialog, setOpenDialog] = useRecoilState(openRescheduleBroadcastBoxState);
    const [confirm, setConfirm] = useRecoilState(confirmationDialogState);
    const [alertOpen, setAlertOpen] = useRecoilState(AlertOpenState);
    const navigate = useNavigate();
    
    useEffect(() => {
        const token = Cookies.get('Token');
        if(!token){
            localStorage.removeItem('Token');
            window.location.href = '/login'
        }
        else{
            fetchData();
        }
    }, [id]);

    const handleBack = () => {
        navigate(-1)
    };

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`api/v1/communications/whatsapp/campaigns/${id}/`);
            setData(response.data.result);
        } catch (error) {
            console.log(error);
        }
    };
    
    const goToChat = async () => {
        navigate('/agent-chats');
        const response = await axiosInstance.get(`/api/v1/communications/whatsapp/all-chats/?being_handled_by=all&campaign_id=${id}`);
        setBroadcastId({ id: id, name: data.name });
        setChatsNextURL(response.data.next);
        setAllChats(response.data.results);
    };

    const rescheduleBroadcast = () => {
        setOpenDialog({ open: true, id: id, title: 'Reschedule Broadcast', type: 'reschedule' });
    };

    const onConfirmDialog = (function_to_run) => {
        setConfirm({
            open: true,
            title: 'Are you sure?',
            message: `Please confirm that you want to make changes?`,
            onConfirm: async () => await function_to_run(),
            onCancel: () => setConfirm({ ...confirm, title: 'Are you sure?', message: `Please confirm that you want to make changes?`, open: false }),
        });
    };

    const playAndPause = async () => {
        try {
            const response = await axiosInstance.patch(`/api/v1/communications/whatsapp/campaigns/${id}/`, {
                is_pause: !data.is_pause
            });
            if(response.status === 200){
                setAlertOpen({...alertOpen, open: true, alertMsg: response.data.msg, severity: 'success' });
                setData({ ...data, is_pause: !data.is_pause });
            }
            setConfirm({ ...confirm, open: false });
        } catch (error) {
            if(error.response.status !== 200){
                setAlertOpen({...alertOpen, open: true, alertMsg: error.response.data.msg, severity: 'error' });
            }else{
                setAlertOpen({...alertOpen, open: true, alertMsg: 'Something went wrong', severity: 'error' });
            }
            console.log(error);
        }
    };

    const isTimeDisabled = () => {
        const startDateTime = moment(data.start_date).add(moment.duration(data.start_time)); // Assuming data.start_time is in 'HH:mm' format
        if(startDateTime.isBefore(moment().add(15, 'minutes'))) {
            return true;
        }
        return false;
    };

    const deleteBroadcast = async () => {
        try{
            const response = await axiosInstance.delete(`/api/v1/communications/whatsapp/campaigns/${id}/`);
            if(response.status === 200){
                setAlertOpen({...alertOpen, open: true, alertMsg: response.data.msg, severity: 'success' });
            }
            setConfirm({ ...confirm, open: false });
            navigate('/broadcasts');
        } catch (error) {
            if(error.response.status !== 200){
                setAlertOpen({...alertOpen, open: true, alertMsg: error.response.data.msg, severity: 'error' });
            }else{
                setAlertOpen({...alertOpen, open: true, alertMsg: 'Something went wrong', severity: 'error' });
            }
            setConfirm({ ...confirm, open: false });
        }
    }
    

    return (
        <>
            <div className={styles.broadcast__list__container}>
                <Button size='small' color="primary" startIcon={<KeyboardBackspaceIcon />} className='mb-1' onClick={handleBack}>
                    Back
                </Button>
                <div className="d-flex justify-content-between">
                    <h4>Broadcasts</h4>
                    <Button onClick={goToChat} sx={{ width: '80px', height: '30px' }} variant="contained" size='small'>Chats</Button>
                </div>
                <p>Analytics of broadcast request.</p>
                <p className={styles.broadcast__name}>{data.name}</p>
                <div className={styles.broadcast__id}>
                    <div className="d-flex">
                        <div className={styles.broadcast__id__title}>ID: </div>
                        <div className={styles.broadcast__id__value}> #{data.broadcast_id ? data.broadcast_id : data.id}</div>
                    </div>
                    <div className="">
                        <Button disabled={isTimeDisabled()} onClick={rescheduleBroadcast} sx={{ height: '30px' }} variant="contained" color="success" size='small' startIcon={<AccessTimeFilledIcon />}>Reschedule</Button>
                        <ColorButton className='mx-3' disabled={isTimeDisabled()} onClick={() => onConfirmDialog(playAndPause)} sx={{ height: '30px' }} variant="contained" color="warning" size='small' startIcon={data.is_pause ? <PlayArrowIcon /> : <PauseIcon />}>{data.is_pause ? 'Resume' : 'Pause'}</ColorButton>
                        <Button disabled={isTimeDisabled()} onClick={() => onConfirmDialog(deleteBroadcast)} sx={{ height: '30px' }} startIcon={<DeleteIcon />} variant="contained" color="error" size='small'>Delete</Button>
                    </div>
                </div>
            </div>
            <BroadcastStatus item={data} />
            <RescheduleBroadcast broadcastData={data} setBroadcastData={setData} />
        </>
    );
};

const SuspenseRescheduleBroadcast = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <ViewBroadcast />
        </Suspense>
    );
};

export default SuspenseRescheduleBroadcast;
