import React, { useState, useEffect } from "react";
import { useRecoilState } from 'recoil';
import { Plus, Trash3 } from 'react-bootstrap-icons'
import DataObjectIcon from '@mui/icons-material/DataObject';
import styles from  '../../assets/css/reply-button-edit.module.css';
import TextArea from "../ui-components/text-area";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/system';
import { nodesState, editTypesState, isFormSubmittedState } from '../../store/state';
import { variableListState } from '../../store/journey-list';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { debouncedHandleText, handleVariableListDebounce, debouncedHandleHeader, debouncedHandleFooter } from '../../mixins/journey'
import { checkHeaderAndFooterError, checkMessageError, checkButtonTitleError, checkVariableError } from '../../mixins/errors'

const ErrorText = styled('p')`
  color: red;
  font-size: 0.75rem;
  margin-bottom: 0px;
`;

const ReplyEdit = () => {
    const [editTypes, setEditTypes] = useRecoilState(editTypesState);
    const [nodes, setNodes] = useRecoilState(nodesState);
    const [initialValue, setInitialValue] = useState('');
    const [initialButtons, setInitialButtons] = useState([]);
    const [initialVariable, setInitialVariable] = useState(null);
    const [variableList, setVariableList] = useRecoilState(variableListState);
    const [isHeader, setIsHeader] = useState(false);
    const [initialHeaderValue, setInitialHeaderValue] = useState('');
    const [isFooter, setIsFooter] = useState(false);
    const [initialFooterValue, setInitialFooterValue] = useState('');
    const [storingValues, setStoringValues] = useState(null);
    const [buttonTitleBlurStatus, setButtonTitleBlurStatus] = useState(Array(initialButtons.length).fill(false));
    const [isFormSubmitted, setIsFormSubmitted] = useRecoilState(isFormSubmittedState);

    const findIndex = nodes.findIndex(item => item.id === editTypes.selected_id);
    const replyButtons = nodes.find((node) => node.id === editTypes.selected_id)?.message_to_send.template.value.buttons || [];


    useEffect(() => {
        if (findIndex !== -1) {
            setInitialValue(nodes[findIndex]?.message_to_send.template.message || '');
            setInitialButtons(replyButtons.map(button => ({ ...button })));
            setInitialVariable(nodes[findIndex].message_to_send.store_response_in);
            setInitialHeaderValue(nodes[findIndex].message_to_send.template.header);
            setIsHeader(nodes[findIndex].message_to_send.template.header !== '');
            setIsFooter(nodes[findIndex].message_to_send.template.footer !== '');
            setInitialFooterValue(nodes[findIndex].message_to_send.template.footer);
            setStoringValues(nodes[findIndex].message_to_send.storing_values);

        }
    }, [editTypes.selected_id]);

    const handleButtonTitleChangeDebounce = debounce((event, buttonIndex) => {
        if (findIndex !== -1) {
            const updatedNodes = [...nodes];
            updatedNodes[findIndex] = {
                ...updatedNodes[findIndex],
                message_to_send: {
                    ...updatedNodes[findIndex].message_to_send,
                    template: {
                        ...updatedNodes[findIndex].message_to_send.template,
                        value: {
                            ...updatedNodes[findIndex].message_to_send.template.value,
                            buttons: updatedNodes[findIndex].message_to_send.template.value.buttons.map((button, index) => {
                                if (index === buttonIndex) {
                                    return {
                                        ...button,
                                        reply: {
                                            ...button.reply,
                                            title: event.target.value
                                        }
                                    };
                                }
                                return button;
                            })
                        }
                    }
                }
            };
            setNodes(updatedNodes);
        }
    }, 300);
    

    const handleButtonTitleChange = (event, buttonIndex) => {
        const newButtons = [...initialButtons];
        newButtons[buttonIndex] = {
            ...newButtons[buttonIndex],
            reply: {
                ...newButtons[buttonIndex].reply,
                title: event.target.value
            }
        };
        setInitialButtons(newButtons);
        handleButtonTitleChangeDebounce(event, buttonIndex);
    };
    

    const handleAddButtonDebounced = debounce((newId) => {
        const newReplyButtonSection = {
            type: 'reply',
            reply: {
                id: newId, // Generate unique ID for the new reply button section
                title: '',
            }
        };

        if (findIndex !== -1) {
            const updatedNodes = [...nodes];
            updatedNodes[findIndex] = {
                ...updatedNodes[findIndex],
                message_to_send: {
                    ...updatedNodes[findIndex].message_to_send,
                    template: {
                        ...updatedNodes[findIndex].message_to_send.template,
                        value: {
                            buttons: [
                                ...updatedNodes[findIndex].message_to_send.template.value.buttons,
                                newReplyButtonSection
                            ]
                        }
                    },
                    storing_values: {
                        ...updatedNodes[findIndex].message_to_send.storing_values,
                        [newId]: null
                    }
                }
            };
            setNodes(updatedNodes); 
        }
    }, 300);

    const handleAddButton = () => {
        const newId = uuidv4();
        setInitialButtons(prevButtons => [...prevButtons, { type: 'reply', reply: { id: newId, title: '' } }]);
        setStoringValues(prevValues => ({ ...prevValues, [newId]: null }));
        handleAddButtonDebounced(newId);
    }

    const handleDeleteButtonDebounced = debounce((indexToDelete, id) => {
        if (findIndex !== -1) {
            const updatedNodes = [...nodes];
            updatedNodes[findIndex] = {
                ...updatedNodes[findIndex],
                message_to_send: {
                    ...updatedNodes[findIndex].message_to_send,
                    template: {
                        ...updatedNodes[findIndex].message_to_send.template,
                        value: {
                            buttons: updatedNodes[findIndex].message_to_send.template.value.buttons.filter((button, index) => index !== indexToDelete)
                        }
                    },
                    storing_values: Object.keys(updatedNodes[findIndex].message_to_send.storing_values)
                    .filter(key => key !== id)
                    .reduce((obj, key) => {
                        obj[key] = updatedNodes[findIndex].message_to_send.storing_values[key];
                        return obj;
                    }, {})
                },
            };
            console.log(updatedNodes);
            setNodes(updatedNodes);
        }
    }, 300);

    const handleDeleteButton = (indexToDelete, id) => {
        setInitialButtons(prevButtons => {
            const updatedButtons = [...prevButtons];
            updatedButtons.splice(indexToDelete, 1);
            return updatedButtons;
        });
        handleDeleteButtonDebounced(indexToDelete, id);
    };


    const onChangeMessage = (e) => {
        const { value } = e.target;
        setInitialValue(value);
        debouncedHandleText(findIndex, nodes, setNodes)(value);
    }

    const handleVariableList = (e) => {
        const { value } = e.target;
        const item = variableList.find(item => item.name === value)
        setInitialVariable(item);
        handleVariableListDebounce(findIndex, nodes, setNodes)(item);
    }

    const openHeader = () => {
        setIsHeader(!isHeader);
        if(isHeader) {
            setInitialHeaderValue('');
            const updatedNodes = [...nodes];
            updatedNodes[findIndex] = {
                ...updatedNodes[findIndex],
                message_to_send: {
                    ...updatedNodes[findIndex].message_to_send,
                    template: {
                        ...updatedNodes[findIndex].message_to_send.template,
                        header: ''
                    }
                }
            };
            setNodes(updatedNodes);
        }
    }

    const handleChangeHeader = (e) => {
        const { value } = e.target;
        setInitialHeaderValue(value);
        debouncedHandleHeader(findIndex, nodes, setNodes)(value);
    }

    const openFooter = () => {
        setIsFooter(!isFooter);
        if(isFooter) {
            setInitialFooterValue('');
            const updatedNodes = [...nodes];
            updatedNodes[findIndex] = {
                ...updatedNodes[findIndex],
                message_to_send: {
                    ...updatedNodes[findIndex].message_to_send,
                    template: {
                        ...updatedNodes[findIndex].message_to_send.template,
                        footer: ''
                    }
                }
            }
            setNodes(updatedNodes);
        }
    }

    const handleChangeFooter = (e) => {
        const { value } = e.target;
        setInitialFooterValue(value);
        debouncedHandleFooter(findIndex, nodes, setNodes)(value);
    }

    const openVariable = (id) => {
        const updatedStoringValues = {...storingValues};
        if(!storingValues[id] && storingValues[id] === '') {
            updatedStoringValues[id] = null;
        } else {
            updatedStoringValues[id] = '';
        }
        setStoringValues(updatedStoringValues);
    }

    const handleDeleteVariableDebounce = debounce((id) => {
        const updatedNodes = [...nodes];
        updatedNodes[findIndex] = {
            ...updatedNodes[findIndex],
            message_to_send: {
                ...updatedNodes[findIndex].message_to_send,
                storing_values: {
                    ...updatedNodes[findIndex].message_to_send.storing_values,
                    [id]: null
                }
            }
        };
        setNodes(updatedNodes);
    }, 300);

    const handleDeleteVariable = (id) => {
        setStoringValues({...storingValues, [id]: null});
        handleDeleteVariableDebounce(id);
    }

    const handleVariableDebounce = debounce((value, id) => {
        const updatedNodes = [...nodes];
        updatedNodes[findIndex] = {
            ...updatedNodes[findIndex],
            message_to_send: {
                ...updatedNodes[findIndex].message_to_send,
                storing_values: {
                    ...updatedNodes[findIndex].message_to_send.storing_values,
                    [id]: value // Use square brackets for computed property name
                }
            }
        };


        console.log(updatedNodes);
        setNodes(updatedNodes);
    }, 300);

    const handleVariableChange = (e, id) => {
        const { value } = e.target;
        const updatedStoringValues = {...storingValues};
        updatedStoringValues[id] = value;
        setStoringValues(updatedStoringValues);
        handleVariableDebounce(value, id);
    }

    const handleButtonTitleBlur = (index) => {
        const updatedButtonTitleBlurStatus = [...buttonTitleBlurStatus];
        updatedButtonTitleBlurStatus[index] = true;
        setButtonTitleBlurStatus(updatedButtonTitleBlurStatus);
    };

    const handleAddVariable = (newValue) => {
        setInitialValue(newValue);
    };

    return (
        <div className={styles.reply_button_edit}>
            {
                !isHeader ? 
                <div className="d-flex justify-content-start mb-3">
                    <Button variant="outlined" size="small" color="primary" onClick={openHeader}>+ Header</Button>
                </div>
                : 
                <>
                    <FormControl size="small" className="mx-0 mb-3" fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="header">Header</InputLabel>
                        <OutlinedInput
                            endAdornment={<InputAdornment position="end">
                                <Trash3 className="cursor-pointer" onClick={openHeader} />
                            </InputAdornment>}
                            id="header"
                            label="Header"
                            value={initialHeaderValue} 
                            onChange={(e) => handleChangeHeader(e)} 
                            error={checkHeaderAndFooterError('Header',initialHeaderValue)}
                        />
                        <ErrorText error>{checkHeaderAndFooterError('Header',initialHeaderValue)}</ErrorText>
                    </FormControl>
                </>
            }
            <div className="">
                <InputLabel 
                    className="mb-1" 
                    htmlFor="message" 
                    style={{fontSize: '12px', fontWeight: 'bold', textTransform: 'uppercase', letterSpacing: '1px', marginLeft: '4px'}}
                >
                    Message
                </InputLabel>
                
                <TextArea
                    showVariable={true}
                    onChange={onChangeMessage}
                    label="Message"
                    minRows={3}
                    placeholder="What's on your mind"
                    variant="outlined"
                    value={initialValue}
                    error={checkMessageError(initialValue)}
                    errorMessage={checkMessageError(initialValue)}
                    onAddVariable={handleAddVariable}
                />
            </div>
            {initialButtons.map((button, index) => (
                <div key={index}>
                    <FormControl size="small" className="mx-0" fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor={`button-title-${index}`}>Button Title</InputLabel>
                        <OutlinedInput
                            endAdornment={
                                index === 0 ? ( // Check if index is 0
                                    <InputAdornment position="end">
                                        <Tooltip title="Corresponding Value" className="cursor-pointer ">
                                            <IconButton className="primary_icon cursor-pointer" onClick={() => openVariable(button.reply.id)}>
                                                <DataObjectIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                ) : (
                                    <>
                                        <InputAdornment position="end">
                                            <Tooltip title="Corresponding Value" className="cursor-pointer ">
                                                <IconButton className="primary_icon cursor-pointer" onClick={() => openVariable(button.reply.id)}>
                                                    <DataObjectIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                        <InputAdornment position="end">
                                            <Tooltip title="Delete" className="">
                                                <IconButton className="trash_icon cursor-pointer" onClick={() => handleDeleteButton(index, button.reply.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    </>
                                )
                            }
                            id={`button-title-${index}`}
                            label="Button Title"
                            value={button.reply.title}
                            onChange={(event) => handleButtonTitleChange(event, index)}
                            error={(buttonTitleBlurStatus[index] || isFormSubmitted) && checkButtonTitleError(button.reply.title)}
                            onBlur={() => handleButtonTitleBlur(index)}
                        />

                        {(buttonTitleBlurStatus[index] || isFormSubmitted) && checkButtonTitleError(button.reply.title) && <ErrorText>{checkButtonTitleError(button.reply.title)}</ErrorText>}
                    </FormControl>
                    {storingValues[button.reply.id] !== null && (
                        <div className="">
                            <FormControl size="small" className="mx-0" fullWidth sx={{ m: 1 }}>
                                <InputLabel htmlFor={`variable-${index}`}>Corresponding Value</InputLabel>
                                <OutlinedInput
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Tooltip title="Delete" className="">
                                                <IconButton className="trash_icon cursor-pointer" onClick={() => handleDeleteVariable(button.reply.id)}>
                                                        <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                    id={`variable-${index}`}
                                    label="Corresponding Value"
                                    value={storingValues[button.reply.id]}
                                    onChange={(event) => handleVariableChange(event, button.reply.id)}
                                    error={checkVariableError(storingValues[button.reply.id] || '')}
                                />
                                <ErrorText>{checkVariableError(storingValues[button.reply.id] || '')}</ErrorText>
                            </FormControl>
                        </div>
                    )}
                </div>
            ))}

            {replyButtons.length < 3 && <div className="my-2">
                <Button variant="contained" size="small" startIcon={<Plus />} onClick={handleAddButton}>
                    Add Button
                </Button>
            </div>}
            
            {
                !isFooter ? 
                <div className="d-flex justify-content-start mt-3">
                    <Button variant="outlined" size="small" color="primary" onClick={openFooter}>+ Footer</Button>
                </div>
                : 
                <FormControl size="small" className="mx-0 mt-3 d-flex justify-content-start" fullWidth>
                    <InputLabel htmlFor="footer">Footer</InputLabel>
                    <OutlinedInput
                        endAdornment={<InputAdornment position="end">
                            <Trash3 className="cursor-pointer" onClick={openFooter} />
                        </InputAdornment>}
                        id="footer"
                        label="Footer"
                        value={initialFooterValue} 
                        onChange={(e) => handleChangeFooter(e)} 
                        error={checkHeaderAndFooterError('Footer',initialFooterValue)}
                    />
                    <ErrorText error>{checkHeaderAndFooterError('Footer',initialFooterValue)}</ErrorText>
                </FormControl>
            }
            <FormControl fullWidth size="small" className="mt-3">
                <InputLabel id="select-variable">Store Response In</InputLabel>
                <Select
                    labelId="select-variable"
                    id="select-variable"
                    value={initialVariable ? initialVariable.name : ''}  // Set the value to the selected variable value from the state
                    label="Store Response In"
                    onChange={handleVariableList}
                >
                    <MenuItem value={null}>--Store Response In--</MenuItem>
                    {variableList.map((item, index) => <MenuItem className={item.is_global ? 'global_response' : ''}  key={index} value={item.name}>{item.name}</MenuItem>)}
                </Select>
                <small className="mt-1">NOTE: Underlined values are global variables.</small>
            </FormControl>
        </div>
    )
}

export default ReplyEdit;
