import React, { useEffect, useState, Suspense } from 'react'
import Loading from "../../components/basic-components/Loading";
import { useParams } from "react-router-dom";
import axiosInstance from "../../plugins/axios";
import styles from '../../assets/scss/page/journey-analytics.module.scss';
import JourneyStatus from "../../components/analytics/journey-status";
import Cookies from 'js-cookie';

const JourneyAnalytics = () => {
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const journeyID = params.uuid
    const [analytics, setAnalytics] = useState(null);

    useEffect(async () => {
        try{
            const token = Cookies.get('Token');
            if(!token){
                localStorage.removeItem('Token');
                window.location.href = '/login'
            }
            else{
                const res = await axiosInstance.get(`/api/v1/communications/whatsapp/journeys/analytics/${journeyID}/`)
                setAnalytics(res.data)   
            }
        }
        catch(error){
            console.log(error)
        }
    }, [])

    return (
        <>
            <div className={styles.journey_analytics__container}>
            <h4>Journey Analytics</h4>
                <p className={styles.analytics__name}>Journey Name: {analytics?.journey_details?.name}</p>
                <p className={styles.analytics__id}>ID: #{analytics?.journey_details?.uuid}</p>
            </div>
            <div className={styles.journey_analytics__container}>
                <JourneyStatus item={analytics} />
            </div>
        </>
    )
}

const SuspenseJourneyAnalytics = () => (
    <Suspense 
        fallback={ <Loading /> }
    >
        <JourneyAnalytics />
    </Suspense>
);


export default SuspenseJourneyAnalytics;