import * as React from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useRecoilState } from 'recoil';
import { AlertOpenState } from '../../store/journey-list'

export default function AlertDialog() {
	const [values, setValues] = useRecoilState(AlertOpenState);
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	}
	const handleClose = () => {
		setOpen(false);
		setValues({
			...values,
			open: false
		})
	}
	return (
		<Snackbar 
                open={values.open} 
                autoHideDuration={values.timing} 
                anchorOrigin={values.position}
                onClose={handleClose}
            >
                <Alert
                    severity={values.msgType}
                    variant={values.variant}
                    sx={{ width: '100%' }}
                    onClose={handleClose}
                >
                    {values.alertMsg}
                </Alert>
		</Snackbar>
	);
}