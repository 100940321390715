import React, { Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../basic-components/Loading";
import { useRecoilState } from "recoil";
import { userState } from "../../store/auth";
import { graphState, uDataState, pDataState, xLabelsState } from "../../store/homepage";
import styles from '../../assets/scss/components/homepage.module.scss';
import whatsAppLogo from '../../assets/images/Homepage/ic_whatsapp.png';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import TerminalIcon from '@mui/icons-material/Terminal';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { v4 as uuidv4 } from 'uuid';
import { LineChart } from '@mui/x-charts/LineChart';
import axiosInstance from '../../plugins/axios';
import Cookies from 'js-cookie';

const HomePage = () => {
    const [user, setUser] = useRecoilState(userState);
    const [active, setActive] = useState('journey');
    const [graphValues, setGraphValues] = useRecoilState(graphState);
    const [uData, setUData] = useRecoilState(uDataState);
    const [pData, setPData] = useRecoilState(pDataState);
    const [xLabels, setXLabels] = useRecoilState(xLabelsState);

    useEffect(() => {
        try {
            const token = Cookies.get('Token');
            if(!token){
                localStorage.removeItem('Token');
                window.location.href = '/login'
            }
            else{
                fetchGraphValues();   
            }
        } 
        catch (error) {
            console.log(error)    
        }
    }, [setGraphValues]);

    const fetchGraphValues = async () => {
        try {
            const { data } = await axiosInstance.get('/api/v1/communications/whatsapp/get-charts/');
            if (data) {
                setGraphValues(data);
                setUData(data.sent);
                setPData(data.received);
                setXLabels(data.times);
            }
        } 
        catch (error) {
            console.log(error)    
        }
    }

    const handleActiveMenu = (value) => {
        setActive(value);
    }

    return (
        <>
            <div className={styles.name__container}>
                <p className="mb-0">Welcome, {user ? user.user.user_ptr.name : ''}</p>
            </div>
            <div className="d-flex gap-4">
                <div className={styles.first__div}>
                    <div className="d-flex flex-column justify-content-center">
                        <div className={styles.whatsApp__container}>
                            <div className="d-flex gap-3 align-items-center">
                                <div className={styles.image__container}>
                                    <img src={whatsAppLogo}></img>
                                </div>
                                <div className={styles.number__container}>
                                    <p className="mb-0">WhatsApp Business Number</p>
                                    <p className="mb-0">{localStorage.getItem('project_type') === 'Dev' ? '+91 8595228300' : ' +91 8595661656'} <span>Live</span></p>
                                </div>
                                <div className={styles.active_status__container}>
                                    <p className="mb-0">WhatsApp Account Status</p>
                                    <p className="mb-0 text-success">Active</p>
                                </div>
                            </div>
                            
                        </div>
                        <div className={styles.whatsApp__container}>
                            <div className={styles.content__container}>
                                <h5>Get started and build powerful conversational experiences</h5>
                                <p>Make every customer touchpoint across marketing, commerce, and support conversational</p>
                            </div>

                            <div className="d-flex gap-5">
                                <div className={styles.option__list}>
                                    <div 
                                        onClick={() => handleActiveMenu('journey')}
                                        className={active === 'journey' ? styles.menu__active : styles.menu__list}
                                    >
                                        <SmartToyOutlinedIcon className={styles.menu__icon} />
                                        <p className="mb-0">Journey</p>
                                    </div>
                                    <div  
                                        onClick={() => handleActiveMenu('variables')}
                                        className={active === 'variables' ? styles.menu__active : styles.menu__list}
                                    >
                                        <TerminalIcon className={styles.menu__icon} />
                                        <p className="mb-0">Variables</p>
                                    </div>
                                    <div  
                                        onClick={() => handleActiveMenu('ai-intent')}
                                        className={active === 'ai-intent' ? styles.menu__active : styles.menu__list}
                                    >
                                        <AutoAwesomeIcon className={styles.menu__icon} />
                                        <p className="mb-0">AI Intent</p>
                                    </div>
                                </div>
                                <div className={styles.options_desc}>
                                    { active === 'journey' && (
                                        <div className={styles.active__details}>
                                            <h5>Creating a journey</h5>
                                            <p className="mb-0">Create a bot journey by construct from scratch, and subsequently publish it.</p>
                                            <Link to={`/create-journey?id=${uuidv4()}`}>
                                                <button> Create Journey </button>
                                            </Link>
                                        </div>
                                    )}
                                    { active === 'variables' && (
                                        <div className={styles.active__details}>
                                            <h5>Creating a variable</h5>
                                            <p className="mb-0">Variables are used to store data from the journey to the backend, corresponding to their respective fields.</p>
                                            <Link to={`/variables/?local=true`}>
                                                <button>Create Variable</button>
                                            </Link>
                                        </div>
                                    )}
                                    { active === 'ai-intent' && (
                                        <div className={styles.active__details}>
                                            <h5>AI Intent</h5>
                                            <p className="mb-0">Adding AI intent to determine what the user wants.</p>
                                            <Link to={`/model-list`}>
                                                <button>AI - Intent</button>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.second__div}>
                    <div className="d-flex flex-column justify-content-center">
                        <div className={styles.whatsApp__container}>
                            <LineChart
                                width={500}
                                height={300}
                                series={[
                                    { data: pData, label: 'Received' },
                                    { data: uData, label: 'Sent' },
                                ]}
                                xAxis={[{ scaleType: 'point', data: xLabels }]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const SuspenseHomePage = () => (
    <Suspense 
        fallback={ <Loading /> }
    >
        <HomePage />
    </Suspense>
);

export default SuspenseHomePage;