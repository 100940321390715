import React, { useState } from "react";
import { useRecoilState } from 'recoil';
import { nodesState, edgesState, triggersState, isFormSubmittedState, generateInitialNodesState } from '../../store/state';
import { journeyNameState, confirmationDialogState, AlertOpenState } from '../../store/journey-list'
import styles from '../../assets/scss/components/journey-name.module.scss';
import axiosInstance from "../../plugins/axios";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate, useLocation  } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import AlertDialog from '../../components/basic-components/alert-dialog';

const JourneySave = (props) => {
    const navigate = useNavigate();
    const [journeyName, setJourneyName] = useRecoilState(journeyNameState);
    const [nodes, setNodes] = useRecoilState(nodesState);
	const [edges, setEdges] = useRecoilState(edgesState);
	const [triggers, setTriggers] = useRecoilState(triggersState);
    const [isFormSubmitted, setIsFormSubmitted] = useRecoilState(isFormSubmittedState);
    const [alertOpen, setAlertOpen] = useRecoilState(AlertOpenState);
    const [loading, setLoading] = useState(false);
    const[confirm, setConfirm] = useRecoilState(confirmationDialogState);

    const location = useLocation();
    const currentPath = location.pathname;


    const handleJourneyName = (event) => {
        try {
            let obj = {
                id: props.journeyID,    
                name: event.target.value
            }
            setJourneyName(obj);
        } 
        catch (error) {
            console.error(error);
        }
    };

    const validateJourney = async (payload) => {
    
        if(payload.name === '' || payload.id === null){
            // await showToast('Journey name cannot be empty');
            setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Journey name cannot be empty' });
            return false;
        }
    
        for (const element of payload.triggers.values) {
            if (element.type === 'User Input') {
                if (!element.conditional_type) {
                    setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Conditional type cannot be empty' });
                    return false;
                } else if (element.triggering_value === '') {
                    setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Triggering value cannot be empty' });
                    return false;
                }
            } else {
                if (element.intent_name === '' || element.intent_name === null) {
                    setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Intent name cannot be empty' });
                    return false;
                }
            }
        }
    
        for (const element of payload.messages) {
            const msg_type = element.template.msg_type;
            if (element.template.message === "") {
                setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Message cannot be empty' });
                return false;
            } else if (element.template.message.length > 900) {
                setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Message should be less than 901 characters' });
                return false;
            } else if (element.template.header.length > 60) {
                setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Header should be less than 61 characters' });
                return false;
            } else if (element.template.footer.length > 60) {
                setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Footer should be less than 61 characters' });
                return false;
            } else if (msg_type === 'image' || msg_type === 'video' || msg_type === 'document') {
                if (!element.template.media_url || !element.template.content_type || !element.template.filename) {
                    setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Media cannot be empty' });
                    return false;
                }
            } else if (msg_type === 'reply_button') {
                for (const button of element.template.value.buttons) {
                    if (button.reply.title === "") {
                        setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Button title cannot be empty' });
                        return false;
                    } else if (button.reply.title.length > 20) {
                        setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Button title should be less than 21 characters' });
                        return false;
                    }
                }
    
                for (const key of Object.keys(element.storing_values)) {
                    if (element.storing_values[key]?.length > 150) {
                        setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Storing value should be less than 151 characters' });
                        return false;
                    }
                }
            } else if (msg_type === 'reply_list') {
                if (element.template.value.button === "") {
                    setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Button title cannot be empty in list' });
                    return false;
                } else if (element.template.value.button.length > 20) {
                    setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Button title should be less than 21 characters in list' });
                    return false;
                } else {
                    for (const section of element.template.value.sections) {
                        if (section.title === "") {
                            setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Section title cannot be empty in list' });
                            return false;
                        } else if (section.title.length > 24) {
                            setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Section title should be less than 25 characters in list' });
                            return false;
                        } else {
                            for (const row of section.rows) {
                                if (row.title === "") {
                                    setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Row title cannot be empty in list' });
                                    return false;
                                } else if (row.title.length > 24) {
                                    setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Row title should be less than 25 characters in list' });
                                    return false;
                                } else if (row?.description?.length > 72) {
                                    setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: 'Row description should be less than 73 characters in list' });
                                    return false;
                                }
                            }
                        }
                    }
                }
            }
        }
        return true;
    }
    


    const createAndUpdate = async (isValidJourney, payload) => {
        if (isValidJourney) {
            try {
                let res;
                if (currentPath === '/create-journey') {
                    res = await axiosInstance.post('/api/v1/communications/whatsapp/journey/create/', payload);
                } else {
                    res = await axiosInstance.put(`/api/v1/communications/whatsapp/journeys/${props.journeyID}/`, payload);
                }
                if (res.status === 200) {
                    setAlertOpen({ ...alertOpen, open: true, msgType: 'success', alertMsg: currentPath === '/create-journey' ? 'Journey created successfully' : 'Journey Updated successfully' });
                    if(currentPath === '/create-journey') {
                        setTimeout(() => {
                            const newNodesState = generateInitialNodesState();
                            setNodes(newNodesState);
                            setTriggers({
                                condition: null,
                                values: [
                                    {
                                        uuid: uuidv4(),
                                        type: "User Input",
                                        conditional_type: "Exact",
                                        triggering_value: ""
                                    },
                                ]
                            });
                            setJourneyName({id: null, name: ''});
                            navigate("/journey-list");
                            setLoading(false);
                        }, 2000);
                    }else{
                        setLoading(false);
                    }
                }
            } catch (error) {
                setAlertOpen({ ...alertOpen, open: true, msgType: 'error', alertMsg: error?.response?.data?.result ? error?.response?.data?.result : 'Something went wrong' });
                setLoading(false);
                console.error(error);
            }
        }
    }
    const savePayload = async () => {
        setIsFormSubmitted(true);
		const message = nodes.map(node => node.message_to_send);
		// Filter out elements with id "starting_node" and update the types
		const filteredMessage = message.filter(element => element.id !== "starting_node");
        
		// Iterate through filteredMessage to update type based on prev_node_id and next_node_id
		const updatedMessage = filteredMessage.map(element => {
            if (element?.prev_node_id === 'starting_node') {
                return { ...element, type: "start" };
            } else if (element.prev_node_id && !element.next_node_id) { // && element.type !== "start"
				return { ...element, type: "last" };
			}else if(element.prev_node_id && element.next_node_id){ // && element.type !== "start"
                return { ...element, type: "mid" };
            }
			return element; // Return the original object if no modification is needed
		});
	
		// Set prev_node_id and next_node_id to null if empty string
		const newUpdatedMessage = updatedMessage.map(element => {
			return {
				...element,
				prev_node_id: element.prev_node_id === "" ? null : element.prev_node_id,
				next_node_id: element.next_node_id === "" ? null : element.next_node_id
			};
		});
	
		const payload = {
			id: journeyName.id,
			name: journeyName.name,
			triggers,
			messages: newUpdatedMessage
		};
        const isValidJourney = await validateJourney(payload); 
        console.log(payload)
        await createAndUpdate(isValidJourney, payload);
	};

    const getBasePath = (url) => {
        // Regular expression to match path segments before the last one (ID or query string)
        const pathRegex = /^(?:\/[^/?]+)+\//;
      
        // Match the URL against the regular expression
        const match = pathRegex.exec(url);
        
        // Return the matched path name (or an empty string if no match)
        return match ? match[0] : '';
    }

    const switchURl = () => {
        navigate('/journey-list');
        setConfirm({ ...confirm, open: false });
    }

    const goBack = () => {
        const basePath = getBasePath(currentPath);
        if(currentPath === '/create-journey' || (basePath === '/journey-list/' && currentPath !== '/journey-list/')) {
            setConfirm({ 
                open: true,
                title: 'Are you sure ?',
                message: `Please confirm that you want to discard your changes?`,
                onConfirm: () => switchURl(),
                onCancel: () => setConfirm({ ...confirm,title: 'Are you sure ?', message: `Please confirm that you want to discard your changes?`, open: false }),
            })
        }else{
            navigate('/journey-list');
        }
    }

    const checkAnalytics = () => {
        navigate(`/journey/analytics/${journeyName.id}`)
    }
    

    return (
        <div className={styles.save__container}>
            <div className="d-flex align-items-center">
                <div className="" style={{ marginRight: '10px' }}>
                    <IconButton onClick={goBack}>
                        <ArrowBackIcon className={styles.back__icon} />
                    </IconButton>
                </div>
                <div className="flex-grow-1 ml-2">
                    <input 
                        type="text" 
                        placeholder="Journey Name"
                        value={journeyName.name} 
                        onChange={handleJourneyName} 
                        className={styles.journey__save__input}
                    />
                </div>
                {currentPath !== '/create-journey' && <div className="">
                    <button onClick={checkAnalytics} className={styles.journey__save__analytics}>Analytics</button>
                </div>}
                <div>
                    <button disabled={loading} onClick={savePayload} className={styles.journey__save__btn}>Save & Deploy</button>
                </div>
            </div>
            <AlertDialog />
        </div>
    )
}

export default JourneySave;