import React, {useEffect, useState, Suspense} from "react";
import styles from "../../assets/scss/components/model-list.module.scss";
import AIModelList from "../../components/AI-models/ai-model-list"; 
import { useRecoilState, useRecoilValue } from 'recoil';
import { modelListState, openCreateDialogBox } from '../../store/AI-intent';
import { getPaginationState, confirmationDialogState } from "../../store/journey-list";
import Loading from "../../components/basic-components/Loading";
import CreateAIModelList from "../../components/AI-models/create-model-list";
import { Search, X } from 'react-bootstrap-icons';
import { debounce } from 'lodash';
import axiosInstance from "../../plugins/axios";
import BasicPagination from '../../components/basic-components/basic-pagination';
import AlertDialog from '../../components/basic-components/alert-dialog';
import { AlertOpenState } from '../../store/journey-list';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const ModelList = () => {
    const [list, setList] = useRecoilState(modelListState);
    const [openDialog, setOpenDialog] = useRecoilState(openCreateDialogBox);
    const [loading, setLoading] = useState(false);
    const [search, setSearchText] = useState('');
    const [pagination, setPagination] = useRecoilState(getPaginationState);
    const [confirm, setConfirm] = useRecoilState(confirmationDialogState);
    const [values, setValues] = useRecoilState(AlertOpenState);

    const navigate = useNavigate();

    const query = new URLSearchParams(location.search);
    const page = parseInt(query.get('page') || '1', 10);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = Cookies.get('Token');
                if(!token){
                    localStorage.removeItem('Token');
                    window.location.href = '/login'
                }
                else{
                    const {data} = await axiosInstance.get(`/api/v1/communications/whatsapp/ai-intents/list/?limit=20&offset=${(page - 1) * 20}`);
                    setPagination({
                        ...pagination,
                        count: data.count,
                        onPageChange: (event, value) => onPageChange(event, value),
                        defaultPage: page,
                        page: page,
                        path: '/model-list/?'
                    })
                    setList(data.results);
                }
            } 
            catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const onPageChange = async (event, value) => {
        try{
            setSearchText('')
            const res = await axiosInstance.get(`/api/v1/communications/whatsapp/ai-intents/list/?limit=20&offset=${(value - 1) * 20}`);
            setPagination({
                ...pagination,
                page: value,
                count: res.data.count,
                path: '/model-list/?',
                onPageChange: (event, value) => onPageChange(event, value)
            })
            setList(res.data.results);
        }catch(error){
            console.log(error)
        }
    }

    const handleCreateNew = () => {
        setOpenDialog(true);
    }

    const handleSearchDebounce = debounce( async(e) => {
        try {
            navigate('/model-list/?page=1');
            const { data } = await axiosInstance.get(`/api/v1/communications/whatsapp/ai-intents/list/?name=${e.target.value}`)
            setPagination({
                ...pagination,
                count: data.count,
                onPageChange: (event, value) => onPageChange(event, value),
                defaultPage: 1,
                page: 1,
                path: `/model-list/`
            })
            setList(data.results);
            setLoading(false);
        } 
        catch (error) {
            setLoading(false);
            console.log(error)
        }
    }, 300)

    const handleResetSearch = async () => {
        try {
            setSearchText('')
            setLoading(true);
            try {
                const {data} = await axiosInstance.get(`/api/v1/communications/whatsapp/ai-intents/list/?limit=20&offset=${(page - 1) * 20}`);
                setPagination({
                    ...pagination,
                    count: data.count,
                    onPageChange: (event, value) => onPageChange(event, value),
                    defaultPage: page,
                    page: page,
                    path: '/model-list/?'
                })
                setList(data.results);
            } 
            catch (error) {
                setLoading(false);
                console.error('Error fetching data:', error);
            }
            setLoading(false);
        } 
        catch (error) {
            setLoading(false);
            console.log(error)
        }
    };

    const handleSearch = (e) => {
        try {
            setLoading(true);
            setSearchText(e.target.value);
            if(e.target.value === '') {
                handleResetSearch();
            }else{
                handleSearchDebounce(e)
            }
        } 
        catch (error) {
            console.log(error)
        }
    };

    const confirmTrainData = () => {
        setConfirm({
            open: true,
            title: 'Retrain All AI-Intents',
            message: `Are you sure you want to retrain all AI-Intent?`,
            onConfirm: () => handleTrainModel(),
            onCancel: () => setConfirm({ ...confirm, title: 'Retrain All AI-Intents', message: `Are you sure you want to retrain all AI-Intent?`, open: false }),
        });
    }

    const handleTrainModel = async () => {
        try {
            const res = await axiosInstance.get('api/v1/communications/whatsapp/retrain-intent-model/')
            if(res.status === 200 || res.status === 201){
                setValues({ ...values, open: true, msgType: 'success', alertMsg: 'Retrain data successfully.' })
                setConfirm({ ...confirm, title: 'Retrain All AI-Intents', message: `Are you sure you want to retrain all AI-Intent?`, open: false })
            }    
        } 
        catch (error) {
            console.log(error)
            setValues({ ...values, open: true, msgType: 'error', alertMsg: 'Something went wrong' })
            setConfirm({ ...confirm, title: 'Retrain All AI-Intents', message: `Are you sure you want to retrain all AI-Intent?`, open: false })
        }
    }

    return (
        <div className={styles.model__list__container}>
            <div className="d-flex gap-2 align-items-center">
                <h4 className="flex-grow-1">Intent Detection</h4>
                <button className={styles.add__btn} onClick={confirmTrainData}>Train Intent</button>
                <button className={styles.add__btn} onClick={handleCreateNew}>Add New Intent</button>
            </div>
            <div className="d-flex">
                <div className="flex-grow-1 flex-end mt-2">
                    <div className={styles.modal_list_search}>
                        <div>
                            <input 
                                type="text" 
                                placeholder="Search Variables" 
                                onChange={handleSearch}
                                value={search}
                            />
                        </div>
                        <div className="cursor-pointer">
                            {
                                search.length > 0 &&
                                <X size={30} onClick={handleResetSearch} />
                            }
                        </div>
                        {search.length === 0 && <div>
                            <Search color="#5956d6" />
                        </div>}
                    </div>
                </div>
            </div>

            <div className="mt-4">
                {
                    !loading ? <AIModelList list={list} /> : <>Loading...</>
                }
                
            </div>
            <BasicPagination />
        </div>
    )
};

const SuspenseModelList = () => {
    return (
        <Suspense 
            fallback={ <Loading /> }
        >
            <ModelList />
            <CreateAIModelList />
            <AlertDialog />
        </Suspense>
    );
}

export default SuspenseModelList;
