import { debounce } from 'lodash';
import { recoilState } from 'recoil'; // Import Recoil state functions

// Exported debounced handler function
export const debouncedHandleText = (findIndex, nodes, setNodes, recoilSetter) => debounce((value) => {
    console.log(findIndex)
    console.log(nodes)
    console.log(value)
    if (findIndex !== -1) {
        const updatedNodes = [...nodes];
        updatedNodes[findIndex] = {
            ...updatedNodes[findIndex],
            message_to_send: {
                ...updatedNodes[findIndex].message_to_send,
                template: {
                    ...updatedNodes[findIndex].message_to_send.template,
                    message: value,
                }
            }
        };
        setNodes(updatedNodes);
        // Update Recoil state if provided
        if (recoilSetter) {
            recoilSetter(updatedNodes);
        }
    }
}, 300); // Adjust the debounce delay as needed


export const handleVariableListDebounce = (findIndex, nodes, setNodes) => debounce((value) => {
    const updatedNodes = [...nodes];
    updatedNodes[findIndex] = {
        ...updatedNodes[findIndex],
        message_to_send: {
            ...updatedNodes[findIndex].message_to_send,
            store_response_in: value
        }
    };
    setNodes(updatedNodes);
}, 300); // Adjust the debounce delay as needed


// Create a debounced version of the handleHeader function
export const debouncedHandleHeader = (findIndex, nodes, setNodes) => debounce((value) => {
    if (findIndex !== -1) {
        const updatedNodes = [...nodes];
        updatedNodes[findIndex] = {
            ...updatedNodes[findIndex],
            message_to_send: {
                ...updatedNodes[findIndex].message_to_send,
                template: {
                    ...updatedNodes[findIndex].message_to_send.template,
                    header: value
                }
            }
        };
        setNodes(updatedNodes);
    }
}, 300); // Adjust the debounce delay as needed

export const debouncedHandleFooter = (findIndex, nodes, setNodes) => debounce((value) => {
    if (findIndex !== -1) {
        const updatedNodes = [...nodes];
        updatedNodes[findIndex] = {
            ...updatedNodes[findIndex],
            message_to_send: {
                ...updatedNodes[findIndex].message_to_send,
                template: {
                    ...updatedNodes[findIndex].message_to_send.template,
                    footer: value
                }
            }
        };
        setNodes(updatedNodes);
    }
}, 300); // Adjust the debounce delay as needed


export const handleDeleteDebounced = (nodes, setNodes, edges, setEdges, nodeId) => debounce(() => {
    // Find the current node
    const currentNode = nodes.find((node) => node.id === nodeId);

    if (!currentNode) {
        // If the node does not exist, return early
        return;
    }

    // Extract the IDs of the previous and next nodes
    const prevNodeId = currentNode?.message_to_send?.prev_node_id;
    const nextNodeIds = currentNode?.message_to_send?.next_node_id;

    // Check if previous node is fallback
    const isFallback = (nodes.find((node) => node.id === prevNodeId)?.message_to_send?.fallback_node_id === nodeId);
    
    let updatedNodes = [...nodes];
    // Update the previous node's next_node_id reference
    updatedNodes = nodes.map((node) => {
        if (node.id === prevNodeId) {
            // Remove the current node ID from the previous node's next_node_id object
            return {
                ...node,
                message_to_send: {
                    ...node.message_to_send,
                    next_node_id: typeof node.message_to_send.next_node_id === 'object'
                        ? Object.entries(node.message_to_send.next_node_id)
                            .reduce((obj, [key, value]) => {
                                if (value !== nodeId) {
                                    obj[key] = value;
                                }
                                return obj;
                            }, {})
                        : ''
                }
            };
        }
        return node;
    });

    if (!isFallback) {
        // Update the next node(s) prev_node_id reference
        updatedNodes = updatedNodes.map((node) => {
            if (nextNodeIds &&nextNodeIds.includes(node.id)) {
                // Remove the current node's ID from the next node's prev_node_id reference
                return {
                    ...node,
                    message_to_send: {
                        ...node.message_to_send,
                        prev_node_id: '',
                    },
                };
            }
            return node;
        });
    } else {
        updatedNodes = updatedNodes.map((node) => {
            return {
                ...node,
                message_to_send: {
                    ...node.message_to_send,
                    fallback_node_id: null,
                },
            };
        })
    }

    // Remove the current node
    updatedNodes = updatedNodes.filter((node) => node.id !== nodeId);

    // Remove edges connected to the current node
    const updatedEdges = edges.filter(
        (edge) => edge.source !== nodeId && edge.target !== nodeId
    );
    // Update the state with the modified nodes and edges
    console.log(updatedNodes);
    setNodes(updatedNodes);
    setEdges(updatedEdges);
}, 300);
