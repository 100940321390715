import React, { useRef, useEffect, useState } from "react";
import axiosInstance from "./../../plugins/axios";
import { useRecoilState } from 'recoil';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { editTypesState, nodesState } from "../../store/state";
import { variableListState } from '../../store/journey-list';
import styles from  '../../assets/css/editbar.module.css';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { debounce } from 'lodash';
import TextArea from "../ui-components/text-area";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { Trash3 } from 'react-bootstrap-icons'
import { debouncedHandleText, handleVariableListDebounce } from '../../mixins/journey'
import { checkMessageError, checkHeaderAndFooterError} from '../../mixins/errors'

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const ErrorText = styled('p')`
  color: red;
  font-size: 0.75rem;
  margin-bottom: 0px;
`;

const UploadEdit = () => {
    const [nodes, setNodes] = useRecoilState(nodesState);
    const [editTypes, setEditTypes] = useRecoilState(editTypesState);
    const inputRef = useRef(null);
    const [openToast, setOpenToast] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');
    const [initialValue, setInitialValue] = useState('');
    const [initialVariable, setInitialVariable] = useState(null);
    const [variableList, setVariableList] = useRecoilState(variableListState);
    const [tostTiming, setTostTiming] = useState(3000);
    const [toastType, setToastType] = useState('success');
    const [loading, setLoading] = useState(false);

    const selectedNode = nodes.find(node => node.id === editTypes.selected_id);
    const selectedType = editTypes.selected_type;
    const selectedValue = selectedNode?.message_to_send?.template?.media_url;
    const findIndex = nodes.findIndex(item => item.id === editTypes.selected_id);

    useEffect(() => {
        if (selectedNode !== -1) {
            setInitialValue(nodes[findIndex].message_to_send.template.message);
            setInitialVariable(nodes[findIndex].message_to_send.store_response_in);
        }
    }, [editTypes.selected_id]);

    const handleToastClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
    };

    const uploadMedia = async (file) => {
        try {
            const formData = new FormData();
            formData.set('uuid', editTypes.selected_id);
            formData.set('file', file);
            formData.set('mime_type', file.type);
            const response = await axiosInstance.post('/api/v1/communications/whatsapp/journey/upload-media/', formData);
            console.log(response?.data?.url)
            return {
                url: response?.data?.url,
            }
        } catch (error) {
            console.error('Error uploading media:', error);
        }
    };

    const showToast = (showToast, errMsg, tostTiming, toastType) =>{
        setErrMsg(errMsg);
        setTostTiming(tostTiming);
        setToastType(toastType);
        setOpenToast(showToast);
    }
    
    const onChangeHandle = async (e) => {
        try{

            setLoading(true); // Set loading to true when handling file change
            setOpenToast(false); // Reset toast state
            const file = e.target.files[0];
            if (file) {
                if (file.name.length >= 50) {
                    showToast(true, 'File name exceeds 50 characters.', 5000, 'error');
                    setLoading(false); // Set loading to false on error
                    return;
                }
                if (selectedType === 'image') {
                    if (file.type.startsWith('image/') && (file.type === 'image/jpeg' || file.type === 'image/png')) {
                        if (file.size <= 5 * 1024 * 1024) {
                            showToast(true, 'Uploading file please wait...', 6000, 'info');
                            const reader = new FileReader();
                            reader.onload = async () => {
                                const { url } = await uploadMedia(file);
                                const updatedNodes = nodes.map(node => {
                                    if (node.id === editTypes.selected_id) {
                                        return {
                                            ...node,
                                            message_to_send: {
                                                ...node.message_to_send,
                                                template: {
                                                    ...node.message_to_send.template,
                                                    media_url: url,
                                                    content_type: file.type,
                                                    filename: file.name
                                                }
                                            }
                                        };
                                    }
                                    return node;
                                });
                                setNodes(updatedNodes);
                                setOpenToast(false); // Close toast when upload completes
                                setLoading(false); // Set loading to false after upload
                            };
                            reader.readAsDataURL(file);
                            e.target.value = '';
                        } else {
                            showToast(true, 'File size exceeds 5 MB.', 3000, 'error');
                            setLoading(false); // Set loading to false on error
                        }
                    } else {
                        showToast(true, 'Please select an image file (JPEG, PNG).', 3000, 'error');
                        setLoading(false); // Set loading to false on error
                    }
                } else if (selectedType === 'video' || selectedType === 'document') {
                    const maxSize = selectedType === 'video' ? 10 * 1024 * 1024 : 10 * 1024 * 1024;
                    if (file.size <= maxSize) {
                        showToast(true, `Uploading ${selectedType} file please wait...`, 6000, 'info');
                        const { url } = await uploadMedia(file);
                        const updatedNodes = nodes.map(node => {
                            if (node.id === editTypes.selected_id) {
                                return {
                                    ...node,
                                    message_to_send: {
                                        ...node.message_to_send,
                                        template: {
                                            ...node.message_to_send.template,
                                            media_url: url,
                                            content_type: file.type,
                                            filename: file.name
                                        }
                                    }
                                };
                            }
                            return node;
                        });
                        setNodes(updatedNodes);
                        setOpenToast(false); // Close toast when upload completes
                        setLoading(false); // Set loading to false after upload
                    } else {
                        showToast(true, `${selectedType} file size exceeds 10 MB.`, 3000, 'error');
                        setLoading(false); // Set loading to false on error
                    }
                }
            }
            console.log(nodes);
        }catch (error) {
            console.error(error);
            showToast(true, 'Error occurred while handling file.', 3000, 'error');
            setLoading(false);
        }
    };
    

    const handleDeleteImage = debounce (() => {
        const updatedNodes = nodes.map(node => {
            if (node.id === editTypes.selected_id) {
                return {
                    ...node,
                    message_to_send: {
                        ...node.message_to_send,
                        template: {
                            ...node.message_to_send.template,
                            media_url: '',
                            content_type: '',
                            filename: ''
                        }
                    }
                };
            }
            return node;
        });
        setNodes(updatedNodes);
    }, 300)


    const onHandleText = (e) => {
        const { value } = e.target;
        setInitialValue(value);
        debouncedHandleText(findIndex, nodes, setNodes)(value); 
    };


    const handleVariableList = (e) => {
        const { value } = e.target;
        const item = variableList.find(item => item.name === value)
        setInitialVariable(item);
        handleVariableListDebounce(findIndex, nodes, setNodes)(item);
    }

    const handleAddVariable = (newValue) => {
        setInitialValue(newValue);
    };


return (
    <div>  
        <div className="">
            <InputLabel id="select-event" style={{marginTop: '10px', fontWeight: 'bold', fontSize: '14px', marginBottom: '4px', marginLeft: '4px'}}>MESSAGE</InputLabel>
            <TextArea
                id="text-basic"
                onChange={onHandleText}
                label="Edit Text"
                minRows={3}
                placeholder="What's on your mind"
                variant="outlined" 
                value={initialValue}
                autoComplete="off"
                error={checkMessageError(initialValue)}
                errorMessage={checkMessageError(initialValue)}
                onAddVariable={handleAddVariable}
                showVariable={true}
            />
        </div>
        {!selectedValue && (
            <div className="d-flex justify-content-end">
                <Button 
                    component="label" 
                    variant="contained"
                    disabled={loading}
                >
                    Upload file
                    <VisuallyHiddenInput 
                        type="file" 
                        accept={selectedType === "image" ? "image/*" : selectedType === "video" ? "video/*" : "*/*"} 
                        onChange={onChangeHandle} 
                        ref={inputRef} 
                    />
                </Button>
            </div>
        )}
        {(editTypes && (editTypes.selected_type === 'image' || editTypes.selected_type === 'video' || editTypes.selected_type === 'document')) && (
            <>
                {selectedType === 'image' && selectedValue && (
                    <>
                        <div className="mt-2">
                            <img 
                                src={selectedValue} 
                                alt="Uploaded" 
                                style={{ maxWidth: '300px' }} 
                            />
                        </div>
                        <div className="mt-3 d-flex justify-content-end">
                            <button onClick={handleDeleteImage} className={styles.delete__image}>Delete Image</button>
                        </div>
                    </>
                )}

                {selectedType === 'video' && selectedValue && (
                    <>
                        <div className="mt-2">
                            <video 
                                controls 
                                style={{ maxWidth: '300px' }}  
                            >
                                <source src={selectedValue} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        <div className="mt-3 d-flex justify-content-end">
                            <button onClick={handleDeleteImage} className={styles.delete__image}>Delete Video</button>
                        </div>
                    </>
                )}

                {selectedType === 'document' && selectedValue && (
                    <>
                        <div className="mt-2">
                            <iframe 
                                src={selectedValue} 
                                style={{ maxWidth: '300px' }} 
                            />
                        </div>
                        <div className="mt-3 d-flex justify-content-end">
                            <button onClick={handleDeleteImage} className={styles.delete__image}>Delete Document</button>
                        </div>
                    </>
                )}
            </>
        )}

        <FormControl fullWidth size="small" className="mt-4">
            <InputLabel id="select-variable">Store Response In</InputLabel>
            <Select
                labelId="select-variable"
                id="select-variable"
                value={initialVariable ? initialVariable.name : ''}
                label="Store Response In"
                onChange={handleVariableList}
            >   
            <MenuItem value={null}>--Store Response In--</MenuItem>
            {variableList.map((item, index) => <MenuItem className={item.is_global ? 'global_response' : ''}  key={index} value={item.name}>{item.name}</MenuItem>)}
            </Select>
            <small className="mt-1">NOTE: Underlined values are global variables.</small>
        </FormControl>

        <Snackbar open={openToast} autoHideDuration={tostTiming} onClose={handleToastClose}>
            <MuiAlert onClose={handleToastClose} severity={toastType} sx={{ width: '100%' }}>
                {errMsg}
            </MuiAlert>
        </Snackbar>
    </div>
);
    
};

export default UploadEdit;
