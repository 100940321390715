import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import { useRecoilState } from 'recoil';
import {
editBarOpenState,
editTypesState,
nodesState,
edgesState,
} from '../../store/state';
import styles from '../../assets/css/upload.module.css';
import { Upload, Trash3 } from 'react-bootstrap-icons';
import { handleDeleteDebounced } from '../../mixins/journey'

function UploadFile({ data, isConnectable, props, nodeId }) {
const [showDeleteButton, setShowDeleteButton] = useState(false);
const [editBarOpen, setEditBarOpen] = useRecoilState(editBarOpenState);
const [editTypes, setEditTypes] = useRecoilState(editTypesState);
const [nodes, setNodes] = useRecoilState(nodesState);
const [edges, setEdges] = useRecoilState(edgesState);

let uploadText = '';
if (props) {
	// setFileType(props.type);
	if (props.type === 'image') {
	uploadText = 'Image';
	} else if (props.type === 'video') {
	uploadText = 'Video';
	} else if (props.type === 'document') {
	uploadText = 'Document';
	}
}

const handleMouseEnter = () => {
	setShowDeleteButton(true);
};

const handleMouseLeave = () => {
	setShowDeleteButton(false);
};

	const handleDelete = (event) => {
		event.stopPropagation();
		handleDeleteDebounced(nodes, setNodes, edges, setEdges, nodeId)();
	};

const handleClickOpenEditbar = () => {
	setEditBarOpen(!editBarOpen);
	setEditTypes({ selected_id: nodeId, selected_type: props.type });
};

const selectedNode = nodes.find((node) => node.id === nodeId);
const selectedType = props.type;
const selectedValue = selectedNode?.message_to_send?.template?.media_url;
const selectedMessage = selectedNode?.message_to_send?.template?.message;

return (
	<>
	<div
		className={styles.imageNode}
		onMouseEnter={handleMouseEnter}
		onMouseLeave={handleMouseLeave}
		onClick={handleClickOpenEditbar}
	>
		<Handle
		type='target'
		position={Position.Left}
		isConnectable={isConnectable}
		/>
		<div>
		<div className='heading__div'>
			<h1 className='heading-text'>{uploadText}</h1>
		</div>
		<div className={styles.content__div}>
			<div className={styles.imageContentBox}>
			{selectedValue ? (
				selectedType === 'image' ? (
				<img
					src={selectedValue}
					alt='Uploaded'
					className={styles.uploadedImage}
				/>
				) : selectedType === 'video' ? (
				<video
					src={selectedValue}
					className={styles.uploadedImage}
					controls
				/>
				) : null
			) : (
				<div className='text-center'>
				{props.type === 'image' ? (
					<>
					<Upload color='#4b4b4b' size={28} className='' />
					<p className={styles.textUpload}>Upload {uploadText}</p>
					<small className={styles.textUploadSize}>Max 5MB</small>
					</>
				) : props.type === 'video' ? (
					<>
					<Upload color='#4b4b4b' size={28} className='' />
					<p className={styles.textUpload}>Upload {uploadText}</p>
					<small className={styles.textUploadSize}>Max 10MB</small>
					</>
				) : props.type === 'document' ? (
					<>
					<Upload color='#4b4b4b' size={28} className='' />
					<p className={styles.textUpload}>Upload {uploadText}</p>
					<small className={styles.textUploadSize}>Max 10MB</small>
					</>
				) : null}
				</div>
			)}
			</div>
			<div className="pt-2">{selectedMessage}</div>
		</div>
		</div>
		<Handle
		type='source'
		position={Position.Right}
		id='b'
		isConnectable={isConnectable}
		style={{
			backgroundColor: '#5956d6',
			border: '1px solid #5956d6',
			padding: '5px',
			position: 'absolute',
			right: '-8px',
			top: '50%',
			transform: 'translateY(-50%)',
			boxShadow: '0 0 0 0.25rem #c5cad9',
			zIndex: '1',
		}}
		/>
		<Handle
			id='fallback'
			type='source'
			position={Position.Right}
			style={{
				backgroundColor: '#ff3964',
				border: '1px solid #ff3964',
				padding: '5px',
				position: 'absolute',
				right: '-6px',
				top: '90%',
				transform: 'translateY(-50%)',
				boxShadow: '0 0 0 0.25rem #c5cad9',
			}}
		/>
		{showDeleteButton && (
		<div
			className='deleteButton'
			onClick={handleDelete}
			onMouseEnter={() => setShowDeleteButton(true)}
			onMouseLeave={() => setShowDeleteButton(false)}
		>
			<Trash3 color='#8c8ace' size={20} className='' />
		</div>
		)}
	</div>
	</>
);
}

export default UploadFile;
