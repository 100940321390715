import React from 'react'
// import { openCreateTemplateBoxState } from '../../store/template';
import Card from '@mui/material/Card';
import styles from '../../assets/scss/page/optins-page.module.scss';
import { useNavigate } from 'react-router-dom';

const OptInList = ({item, index}) => {
    const navigate = useNavigate();
  	const query = new URLSearchParams(location.search);
    const page = parseInt(query.get('page') || '1', 10);
	
	const handleOpenOptIns = () => {
        navigate(`/opt-ins/${item.id}`);
	}
	return (
		<Card variant="outlined" className={styles.optins__card} onClick={handleOpenOptIns}>
			<div className="">{((page-1)*20)+index+1}. {item.name}</div>
		</Card>
	)
}

export default OptInList;
