import {React, useState, useEffect} from 'react'
import styles from '../../assets/scss/components/optins-status.module.scss';
import { Whatsapp } from 'react-bootstrap-icons'
import AdsClickIcon from '@mui/icons-material/AdsClick';
import { useTheme } from '@mui/material/styles';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PendingIcon from '@mui/icons-material/Pending';
import axiosInstance from '../../plugins/axios';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';

const BroadcastStatus = ({item}) => {
    const [showDownloadResponsesButton, setDownloadResponsesButton] = useState(false);
    const [response_available_time,setResponseAvailableTime] = useState('');
    const theme = useTheme();

    useEffect(() => {
        const checkTimeDifference = () => {
            const startDate = new Date(item.start_date);
            const currentDate = new Date();
            const timeDifference = currentDate - startDate;
            const timeDifferenceInHours = timeDifference / (1000 * 60 * 60);
            if (timeDifferenceInHours > 3) {
                setDownloadResponsesButton(true);
            }
            startDate.setHours(startDate.getHours() + 3);
            const formattedDate = `${startDate.toLocaleDateString('en-US', { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' })}, 
            ${startDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}`;
            setResponseAvailableTime(formattedDate);
        };
        checkTimeDifference();
      }, [item.start_date]);

    const downloadFile = () => {
        const link = document.createElement('a');
        link.href = item.file;
        link.download = ''; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    
    const downloadCallbckStatus = async () => {
        try {
            axiosInstance.get(`api/v1/communications/whatsapp/campaigns/wa-callbckstatus/${item.id}/`)
            .then(response => {
                const url = URL.createObjectURL(new Blob([response.data], {
                    type: 'text/csv'
                }))
                const link = document.createElement('a')
                link.href = url
                const formattedDateTime = new Date().toLocaleString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true }).replace(',', '').replace(' ', '_');
                link.setAttribute('download', `${item.name}-${formattedDateTime}`)
                document.body.appendChild(link)
                link.click()
            })
        } catch (error) {
            console.log(error);
        }
    };
    
    return (
        <>
            <div className={styles.optins__grid}>
                <div className={styles.optins__source}>
                    Whatsapp <Whatsapp className='' color='#4bcb59' />
                </div>
                <div className={styles.broadcast__status}>
                    <div className='' style={{borderRight: '1px solid #e5e7eb', paddingRight: '1rem'}}>
                        {/* <div className={styles.optins__layout}>
                        </div> */}
                        <div className="status_card mt-2">
                            <div className='d-flex p-2 justify-content-between align-items-center'>
                                <div className={styles.optins__number}>{item.target}</div>
                                <div className={styles.optins__icon}>
                                    <AdsClickIcon  style={{ color: theme.palette.primary.dark, fontSize: '2.5rem', backgroundColor: theme.palette.primary.extraLight, borderRadius: '50%', padding: '4px'}} />
                                </div>
                            </div>
                            <div className={styles.optins__title}>
                                Total Targeted Numbers
                            </div>
                        </div>
                        <div className="status_card mt-2">
                            {/* Start Date: <br />
                            <small>{item.start_date}</small> */}
                            <div className='d-flex p-2 justify-content-between align-items-center'>
                                <div className={styles.optins__text}>BROADCAST START DATETIME:</div>
                                <div className={styles.optins__icon}>
                                    <CalendarMonthIcon  style={{ color: theme.palette.primary.dark, fontSize: '2.5rem', backgroundColor: theme.palette.primary.extraLight, borderRadius: '50%', padding: '4px'}} />
                                </div>
                            </div>
                            <div className={styles.optins__title}>
                                {moment(item.start_date).format('ddd, DD-MMM-YYYY, hh:mm A')}
                                {/* {item.start_date} */}
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div className="status_card mt-2">
                            <div>
                                <div className='d-flex p-2 justify-content-between align-items-center'>
                                    {/* <div className={styles.optins__text}>{item?.current_broadcast_status}</div> */}
                                    <div className={styles.optins__text}>Not Available</div>
                                    <div className={styles.optins__icon}>
                                        <CancelIcon  style={{ color: '#ff0000', fontSize: '2.5rem', backgroundColor: '#ffe8e8', borderRadius: '50%', padding: '4px'}} />
                                        {/* {
                                            item?.current_broadcast_status === 'SUCCESS' ? <CheckCircleIcon  style={{ color: '#4bcb59', fontSize: '2.5rem', backgroundColor: '#e8f8e8', borderRadius: '50%', padding: '4px'}} /> : item?.current_broadcast_status === 'PENDING' ? <PendingIcon  style={{ color: '#ff9900', fontSize: '2.5rem', backgroundColor: '#ffffe8', borderRadius: '50%', padding: '4px'}} /> :
                                            <CheckCircleIcon  style={{ color: '#ff0000', fontSize: '2.5rem', backgroundColor: '#ffe8e8', borderRadius: '50%', padding: '4px'}} />
                                        } */}
                                    </div>
                                </div>
                                <div className={styles.optins__title}>
                                    Current Broadcast Status
                                </div>
                            </div>
                        </div>
                        <div className="status_card mt-2">
                            <div>
                                <div className='d-flex p-2 justify-content-between align-items-center'>
                                    <div className={styles.optins__number}>{item?.data?.sent}</div>
                                    <div className={styles.optins__icon}>
                                        <DoneIcon style={{ color: '#495057', fontSize: '2.5rem', backgroundColor: '#dee2e6', borderRadius: '50%', padding: '4px'}} />
                                    </div>
                                </div>
                                <div className={styles.optins__title}>
                                    Sent Messages
                                    <Tooltip 
                                        title="Message is sent to WhatsApp server successfully (equivalent of single grey tick on WhatsApp)" 
                                        arrow 
                                        placement="top"
                                    >
                                        <InfoOutlinedIcon className={styles.option__status__info} />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div className="status_card mt-2">
                            <div>
                                <div className='d-flex p-2 justify-content-between align-items-center'>
                                    <div className={styles.optins__number}>{item?.data?.pending}</div>
                                    <div className={styles.optins__icon}>
                                        <PendingActionsOutlinedIcon  style={{ color: '#ff9900', fontSize: '2.5rem', backgroundColor: '#ffffe8', borderRadius: '50%', padding: '4px'}} />
                                    </div>
                                </div>
                                <div className={styles.optins__title}>
                                    Pending Messages
                                    <Tooltip 
                                        title="Message is currently in the process of being sent but has not yet reached the WhatsApp server (equivalent of no ticks on WhatsApp)" 
                                        arrow 
                                        placement="top"
                                    >
                                        <InfoOutlinedIcon className={styles.option__status__info} />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div className="status_card mt-2">
                            <div>
                                <div className='d-flex p-2 justify-content-between align-items-center'>
                                    <div className={styles.optins__number}>{item?.data?.success}</div>
                                    <div className={styles.optins__icon}>
                                        <DoneAllIcon  style={{ color: '#495057', fontSize: '2.5rem', backgroundColor: '#dee2e6', borderRadius: '50%', padding: '4px'}} />
                                    </div>
                                </div>
                                <div className={styles.optins__title}>
                                    Success/ Delivered Messages 
                                    <Tooltip 
                                        title="Message is delivered to the user on WhatsApp (equivalent of two grey ticks on WhatsApp)" 
                                        arrow 
                                        placement="top"
                                    >
                                        <InfoOutlinedIcon className={styles.option__status__info} />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div className="status_card mt-2">
                            <div>
                                <div className='d-flex p-2 justify-content-between align-items-center'>
                                    <div className={styles.optins__number}>{item?.data?.failed}</div>
                                    <div className={styles.optins__icon}>
                                        <SmsFailedIcon  style={{ color: '#ff0000', fontSize: '2.5rem', backgroundColor: '#ffe8e8', borderRadius: '50%', padding: '4px'}} />
                                    </div>
                                </div>
                                <div className={styles.optins__title}>
                                    Failed Messages
                                    <Tooltip 
                                        title="Message could not be delivered or sent due to an error (equivalent of a red exclamation mark or a failed delivery status on WhatsApp)" 
                                        arrow 
                                        placement="top"
                                    >
                                        <InfoOutlinedIcon className={styles.option__status__info} />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div className="status_card mt-2">
                            <div>
                                <div className='d-flex p-2 justify-content-between align-items-center'>
                                    <div className={styles.optins__number}>{item?.data?.read}</div>
                                    <div className={styles.optins__icon}>
                                        <DoneAllIcon style={{ color: '#228be6', fontSize: '2.5rem', backgroundColor: '#d0ebff', borderRadius: '50%', padding: '4px'}} />
                                    </div>
                                </div>
                                <div className={styles.optins__title}>
                                    Read Messages
                                    <Tooltip 
                                        title="Message is read by the user on WhatsApp (equivalent of two blue ticks on WhatsApp)" 
                                        arrow 
                                        placement="top"
                                    >
                                        <InfoOutlinedIcon className={styles.option__status__info} />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.broadcast__status}>
                    <div className="status_card download_card mt-2">
                        <div className='d-flex p-2 justify-content-between align-items-center cursor-pointer' onClick={downloadFile}>
                            <div style={{color: '#3f3f3f', fontWeight: '600'}}>DOWNLOAD</div>
                            <CloudDownloadIcon style={{ color: theme.palette.primary.dark, fontSize: '2.5rem', backgroundColor: theme.palette.primary.extraLight, borderRadius: '50%', padding: '6px'}} />
                        </div>
                        <div className={styles.optins__title}>
                            Uploaded/ Attached File
                        </div>
                    </div>

                    {/* This Button should only show after 3 hours from the start date */}
                    <div className={`status_card mt-2 ${showDownloadResponsesButton ?'download_card' : 'download_card_disabled'}`}>
                        <div className={`d-flex p-2 justify-content-between align-items-center ${showDownloadResponsesButton ? 'cursor-pointer':''}`} 
                            style={{opacity: showDownloadResponsesButton ? '':'0.6'}} 
                            onClick={showDownloadResponsesButton ? downloadCallbckStatus : undefined}
                            >
                            <div style={{color: '#3f3f3f', fontWeight: '600'}}>DOWNLOAD RESPONSES</div>
                            <CloudDownloadIcon style={{ color: theme.palette.primary.dark, fontSize: '2.5rem', backgroundColor: theme.palette.primary.extraLight, borderRadius: '50%', padding: '6px'}} />
                        </div>
                        <div className={`${styles.optins__title} ms-2`} >
                            { showDownloadResponsesButton ? 'Broadcast Summary Sheet' : `Will be available at ${response_available_time}`}
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default BroadcastStatus
