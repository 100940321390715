import React, { useState } from 'react';
import styles from '../../assets/css/loading.module.css';

const Loading = () => {
    return (
        <div className={styles.loading__component}>
        {/* {isLoading && (
            <div className={styles.loading__overlay}>
            <div className={styles.spinner}></div>
            </div>
        )} */}
            <div className={styles.loading__overlay}>
                <div className={styles.spinner}></div>
            </div>
        </div>
    );
};

export default Loading;