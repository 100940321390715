import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import styles from "../../assets/scss/components/menubar.module.scss"
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Link, useNavigate } from 'react-router-dom';
import { confirmationDialogState } from '../../store/journey-list';
import { useRecoilState } from 'recoil';
import ConfirmModel from '../../components/basic-components/confirmation-dialog';
import Logout from '../../components/basic-components/logout';
import logo from '../../assets/images/logo.webp';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CampaignIcon from '@mui/icons-material/Campaign';
import HomeIcon from '@mui/icons-material/Home';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import {broadcastIdState} from '../../store/agent-chats'
// import { WindowSidebar } from 'react-bootstrap-icons'
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';

export default function MiniDrawer() {
    const [open, setOpen] = React.useState(true);
    const [broadcastId, setBroadcastId] = useRecoilState(broadcastIdState);
    const [botSubMenuOpen, setBotSubMenuOpen] = React.useState(false);
    const [AIsubMenuOpen, setAIsubMenuOpen] = React.useState(false);
    const [campaignOpen, setCampaignOpen] = React.useState(false);
    const [AgentChatsMenu, setAgentChatsMenu] = React.useState(false);
    const navigate = useNavigate();
    const[confirm, setConfirm] = useRecoilState(confirmationDialogState);
    const currentUrl = window.location.pathname;
    
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setBotSubMenuOpen(false);
        setAIsubMenuOpen(false);
        setAgentChatsMenu(false);
        setCampaignOpen(false);
    };

    const openBotSubMenu = () => {
        setBotSubMenuOpen(!botSubMenuOpen);
        setAIsubMenuOpen(false);
        setAgentChatsMenu(false);
        setCampaignOpen(false);
        setOpen(true);
    };

    const openAIsubMenu = () => {
        setAIsubMenuOpen(!AIsubMenuOpen);
        setBotSubMenuOpen(false);
        setAgentChatsMenu(false);
        setCampaignOpen(false);
        setOpen(true);
    }

    const openAgentChatsMenu = () => {
        setAgentChatsMenu(!AgentChatsMenu);
        setBotSubMenuOpen(false);
        setAIsubMenuOpen(false);
        setCampaignOpen(false);
        setOpen(true);
    }

    const openCampaign = () => {
        setCampaignOpen(!campaignOpen);
        setBotSubMenuOpen(false);
        setAIsubMenuOpen(false);
        setAgentChatsMenu(false);
        setOpen(true);
    }

    const switchURl = (url) => {
        navigate(`/${url}`);
        setConfirm({ ...confirm, open: false });
    }

    const getBasePath = (url) => {
        // Regular expression to match path segments before the last one (ID or query string)
        const pathRegex = /^(?:\/[^/?]+)+\//;
      
        // Match the URL against the regular expression
        const match = pathRegex.exec(url);
        
        // Return the matched path name (or an empty string if no match)
        return match ? match[0] : '';
    }

    const getBaseUrl = (url) => {
        const pathRegex = /^(?:\/[^/?]+)/;
        const match = pathRegex.exec(url);
        return match ? match[0] : '';
    }
    
    const redirectSubMenu = async (url) => {
        const basePath = getBasePath(currentUrl);
        if(currentUrl === '/create-journey' || (basePath === '/journey-list/' && currentUrl !== '/journey-list/')) {
            setConfirm({ 
                open: true,
                title: 'Are you sure ?',
                message: `Please confirm that you want to discard your changes?`,
                onConfirm: () => switchURl(url),
                onCancel: () => setConfirm({ ...confirm,title: 'Are you sure ?', message: `Please confirm that you want to discard your changes?`, open: false }),
            })
        }else if(currentUrl === '/agent-chats'){
            setBroadcastId(null);
            navigate(`/${url}`)
        }else{
            navigate(`/${url}`);
        }
    };

    // const handleProjectType = (e) => {
    //     localStorage.setItem('project_type', e.target.value);
    //     setProjectType(e.target.value);
    //     window.location.reload();
    // }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader className='d-flex justify-content-between my-3 mx-2'>
                        { open && 
                            <>
                                <Link to='/' className={styles.home__link}>
                                    <div className="d-flex flex-column align-items-center">
                                        <img src={logo} alt="logo" className={styles.logo} style={{ 'width': '150px'}} /> 
                                        <span className='ml-2 '>Engage ({localStorage.getItem('project_type')})</span>
                                    </div>
                                </Link>
                            </>
                        }
                        <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
                            {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    {/* {
                        open && (
                            <div className='d-flex justify-content-center'>
                                <Select
                                    size='small'
                                    id="select-project"
                                    value={projectType}
                                    onChange={handleProjectType}
                                    className={styles.select__tag__customize}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value="Dev">Dev</MenuItem>
                                    <MenuItem value="Prod">Production</MenuItem>
                                </Select>
                            </div>
                        )
                    } */}
                    {/* <div className={styles.menubar__list__item}>
                        <CampaignOutlinedIcon />
                        <p className='mb-0'>Campaign</p>
                    </div> */}
                    <div 
                        style={{ gap: '2.2rem', marginTop: '1rem'}}
                        className={currentUrl === '/' ? styles.active_link + ' ' + styles.menubar__list__item : styles.menubar__list__item}
                        onClick={() => redirectSubMenu('')}
                    >
                        <HomeIcon />
                        <p className='mb-0'>Home</p>
                    </div>
                    <div className={styles.menubar__list__item} style={open ? {justifyContent: 'space-between'} : { justifyContent: 'center' }} onClick={openBotSubMenu}>
                        <SmartToyOutlinedIcon />
                        {open && <p className='mb-0'>Bot Studio</p>}
                        {open && <span>
                            {!botSubMenuOpen ? <ChevronRightIcon /> : <KeyboardArrowDownRoundedIcon />}
                        </span>}
                    </div>
                    <div>
                        { botSubMenuOpen && open && (
                            <div className={styles.sub__menu}>
                                <div className={getBaseUrl(currentUrl) === '/journey-list' || getBaseUrl(currentUrl) === '/create-journey' ? styles.active_link + ' ' + styles.menubar__list__item : styles.menubar__list__item} onClick={() => redirectSubMenu('journey-list')}>
                                <p className='mb-0'>- Journeys</p>
                                </div>
                                <div className={getBaseUrl(currentUrl) === '/variables' ? styles.active_link + ' ' + styles.menubar__list__item : styles.menubar__list__item} onClick={() => redirectSubMenu('variables/?local=true')}>
                                    <p className='mb-0'>- Variables</p>
                                </div>
                                <div className={getBaseUrl(currentUrl) === '/user-guide' ? styles.active_link + ' ' + styles.menubar__list__item : styles.menubar__list__item} onClick={() => redirectSubMenu('user-guide')}>
                                    <p className='mb-0'>- Guide</p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles.menubar__list__item} onClick={openAIsubMenu} style={open ? {justifyContent: 'space-between'} : { justifyContent: 'center' }}>
                        <AutoAwesomeIcon />
                        {open && <p className='mb-0'>AI Models</p>}
                        {open && <span>
                            {!AIsubMenuOpen ? <ChevronRightIcon /> : <KeyboardArrowDownRoundedIcon />}
                        </span>}
                    </div>
                    <div>
                        {AIsubMenuOpen && open && (
                            <div className={styles.sub__menu}>
                                <div className={getBaseUrl(currentUrl) === '/model-list' ? styles.active_link + ' ' + styles.menubar__list__item : styles.menubar__list__item} onClick={() => redirectSubMenu('model-list')}>
                                <p className='mb-0'>- Intent Detection</p>
                                </div>
                                <div className={getBaseUrl(currentUrl) === '/ai-intent-guide' ? styles.active_link + ' ' + styles.menubar__list__item : styles.menubar__list__item} onClick={() => redirectSubMenu('ai-intent-guide')}>
                                    <p className='mb-0'>- Guide</p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles.menubar__list__item} onClick={openCampaign} style={open ? {justifyContent: 'space-between'} : { justifyContent: 'center' }}>
                        <CampaignIcon />
                        {open && <p className='mb-0'>Campaigns</p>}
                        {open && <span>
                            {!campaignOpen ? <ChevronRightIcon /> : <KeyboardArrowDownRoundedIcon />}
                        </span>}
                    </div>
                    <div>
                        {campaignOpen && open && (
                            <>
                                <div className={styles.sub__menu}>
                                    <div className={getBaseUrl(currentUrl) === '/templates' ? styles.active_link + ' ' + styles.menubar__list__item : styles.menubar__list__item} onClick={() => redirectSubMenu('templates')}>
                                    <p className='mb-0'>- Templates</p>
                                    </div>
                                </div>
                                <div className={styles.sub__menu}>
                                    <div className={getBaseUrl(currentUrl) === '/opt-ins' ? styles.active_link + ' ' + styles.menubar__list__item : styles.menubar__list__item} onClick={() => redirectSubMenu('opt-ins')}>
                                    <p className='mb-0'>- Opt-Ins</p>
                                    </div>
                                </div>
                                <div className={styles.sub__menu}>
                                    <div className={getBaseUrl(currentUrl) === '/broadcasts' ? styles.active_link + ' ' + styles.menubar__list__item : styles.menubar__list__item} onClick={() => redirectSubMenu('broadcasts')}>
                                    <p className='mb-0'>- Broadcasts</p>
                                    </div>
                                </div>
                                <div className={styles.sub__menu}>
                                    <div className={getBaseUrl(currentUrl) === '/campaign-guide' ? styles.active_link + ' ' + styles.menubar__list__item : styles.menubar__list__item} onClick={() => redirectSubMenu('campaign-guide')}>
                                    <p className='mb-0'>- Guide</p>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className={styles.menubar__list__item} onClick={openAgentChatsMenu} style={open ? {justifyContent: 'space-between'} : { justifyContent: 'center' }}>
                        <SupportAgentRoundedIcon />
                        {open && <p className='mb-0'>Agent Assist</p>}
                        {open && <span>
                            {!AgentChatsMenu ? <ChevronRightIcon /> : <KeyboardArrowDownRoundedIcon />}
                        </span>}
                    </div>
                    <div>
                        {AgentChatsMenu && open && (
                            <div className={styles.sub__menu}>
                                <div className={getBaseUrl(currentUrl) === '/agent-chats' ? styles.active_link + ' ' + styles.menubar__list__item : styles.menubar__list__item} onClick={() => redirectSubMenu('agent-chats')}>
                                <p className='mb-0'>- Chats</p>
                                </div>
                            </div>
                        )}
                    </div>
                    {
                        open && ( <Logout /> )
                    }
                </Drawer>
            </Box>
            <ConfirmModel />
        </>
    );
}

const drawerWidth = 240;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        padding: '10px',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
            '& .MuiDrawer-paperAnchorDockedLeft': { boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)'}
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
            '& .MuiDrawer-paperAnchorDockedLeft': { boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)'}
        }),
    }),
);
