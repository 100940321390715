import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { openCreateDialogBox } from '../../store/AI-intent';
import { useRecoilState } from 'recoil';
import styles from '../../assets/scss/components/model-list.module.scss'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import axiosInstance from '../../plugins/axios';

const CreateAIModelList = () => {
    const [openDialog, setOpenDialog] = useRecoilState(openCreateDialogBox);
    const [modelName, setName] = useState('');
    const [description, setDescription] = useState('');

    const handleClose = () => {
        setOpenDialog(false)
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            console.log(modelName, description);

            const payload = {
                name: modelName,
                descriptions: [
                    {
                        "description": description
                    }
                ]
            }

            const response = await axiosInstance.post('api/v1/communications/whatsapp/ai-intents/create/', payload);
            if (response.status === 201 || response.status === 200) {
                setOpenDialog(false);   
                window.location.reload();
            }

            
        } 
        catch (error) {
            console.log(error)
        }        
    }

    const isFormValid = () => {
        return modelName.trim() !== '' && description.trim() !== '';
    };

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={openDialog}
                onClose={handleClose}
            >
                <div className='d-flex justify-content-end align-items-center px-2 pt-2'>
                    <CloseOutlinedIcon 
                        className={styles.close__btn} 
                        onClick={handleClose}
                    /> 
                </div>
                <div className={styles.dialog__container}>
                    <h4>Add New Intent</h4>
                    <div>
                        <form>
                            <div className="mb-3">
                                <label htmlFor="model-name" className={styles.form__label}>Intent Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="model-name" 
                                    placeholder="Intent name"
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <div className="form-text">Note: Intent name can not be changed, make sure to use correct name.</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="description" className={styles.form__label}>Description</label>
                                <textarea 
                                    className="form-control" 
                                    id="description" 
                                    rows="3"
                                    placeholder='Description to train the model to identify this intent. (Multiple descriptions can be added to create new intent only one is needed)'
                                    onChange={(e) => setDescription(e.target.value)}
                                ></textarea>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <button 
                                    type="submit" 
                                    className={ !isFormValid() ? styles.disabled_submit__btn : styles.submit__btn} 
                                    onClick={handleSubmit}
                                    disabled={!isFormValid()}
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>      
            </Dialog>
        </>
    )
}

export default CreateAIModelList;