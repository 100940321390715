import React, { useEffect, useState } from "react";
import { useRecoilState } from 'recoil';
import Accordion, { AccordionSlots } from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ChevronDown,Trash3, Plus } from 'react-bootstrap-icons'
import styles from  '../../assets/css/list-edit.module.css';
import TextArea from "../ui-components/text-area";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { ListTask } from 'react-bootstrap-icons';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DataObjectIcon from '@mui/icons-material/DataObject';
import { styled } from '@mui/system';
import { nodesState, editTypesState, isFormSubmittedState } from "../../store/state";
import { variableListState } from '../../store/journey-list';
import { debounce } from 'lodash'; // Import debounce from lodash
import { v4 as uuidv4 } from 'uuid';
import DeleteIcon from '@mui/icons-material/Delete';
import { debouncedHandleText, handleVariableListDebounce, debouncedHandleHeader, debouncedHandleFooter } from '../../mixins/journey'
import { checkMessageError, checkHeaderAndFooterError, checkButtonTitleError, checkSectionTitleError, checkRowTitleError, checkDescriptionError, checkVariableError } from '../../mixins/errors'


const ErrorText = styled('p')`
  color: red;
  font-size: 0.75rem;
  margin-bottom: 0px;
`;

const ListEdit = () => {
    const [nodes, setNodes] = useRecoilState(nodesState);
    const [editTypes, setEditTypes] = useRecoilState(editTypesState);
    const [initialValue, setInitialValue] = useState('');
    const [initialButtonName, setInitialButtonName] = useState('');
    const [initialButtonSections, setInitialButtonSections] = useState([]);
    const [initialVariable, setInitialVariable] = useState(null);
    const [variableList, setVariableList] = useRecoilState(variableListState);
    const [isFormSubmitted, setIsFormSubmitted] = useRecoilState(isFormSubmittedState);
    const [isHeader, setIsHeader] = useState(false);
    const [initialHeaderValue, setInitialHeaderValue] = useState('');
    const [isFooter, setIsFooter] = useState(false);
    const [initialFooterValue, setInitialFooterValue] = useState('');
    const [storingValues, setStoringValues] = useState(null);
    const [isButtonBlurred, setIsButtonBlurred] = useState(false);
    
    const listButtonSectionList = nodes.find((node) => node.id === editTypes.selected_id).message_to_send.template.value?.sections || [];
    const [sectionTitleBlurStatus, setSectionTitleBlurStatus] = useState(Array(listButtonSectionList.length).fill(false));
    const [rowTitleBlurStatus, setRowTitleBlurStatus] = useState(Array(listButtonSectionList.length).fill([]));
    const [descriptionBlurStatus, setDescriptionBlurStatus] = useState(Array(listButtonSectionList.length).fill([]));
    
    const findIndex = nodes.findIndex(item => item.id === editTypes.selected_id);

    useEffect(() => {
        if (findIndex !== -1) {
            setInitialValue(nodes[findIndex].message_to_send.template.message);
            setInitialButtonName(nodes[findIndex].message_to_send.template.value.button);
            setInitialButtonSections(nodes[findIndex].message_to_send.template.value.sections || []);
            setInitialVariable(nodes[findIndex].message_to_send.store_response_in);
            setInitialHeaderValue(nodes[findIndex].message_to_send.template.header);
            setIsHeader(nodes[findIndex].message_to_send.template.header !== '');
            setIsFooter(nodes[findIndex].message_to_send.template.footer !== '');
            setInitialFooterValue(nodes[findIndex].message_to_send.template.footer);
            setStoringValues(nodes[findIndex].message_to_send.storing_values);
        }
    }, [editTypes.selected_id]);

    const onChangeListTitle = (e) => {
        const { value } = e.target;
        setInitialValue(value);
        debouncedHandleText(findIndex, nodes, setNodes)(value); 
    }

    const debouncedHandleButtonName = debounce((value) => {
        if (findIndex !== -1) {
            const updatedNodes = [...nodes];
            updatedNodes[findIndex] = {
                ...updatedNodes[findIndex],
                message_to_send: {
                    ...updatedNodes[findIndex].message_to_send,
                    template: {
                        ...updatedNodes[findIndex].message_to_send.template,
                        value:{
                            ...updatedNodes[findIndex].message_to_send.template.value,
                            button: value
                        }
                    }
                }
            };
            setNodes(updatedNodes);
        }
    }, 300);

    const setButtonTitle = (e) => {
        const { value } = e.target;
        setInitialButtonName(value);
        debouncedHandleButtonName(value);
    }

    const handleAddSectionDebounced = debounce((id) => {
        if (findIndex !== -1) {
            const updatedNodes = [...nodes];
            const currentTemplate = updatedNodes[findIndex].message_to_send.template;
            // Create a new section object with initial values
            const newSection = {
                title: '', // Initial title for the new section
                rows: [{
                    id, // Generate unique ID for the new row
                    title: '', // Initial title for the new row
                }] // Initialize rows array for the new section
            };
    
            updatedNodes[findIndex] = {
                ...updatedNodes[findIndex],
                message_to_send: {
                    ...updatedNodes[findIndex].message_to_send,
                    template: {
                        ...currentTemplate,
                        value: {
                            ...currentTemplate.value,
                            sections: [
                                ...currentTemplate.value.sections, // Add existing sections
                                newSection // Add the new section
                            ]
                        }
                    },
                    storing_values: {
                        ...updatedNodes[findIndex].message_to_send.storing_values,
                        [id]: null
                    }
                }
            };
            // console.log(updatedNodes);
            setNodes(updatedNodes);
        }
    }, 300); // Adjust debounce delay as needed
    

    const handleAddSection = () => {
        //set initial value
        const id = uuidv4();
        setInitialButtonSections(prevState => [...prevState, {
            title: '', // Initial title for the new section
            rows: [{
                id, // Generate unique ID for the new row
                title: '', // Initial title for the new row
            }] // Initialize rows array for the new section
        }])
        setStoringValues(prevState => ({ ...prevState, [id]: null }));
        handleAddSectionDebounced(id);
    };
    

    const handleAddRow = debounce((sectionIndex) => {
        const id = uuidv4();
        setStoringValues(prevState => ({ ...prevState, [id]: null }));
        if (findIndex !== -1) {
            const updatedNodes = [...nodes];
            const currentTemplate = updatedNodes[findIndex].message_to_send.template;
        
            updatedNodes[findIndex] = {
                ...updatedNodes[findIndex],
                message_to_send: {
                    ...updatedNodes[findIndex].message_to_send,
                    template: {
                        ...currentTemplate,
                        value: {
                            ...currentTemplate.value,
                            sections: currentTemplate.value.sections.map((section, index) => {
                                if (index === sectionIndex) {
                                    // Update the section if the index matches
                                    return {
                                        ...section,
                                        rows: [
                                            ...section.rows,
                                            {
                                                id,
                                                title: '',
                                            }
                                        ]
                                    };
                                }
                                return section; // Otherwise, return the section unchanged
                            })
                        }
                    },
                    storing_values: {
                        ...updatedNodes[findIndex].message_to_send.storing_values,
                        [id]: null
                    }
                }
            };
            setNodes(updatedNodes);
        }        
    }, 300);
    
    

    const handleDeleteRow = debounce((sectionIndex, rowIndex, id) => {
        if (findIndex !== -1) {
            const updatedNodes = [...nodes];
            const currentTemplate = updatedNodes[findIndex].message_to_send.template;
    
            updatedNodes[findIndex] = {
                ...updatedNodes[findIndex],
                message_to_send: {
                    ...updatedNodes[findIndex].message_to_send,
                    template: {
                        ...currentTemplate,
                        value: {
                            ...currentTemplate.value,
                            sections: currentTemplate.value.sections.map((section, index) => {
                                if (index === sectionIndex) {
                                    // Update the section if the index matches
                                    return {
                                        ...section,
                                        rows: section.rows.filter((row, rowIndexToDelete) => rowIndexToDelete !== rowIndex)
                                    };
                                }
                                return section; // Otherwise, return the section unchanged
                            })
                        }
                    },
                    storing_values: Object.keys(updatedNodes[findIndex].message_to_send.storing_values)
                    .filter(key => key !== id)
                    .reduce((obj, key) => {
                        obj[key] = updatedNodes[findIndex].message_to_send.storing_values[key];
                        return obj;
                    }, {})
                }
            };
            // console.log(updatedNodes);
            setNodes(updatedNodes);
        }
    },300);
    
    const handleDeleteSectionDebounced = debounce((sectionIndex) => {
        if (findIndex !== -1) {
            const updatedNodes = [...nodes];
            const currentSections = updatedNodes[findIndex].message_to_send.template.value.sections;
            const getAllSectionRowId = currentSections[sectionIndex].rows.map(row => row.id);
            console.log(getAllSectionRowId);
            updatedNodes[findIndex] = {
                ...updatedNodes[findIndex],
                message_to_send: {
                    ...updatedNodes[findIndex].message_to_send,
                    template: {
                        ...updatedNodes[findIndex].message_to_send.template,
                        value: {
                            ...updatedNodes[findIndex].message_to_send.template.value,
                            sections: currentSections.filter((section, index) => index !== sectionIndex)
                        }
                    },
                    storing_values: Object.keys(updatedNodes[findIndex].message_to_send.storing_values)
                    .filter(key => !getAllSectionRowId.includes(key)) // Filter out keys in getAllSectionRowId
                    .reduce((obj, key) => {
                        obj[key] = updatedNodes[findIndex].message_to_send.storing_values[key];
                        return obj;
                    }, {})
                }
            };
            setNodes(updatedNodes);
        }
    }, 300); // Adjust debounce delay as needed

    const handleDeleteSection = (sectionIndex) => {
        setInitialButtonSections(prevState => prevState.filter((section, index) => index !== sectionIndex));
        handleDeleteSectionDebounced(sectionIndex);
    };

    const debounceHandleSectionTitleChange = debounce((sectionIndex, value) => {
        // Update the section title in the nodes array

        if (findIndex !== -1) {
            const updatedNodes = [...nodes];
            updatedNodes[findIndex] = {
                ...updatedNodes[findIndex],
                message_to_send: {
                    ...updatedNodes[findIndex].message_to_send,
                    template: {
                        ...updatedNodes[findIndex].message_to_send.template,
                        value: {
                            ...updatedNodes[findIndex].message_to_send.template.value,
                            sections: updatedNodes[findIndex].message_to_send.template.value.sections.map((section, index) => {
                                if (index === sectionIndex) {
                                    return { ...section, title: value };
                                }
                                return section;
                            })
                        }
                    }
                }
            };
            setNodes(updatedNodes);
        }
    }, 300);
    
    const handleSectionTitleChange = (sectionIndex, e) => {
        const { value } = e.target;
        setInitialButtonSections(prevState => {
            const updatedSections = [...prevState]; // Create a copy of the initialButtonSections array
            updatedSections[sectionIndex] = { ...updatedSections[sectionIndex], title: value }; // Update the section_name at the specified index
            return updatedSections; // Return the updated array
        });
        debounceHandleSectionTitleChange(sectionIndex, value);
    }

    const debounceHandleRowTitleChange = debounce((sectionIndex, rowIndex, value) => {
        if(findIndex !== -1) {
            const updatedNodes = [...nodes];
            updatedNodes[findIndex] = {
                ...updatedNodes[findIndex],
                message_to_send: {
                    ...updatedNodes[findIndex].message_to_send,
                    template: {
                        ...updatedNodes[findIndex].message_to_send.template,
                        value: {
                            ...updatedNodes[findIndex].message_to_send.template.value,
                            sections: updatedNodes[findIndex].message_to_send.template.value.sections.map((section, index) => {
                                if (index === sectionIndex) {
                                    return {
                                        ...section,
                                        rows: section.rows.map((row, index) => {
                                            if (index === rowIndex) {
                                                return { ...row, title: value };
                                            }
                                            return row;
                                        })
                                    };
                                }
                                return section;
                            })
                        }
                    }
                }
            };

            setNodes(updatedNodes);
        }
    }, 300)

    const handleRowTitleChange = (sectionIndex, rowIndex, e) => {
        const { value } = e.target;
        setInitialButtonSections(prevState => {
            const updatedSections = [...prevState];
            const updatedSection = { ...updatedSections[sectionIndex] };
            const updatedSectionList = [...updatedSection.rows];
            updatedSectionList[rowIndex] = { ...updatedSectionList[rowIndex], title: value };
            updatedSection.rows = updatedSectionList;
            updatedSections[sectionIndex] = updatedSection;
            return updatedSections;
        });
        debounceHandleRowTitleChange(sectionIndex, rowIndex, value); // Call the debounced event handler with the input value
    };

    const handleVariableList = (e) => {
        const { value } = e.target;
        const item = variableList.find(item => item.name === value)
        setInitialVariable(item);
        handleVariableListDebounce(findIndex, nodes, setNodes)(item);
    }

    const handleAddDescriptionDebounced = debounce((sectionIndex, rowIndex) => {
        if(findIndex !== -1) {
            const updatedNodes = [...nodes];
            updatedNodes[findIndex] = {
                ...updatedNodes[findIndex],
                message_to_send: {
                    ...updatedNodes[findIndex].message_to_send,
                    template: {
                        ...updatedNodes[findIndex].message_to_send.template,
                        value: {
                            ...updatedNodes[findIndex].message_to_send.template.value,
                            sections: updatedNodes[findIndex].message_to_send.template.value.sections.map((section, index) => {
                                if (index === sectionIndex) {
                                    return {
                                        ...section,
                                        rows: section.rows.map((row, index) => {
                                            if (index === rowIndex) {
                                                return {
                                                    ...row,
                                                    description: ''
                                                };
                                            }
                                            return row;
                                        })
                                    };
                                }
                                return section;
                            })
                        }
                    }
                }
            };
            setNodes(updatedNodes);
        }
    })

    const handleAddDescription = (sectionIndex, rowIndex) => {
        handleAddDescriptionDebounced(sectionIndex, rowIndex);
    }

    const onHandleTextDescriptionDebounced = debounce((value, sectionIndex, rowIndex) => {
        if(findIndex !== -1) {
            const updatedNodes = [...nodes];
            updatedNodes[findIndex] = {
                ...updatedNodes[findIndex],
                message_to_send: {
                    ...updatedNodes[findIndex].message_to_send,
                    template: {
                        ...updatedNodes[findIndex].message_to_send.template,
                        value: {
                            ...updatedNodes[findIndex].message_to_send.template.value,
                            sections: updatedNodes[findIndex].message_to_send.template.value.sections.map((section, index) => {
                                if (index === sectionIndex) {
                                    return {
                                        ...section,
                                        rows: section.rows.map((row, index) => {
                                            if (index === rowIndex) {
                                                return {
                                                    ...row,
                                                    description: value
                                                };
                                            }
                                            return row;
                                        })
                                    };
                                }
                                return section;
                            })
                        }
                    }
                }
            };
            setNodes(updatedNodes);
        }

    }, 300)

    const onHandleTextDescription = (e, sectionIndex, rowIndex) => {
        const { value } = e.target;
        setInitialButtonSections(prevState => {
            const updatedSections = [...prevState];
            const updatedSection = { ...updatedSections[sectionIndex] };
            const updatedSectionList = [...updatedSection.rows];
            updatedSectionList[rowIndex] = { ...updatedSectionList[rowIndex], description: value };
            updatedSection.rows = updatedSectionList;
            updatedSections[sectionIndex] = updatedSection;
            return updatedSections;
        })
        onHandleTextDescriptionDebounced(value, sectionIndex, rowIndex);
    }

    const handleDeleteDescription = debounce((sectionIndex, rowIndex) => {
        if(findIndex !== -1) {
            const updatedNodes = [...nodes];
            updatedNodes[findIndex] = {
                ...updatedNodes[findIndex],
                message_to_send: {
                    ...updatedNodes[findIndex].message_to_send,
                    template: {
                        ...updatedNodes[findIndex].message_to_send.template,
                        value: {
                            ...updatedNodes[findIndex].message_to_send.template.value,
                            sections: updatedNodes[findIndex].message_to_send.template.value.sections.map((section, index) => {
                                if (index === sectionIndex) {
                                    return {
                                        ...section,
                                        rows: section.rows.map((row, index) => {
                                            if (index === rowIndex) {
                                                const { description, ...rowWithoutDescription } = row;
                                                return rowWithoutDescription;
                                            }
                                            return row;
                                        })
                                    };
                                }                                
                                return section;
                            })
                        }
                    }
                }
            };
            setNodes(updatedNodes);
        }
    },300)


    const openHeader = () => {
        setIsHeader(!isHeader);
        if(isHeader) {
            setInitialHeaderValue('');
            const updatedNodes = [...nodes];
            updatedNodes[findIndex] = {
                ...updatedNodes[findIndex],
                message_to_send: {
                    ...updatedNodes[findIndex].message_to_send,
                    template: {
                        ...updatedNodes[findIndex].message_to_send.template,
                        header: ''
                    }
                }
            };
            setNodes(updatedNodes);
        }
    }

    const handleChangeHeader = (e) => {
        const { value } = e.target;
        setInitialHeaderValue(value);
        debouncedHandleHeader(findIndex, nodes, setNodes)(value);
    }

    const openFooter = () => {
        setIsFooter(!isFooter);
        if(isFooter) {
            setInitialFooterValue('');
            const updatedNodes = [...nodes];
            updatedNodes[findIndex] = {
                ...updatedNodes[findIndex],
                message_to_send: {
                    ...updatedNodes[findIndex].message_to_send,
                    template: {
                        ...updatedNodes[findIndex].message_to_send.template,
                        footer: ''
                    }
                }
            }
            setNodes(updatedNodes);
        }
    }

    const handleChangeFooter = (e) => {
        const { value } = e.target;
        setInitialFooterValue(value);
        debouncedHandleFooter(findIndex, nodes, setNodes)(value);
    }

    const openVariable = (id) => {
        const updatedStoringValues = {...storingValues};
        if(!storingValues[id] && storingValues[id] === '') {
            updatedStoringValues[id] = null;
        } else {
            updatedStoringValues[id] = '';
        }
        console.log(updatedStoringValues)
        setStoringValues(updatedStoringValues);
    }

    const handleDeleteVariableDebounce = debounce((id) => {
        const updatedNodes = [...nodes];
        updatedNodes[findIndex] = {
            ...updatedNodes[findIndex],
            message_to_send: {
                ...updatedNodes[findIndex].message_to_send,
                storing_values: {
                    ...updatedNodes[findIndex].message_to_send.storing_values,
                    [id]: null
                }
            }
        };
        setNodes(updatedNodes);
    }, 300);

    const handleDeleteVariable = (id) => {
        setStoringValues({...storingValues, [id]: null});
        handleDeleteVariableDebounce(id);
    }

    const handleVariableDebounce = debounce((value, id) => {
        const updatedNodes = [...nodes];
        updatedNodes[findIndex] = {
            ...updatedNodes[findIndex],
            message_to_send: {
                ...updatedNodes[findIndex].message_to_send,
                storing_values: {
                    ...updatedNodes[findIndex].message_to_send.storing_values,
                    [id]: value // Use square brackets for computed property name
                }
            }
        };


        console.log(updatedNodes);
        setNodes(updatedNodes);
    }, 300);

    const handleVariableChange = (e, id) => {
        const { value } = e.target;
        const updatedStoringValues = {...storingValues};
        updatedStoringValues[id] = value;
        setStoringValues(updatedStoringValues);
        handleVariableDebounce(value, id);
    }

    // Function to handle button blur
    const handleButtonBlur = () => {
        setIsButtonBlurred(true);
    };

    const handleSectionTitleBlur = (index) => {
        const updatedBlurStatus = [...sectionTitleBlurStatus];
        updatedBlurStatus[index] = true;
        setSectionTitleBlurStatus(updatedBlurStatus);
    };

    const handleRowTitleBlur = (sectionIndex, rowIndex) => {
        const updatedRowTitleBlurStatus = [...rowTitleBlurStatus];
        if (!updatedRowTitleBlurStatus[sectionIndex]) {
            updatedRowTitleBlurStatus[sectionIndex] = [];
        }
        updatedRowTitleBlurStatus[sectionIndex][rowIndex] = true;
        setRowTitleBlurStatus(updatedRowTitleBlurStatus);
    };
    
    const handleDescriptionBlur = (sectionIndex, rowIndex) => {
        const updatedDescriptionBlurStatus = [...descriptionBlurStatus];
        if (!updatedDescriptionBlurStatus[sectionIndex]) {
            updatedDescriptionBlurStatus[sectionIndex] = [];
        }
        updatedDescriptionBlurStatus[sectionIndex][rowIndex] = true;
        setDescriptionBlurStatus(updatedDescriptionBlurStatus);
    }

    const handleAddVariable = (newValue) => {
        setInitialValue(newValue);
    };


    return (
        <div className={styles.list_edit}>
            {
                !isHeader ? 
                <div className="d-flex justify-content-start mb-3">
                    <Button variant="outlined" size="small" color="primary" onClick={openHeader}>+ Header</Button>
                </div>
                : 
                <>
                    <FormControl size="small" className="mx-0 mb-3" fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="header">Header</InputLabel>
                        <OutlinedInput
                            endAdornment={<InputAdornment position="end">
                                <Trash3 className="cursor-pointer" onClick={openHeader} />
                            </InputAdornment>}
                            id="header"
                            label="Header"
                            value={initialHeaderValue} 
                            onChange={(e) => handleChangeHeader(e)} 
                            error={checkHeaderAndFooterError('Header',initialHeaderValue)}
                        />
                        <ErrorText error>{checkHeaderAndFooterError('Header',initialHeaderValue)}</ErrorText>
                    </FormControl>
                </>
            }
            <Accordion className="mb-3" defaultExpanded>
                <AccordionSummary
                expandIcon={<ChevronDown />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                <div className={styles.list_edit_title}>Message</div>
                </AccordionSummary>
                <AccordionDetails>
                <div>
                    <div className="mx-0">
                        <TextArea
                            showVariable={true}
                            onChange={onChangeListTitle}
                            label="Message"
                            minRows={3}
                            placeholder="What's on your mind"
                            variant="outlined" 
                            value={initialValue}
                            autoComplete="off"
                            error={checkMessageError(initialValue)}
                            errorMessage={checkMessageError(initialValue)}
                            onAddVariable={handleAddVariable}
                        />
                    </div>
                    <div className="">
                    <FormControl className="mx-0" fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="outlined-button">Button Title</InputLabel>
                        <OutlinedInput
                            onChange={setButtonTitle}
                            id="outlined-button"
                            startAdornment={
                                <InputAdornment position="start">
                                    <ListTask size={22} />
                                </InputAdornment>
                            }
                            label="Button Title"
                            value={initialButtonName}
                            autoComplete="off"
                            error={checkButtonTitleError(initialButtonName) && (isButtonBlurred || isFormSubmitted)}
                            onBlur={handleButtonBlur} // Call handleButtonBlur function on blur event
                        />
                        {/* Display error message if blurred and there's an error */}
                        {checkButtonTitleError(initialButtonName) && (isButtonBlurred || isFormSubmitted) && <ErrorText error>{checkButtonTitleError(initialButtonName)}</ErrorText>}
                    </FormControl>

                    </div>
                </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ChevronDown />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <div className={styles.list_edit_title}>List View</div>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <Card>
                            <CardContent>
                                {listButtonSectionList.map((section, index) => (
                                    <React.Fragment key={index}>
                                        <FormControl size="small" className="mx-0" fullWidth sx={{ m: 1 }}>
                                            <InputLabel htmlFor={`outlined-section-${index}`}>Section Title</InputLabel>
                                            <OutlinedInput
                                                endAdornment={
                                                    index !== 0 && ( // Check if index is not 0
                                                        <InputAdornment position="end">
                                                            <Trash3 className="cursor-pointer" onClick={() => handleDeleteSection(index)} />
                                                        </InputAdornment>
                                                    )
                                                }
                                                id={`outlined-section-${index}`}
                                                label="Section Title"
                                                value={initialButtonSections[index]?.title || ''} 
                                                onChange={(e) => handleSectionTitleChange(index, e)} 
                                                error={(sectionTitleBlurStatus[index] || isFormSubmitted) && checkSectionTitleError(initialButtonSections[index]?.title || '')}
                                                onBlur={() => handleSectionTitleBlur(index)}
                                            />
                                            {(sectionTitleBlurStatus[index] || isFormSubmitted) && checkSectionTitleError(initialButtonSections[index]?.title || '') && <ErrorText error>{checkSectionTitleError(initialButtonSections[index]?.title || '')}</ErrorText>}
                                        </FormControl>
                                        <hr />
                                        {section.rows.map((row, rowIndex) => (
                                            <div key={`${section.id}-${row.id}`} className="">
                                                <FormControl size="small" className="mx-0 mb-0" fullWidth sx={{ m: 1 }}>
                                                    <InputLabel htmlFor={`outlined-row-${index}-${rowIndex}`}>
                                                        Button Value
                                                    </InputLabel>
                                                    <OutlinedInput
                                                        endAdornment={
                                                            <>  
                                                                <InputAdornment position="end">
                                                                    <Tooltip title="Corresponding Value" className="cursor-pointer ">
                                                                        <IconButton className="primary_icon cursor-pointer" onClick={() => openVariable(row.id)}>
                                                                            <DataObjectIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </InputAdornment>
                                                                {rowIndex !== 0 && ( // Check if index is not 0
                                                                    <InputAdornment position="end">
                                                                        <Tooltip title="Delete" className="">
                                                                            <IconButton className="trash_icon cursor-pointer" onClick={() => handleDeleteRow(index, rowIndex, row.id)}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </InputAdornment>
                                                                )}
                                                            </>
                                                        }
                                                        id={`outlined-row-${index}-${rowIndex}`}
                                                        label="Button Value"
                                                        value={(initialButtonSections[index]?.rows[rowIndex]?.title) || ''} // Add parentheses for clarity
                                                        onChange={(e) => handleRowTitleChange(index, rowIndex, e)}
                                                        error={(rowTitleBlurStatus[index]?.[rowIndex] || isFormSubmitted) && checkRowTitleError(initialButtonSections[index]?.rows[rowIndex]?.title || '')}
                                                        onBlur={() => handleRowTitleBlur(index, rowIndex)}
                                                    />
                                                    {(rowTitleBlurStatus[index]?.[rowIndex] || isFormSubmitted) && checkRowTitleError(initialButtonSections[index]?.rows[rowIndex]?.title || '') && <ErrorText>{checkRowTitleError(initialButtonSections[index]?.rows[rowIndex]?.title || '')}</ErrorText>}
                                                </FormControl>
                                                {storingValues && storingValues[row.id] !== null && (
                                                    <div className="">
                                                        <FormControl size="small" className="mx-0 mt-3" fullWidth sx={{ m: 1 }}>
                                                            <InputLabel htmlFor={row.id+'-variable'}>Corresponding Value</InputLabel>
                                                            <OutlinedInput
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <Tooltip title="Delete" className="">
                                                                            <IconButton className="trash_icon cursor-pointer" onClick={() => handleDeleteVariable(row.id)}>
                                                                                    <DeleteIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </InputAdornment>
                                                                }
                                                                id={row.id+'-variable'}
                                                                label="Corresponding Value"
                                                                value={storingValues[row.id]}
                                                                onChange={(event) => handleVariableChange(event, row.id)}
                                                                error={checkVariableError(storingValues[row.id] || '')}
                                                            />
                                                            <ErrorText>{checkVariableError(storingValues[row.id] || '')}</ErrorText>
                                                        </FormControl>
                                                    </div>
                                                )}
                                                {
                                                    !row.description && row.description !== '' ? (
                                                        <div className="d-flex justify-content-end">
                                                            <Button size="small" onClick={() => handleAddDescription(index, rowIndex)}>Add Description</Button>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div className="mt-3">
                                                                <TextArea
                                                                    id="text-description"
                                                                    onChange={(e) => onHandleTextDescription(e, index, rowIndex)}
                                                                    label="Edit Description"
                                                                    minRows={3}
                                                                    placeholder="What's on your mind"
                                                                    variant="outlined" 
                                                                    value={initialButtonSections[index]?.rows[rowIndex]?.description || ''}
                                                                    autoComplete="off"
                                                                    error={checkDescriptionError(initialButtonSections[index]?.rows[rowIndex]?.description || '')}
                                                                    errorMessage={checkDescriptionError(initialButtonSections[index]?.rows[rowIndex]?.description || '')}
                                                                    onBlur={() => handleDescriptionBlur(index, rowIndex)}
                                                                    onAddVariable={() => {}}
                                                                />
                                                            </div>
                                                            <div onClick={() => handleDeleteDescription(index, rowIndex)} className="d-flex justify-content-end ml-2 mt-0 mb-3">
                                                                <Button variant="outlined" color="error" size="small" startIcon={<DeleteIcon />}>Description</Button>
                                                            </div>
                                                        </>
                                                    )
                                                }

                                                
                                            </div>
                                        ))}
                                        <div className="mt-2">
                                        <Button variant="contained" size="small" startIcon={<Plus />} 
                                            onClick={() => handleAddRow(index)}>
                                            Add Button
                                        </Button>
                                        </div>
                                        <hr />
                                    </React.Fragment>
                                ))}
                                
                            </CardContent>
                            <CardActions>
                                <Button onClick={handleAddSection} size="small">Add Section</Button>
                            </CardActions>
                        </Card>
                    </div>
                </AccordionDetails>
            </Accordion>
            {
                !isFooter ? 
                <div className="d-flex justify-content-start mt-3">
                    <Button variant="outlined" size="small" color="primary" onClick={openFooter}>+ Footer</Button>
                </div>
                : 
                <>
                    <FormControl size="small" className="mx-0 mt-3 d-flex justify-content-start" fullWidth>
                        <InputLabel htmlFor="footer">Footer</InputLabel>
                        <OutlinedInput
                            endAdornment={<InputAdornment position="end">
                                <Trash3 className="cursor-pointer" onClick={openFooter} />
                            </InputAdornment>}
                            id="footer"
                            label="Footer"
                            value={initialFooterValue} 
                            onChange={(e) => handleChangeFooter(e)} 
                            error={checkHeaderAndFooterError('Footer',initialFooterValue)}
                        />
                        <ErrorText error>{checkHeaderAndFooterError('Footer',initialFooterValue)}</ErrorText>
                    </FormControl>
                </>
            }
            <FormControl fullWidth size="small" style={{ marginTop: '20px'}}>
                <InputLabel id="select-variable">Store Response In</InputLabel>
                <Select
                    labelId="select-variable"
                    id="select-variable"
                    value={initialVariable ? initialVariable.name : ''}  // Set the value to the selected variable value from the state
                    label="Store Response In"
                    onChange={handleVariableList}
                >
                    <MenuItem value={null}>--Store Response In--</MenuItem>
                    {variableList.map((item, index) => <MenuItem className={item.is_global ? 'global_response' : ''} key={index} value={item.name}>{item.name} </MenuItem>)}
                </Select>
                <small className="mt-1">NOTE: Underlined values are global variables.</small>
            </FormControl>
        </div>
    )
}

export default ListEdit;