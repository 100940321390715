import React, { useEffect, useState } from "react";
import styles from '../../assets/scss/components/chats.module.scss';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { useRecoilState } from "recoil";
import { chatTypeState, msgStatusState, appliedFilterCountState, allChatsState, chatsDetailState, userNameState, chatNextURLState, selectedChatState, templatesState, isOptInState, openCampaignDialogBoxState, broadcastIdState, userCombinedIDState, webSocketState, chatIDState, chatsNextURLState } from '../../store/agent-chats';
import axiosInstance from '../../plugins/axios';
import CampaignIcon from '@mui/icons-material/Campaign';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import ChatCampaignDialog from './chat-campaign-dialog';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import UpdateIcon from '@mui/icons-material/Update';
import FilterListIcon from '@mui/icons-material/FilterList';
import InfiniteScrollObserver from '../Observer/observer';
import Badge from "@mui/material/Badge";
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';

const ChatsNumber = (props) => {
    const [ allChat, setAllChats ] = useRecoilState(allChatsState);
    const [ chatDetails, setChatDetails ] = useRecoilState(chatsDetailState);
    const [ username, setUserName ] = useRecoilState(userNameState);
    const [ nextURL, setNextURL ] = useRecoilState(chatNextURLState);
    const [ selectedChat, setSelectedChat ] = useRecoilState(selectedChatState);
    const [ openCampaignDialogBox, setOpenCampaignDialogBox ] = useRecoilState(openCampaignDialogBoxState);
    const [ broadcastId, setBroadcastId ] = useRecoilState(broadcastIdState);
    const [ templates, setTemplates ] = useRecoilState(templatesState);
    const [ isOptIn, setIsOptIn ] = useRecoilState(isOptInState);
    const [ combinedID, setUserCombinedID ] = useRecoilState(userCombinedIDState);
    const [ chatID, setID ] = useRecoilState(chatIDState);
    const [ chatsNextURL, setChatsNextURL ] = useRecoilState(chatsNextURLState)
    const [ isValidNo, setIsValidNo ] = useState(false);
    const [ number, setNumber ] = useState('');
    const [ name, setName ] = useState('');
    const [ searchValue, setSearchValue] = useState('');
    const [ chatType, setChatType ] = useRecoilState(chatTypeState);
    const [ msgStatus, setMsgStatus ] = useRecoilState(msgStatusState);
    const [ appliedFilterCount, setAppliedFilterCount ] = useRecoilState(appliedFilterCountState);
    const [ loading, setLoading ] = useState(false)

    //socket state
    const [socket, setSocket] = useRecoilState(webSocketState);

    const dev_key = process.env.REACT_DEV_AUTH_UUID;
    const prod_key = process.env.REACT_PROD_AUTH_UUID;

    useEffect(() => {
        console.log(broadcastId);
        setAppliedFilterCount((prevState) => ({
            ...prevState, 
            msgStatus: msgStatus === 'all' ? 0 : 1,
            chatType: chatType === 'all' ? 0 : 1,
            broadcast: broadcastId ? 1 : 0,
        }))
    },[broadcastId])

    const truncatedContent = (content) => {
        const formattedContent = content.replace(/\n/g, '');

        const truncatedContent = formattedContent.slice(0, 30);
        return truncatedContent.length < formattedContent.length ? `${truncatedContent}...` : truncatedContent;
    };

    const handleSearchValues = async (e) => {
        setLoading(true)
        try {
            const value = e.target.value;
            setSearchValue(value);
            const mobileRegex = /^[0-9]{6,16}$/;
            const nameRegex = /^[a-zA-Z\s]*$/;
            
            if (mobileRegex.test(value)) {
                setIsValidNo(false);
                setNumber(value);
                setName('');
            } 
            else{
                setIsValidNo(true);
                setNumber('');
            }
            
            if (nameRegex.test(value)) {
                setName(value);
                setNumber('');
                setIsValidNo(false);
            } 
            setLoading(false)   
        } 
        catch (error) {
            console.log(error)
            setIsValidNo(true);
            setLoading(false)
        }
    };

    const resetAll = async () => {
        setLoading(true)
        try{
            setIsValidNo(false);
            setNumber('');
            setName('');
            const response = await axiosInstance.get(`/api/v1/communications/whatsapp/all-chats/?being_handled_by=all`);
            const data = response.data;
            setBroadcastId(null);
            setChatsNextURL(data.next);
            setAllChats(data.results);
            setSearchValue('');
            setChatType('all');
            setMsgStatus('all')
            setAppliedFilterCount((prevState) => ({
                ...prevState, 
                msgStatus: 0,
                chatType: 0,
                broadcast: 0,
            }));
            setLoading(false)
        }
        catch(error){
            console.log(error)
            setLoading(false)
        }
    };

    // const handleChatTypes = async (e) => {
    //     try {
    //         setChatType(e.target.value);
    //         const response = await axiosInstance.get(`/api/v1/communications/whatsapp/all-chats/?being_handled_by=${e.target.value}${broadcastId && broadcastId.id ? `&campaign_id=${broadcastId.id}` : ''}`);

    //         const data = response.data;
    //         setAllChats(data.results);
    //         setChatsNextURL(data.next)
    //     } 
    //     catch (error) {
    //         console.log(error)
    //     }
    // };

    const handleKeywordSearch = async () => {
        try {
            const handle_by = chatType
            let query = ''
            if(number !== ''){
                query = `&number=${number}`
            }
            else if(name !== ''){
                query = `&name=${name}`
            }
            else{
                query = ''
            }
            const response = await axiosInstance.get(`/api/v1/communications/whatsapp/all-chats/?being_handled_by=${handle_by}${query}${broadcastId && broadcastId.id ? `&campaign_id=${broadcastId.id}` : ''}`);
            const data = response.data;
            setChatsNextURL(data.next);
            setAllChats(data.results);
        } 
        catch (error) {
            console.log(error)    
        }
    };

    const getChatsDetails = async (chat) => {
        try {
            const number = `${chat?.country_code.replace('-', '').replace('+','')}${chat?.number}`
            const name = chat?.data?.name
            const combined_id = chat?.data?.combined_data_id
            setID(chat.id);
            webSocketConnection(number);
            setSelectedChat(chat);
            const response = await axiosInstance.get(`/api/v1/communications/whatsapp/messages/${number}/`);
            if(response.status === 200 || response.status === 201){
                const data = response.data;
                setChatDetails(data.results);
                setNextURL(data.next);

                const response1 = await axiosInstance.get(`/api/v1/communications/whatsapp/template/list/${number}/?combined_data_id=${combined_id}`);
                const ressonse2 = await axiosInstance.get(`api/v1/communications/whatsapp/read-and-24-hours/${number}/`);

                setTemplates(response1.data.results);
                setIsOptIn(ressonse2.data);
            }
            
            name !== 'N/A' ? setUserName(name) : setUserName(number);
            setUserCombinedID(combined_id);

            props.scrollToBottom()
            // const scrollContainer = scrollContainerRef.current;
            // // if (scrollContainer) {
            // //     scrollContainer.scrollTop = scrollContainer.scrollHeight;
            // // }
        } 
        catch (error) {
            console.log(error)    
        }
    };

    const generateToken = async (mobileNumber, text, serverUUID) => {
        // Get today's date in the format YYYY-MM-DD
        const todayDate = new Date().toISOString().split('T')[0];

        // Concatenate today's date, mobile number, text, and server UUID
        const inputString = `${todayDate}${mobileNumber}${text}${serverUUID}`;

        // Convert the input string to a Uint8Array using TextEncoder
        const encoder = new TextEncoder();
        const data = encoder.encode(inputString);

        try {
            // Create a SHA-256 hash using the SubtleCrypto API
            const hashBuffer = await window.crypto.subtle.digest('SHA-256', data);

            // Convert the hash buffer to a hexadecimal string
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const token = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

            // Return the generated token
            return token;
        } catch (error) {
            console.error('Error generating token:', error);
            throw error;
        }
    };

    const webSocketConnection = async (number) => {
        try {
            let newSocket;
            let type = localStorage.getItem('project_type')?.toLowerCase();
            let uuid_v1 = type == 'dev' ? process.env.REACT_APP_DEV_AUTH_UUID : process.env.REACT_APP_PROD_AUTH_UUID;
            const token = await generateToken(number, type, uuid_v1);

            if(socket){
                socket.close();
            }

            newSocket = new WebSocket(`wss://realtime.ezyschooling.com/ws/v1/whatsapp/?user_number=${number}&type=${type}&auth=${token}`);
            newSocket.onmessage = (event) => {
                const receivedMessage = JSON.parse(event.data);
                if (receivedMessage) {
                    setChatDetails(prevChatDetails => [receivedMessage.message, ...prevChatDetails]);
                }
                const audioElement = document.getElementById('notification-sound');
                if (audioElement && receivedMessage.message.conversation_way=="incoming") {
                    audioElement.play();
                }
            };
            setSocket(newSocket);
        } 
        catch (error) {
            console.log(error);
        }
    };

    const openCampaignToSearch = () => {
        setOpenCampaignDialogBox(true);
    };

    const exportChats = async () => {
        try {
            axiosInstance.get(`/api/v1/communications/whatsapp/export-all-chats/?${broadcastId && broadcastId.id ? `campaign_id=${broadcastId.id}` : ''}`)
            .then(response => {
                const url = URL.createObjectURL(new Blob([response.data], {
                    type: 'text/csv'
                }))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'chats.csv')
                document.body.appendChild(link)
                link.click()
            })
        } 
        catch (error) {
            console.log(error)    
        }
    };

    const handleRefreshChats = async () => {
        setLoading(true)
        try {
            const response = await axiosInstance.get(`/api/v1/communications/whatsapp/all-chats/?being_handled_by=${chatType}&is_new_whatsapp_message=${msgStatus}${broadcastId && broadcastId.id ? `&campaign_id=${broadcastId.id}` : ''}`);

            const data = response.data;
            setAllChats(data.results);
            setLoading(false)
        } 
        catch (error) {
            console.log(error)  
            setLoading(false)  
        }
    };

    const fetchMoreData = async () => {
        try{
            console.log(chatsNextURL)
            if(chatsNextURL){
                const response = await axiosInstance.get(chatsNextURL?.replace('http://', 'https://'));
                const data = response.data;
                setAllChats([...allChat, ...data.results]);
                setChatsNextURL(data.next)
            }
        }
        catch(error){
            console.log(error)
        }
    }

    return(
        <div>
            <div className="d-flex align-items-center">
                <h3 className="flex-grow-1">Chats</h3>
                <Tooltip title="Refresh All Chats" arrow placement="top">
                    {/* <IconButton
                        onClick={handleRefreshChats}
                        className="mr-2"
                    >
                        <RefreshIcon />
                    </IconButton> */}
                    <Button 
                        variant="text" 
                        startIcon={<UpdateIcon />} 
                        onClick={handleRefreshChats}
                        >Refresh All
                    </Button>
                </Tooltip>

            </div>
            <div className={styles.search__keywords}>
                <input 
                    type="text" 
                    placeholder="Search keywords"
                    className={styles.search__input}
                    onChange={handleSearchValues}
                    value={searchValue}
                    onKeyDown={(e) => e.key === 'Enter' && handleKeywordSearch()}
                />
                <SearchRoundedIcon onClick={handleKeywordSearch} />
            </div>
            {isValidNo ? <small className={styles.error__message}>Please enter a valid number</small> : '' }
            <div className="mt-3 d-flex align-items-center justify-content-between">
                <Tooltip title="Chat Filters" arrow placement="top">
                    <Button 
                        variant="text" 
                        startIcon={
                            <Badge badgeContent={appliedFilterCount.msgStatus + appliedFilterCount.chatType + appliedFilterCount.broadcast} color="success">
                                <FilterListIcon />
                            </Badge>
                        } 
                        onClick={openCampaignToSearch}
                        >Filters
                    </Button>
                </Tooltip>
                <Tooltip title="Reset Filters" arrow placement="top">
                    <Button 
                        variant="text" 
                        startIcon={<RestartAltIcon className={styles.campaign__icon} />} 
                        onClick={resetAll}
                        >Reset
                    </Button>
                </Tooltip>
            </div>
            {
                broadcastId?.id ?
                <div className="d-flex justify-content-end">
                    <Tooltip title="Export handled by 'ALL' chats of selected campaign" arrow placement="top">
                        <Button 
                            variant="text" 
                            startIcon={<GetAppRoundedIcon className={styles.export__icon} />} 
                            onClick={exportChats}
                            className={styles.export__icon__button}
                        >
                            Export Chats
                        </Button>
                    </Tooltip>
                </div> : ''
            }
            {props.allChats.map((chat, index) => {
                return(
                    <div key={index}>
                        <div 
                            className={chatID === chat.id ? styles.chat_number__active__container :styles.chat__number__container} 
                            style={ chat.new_message ? { backgroundColor: '#ebfbee' } : {}} 
                            onClick={() => getChatsDetails(chat)}
                        >
                            <div className={styles.chat__number__first__container}>
                                {loading ? (
                                    <Skeleton animation="wave" variant="circular" sx={{ width: 30, height: 30 }} />
                                ) : (
                                    <p className={styles.chat__first__letter}
                                        style={{ backgroundColor: getRandomColor((chat?.data?.name !== 'N/A' && chat?.data?.name !== null) ? chat?.data?.name?.charAt(0)?.toUpperCase() : chat?.number?.charAt(0)) }}
                                    >
                                        {(chat?.data?.name !== 'N/A' && chat?.data?.name !== null) ? chat?.data?.name?.charAt(0)?.toUpperCase() : chat?.number?.charAt(0)}
                                    </p>
                                )}
                            </div>
                            <div className={styles.chat__number__second__container}>
                                <div className="d-flex justify-content-between">
                                    {loading ? (
                                        <React.Fragment>
                                            <Skeleton animation="wave" variant="text" sx={{ width: "50%", fontSize: 14 }} />
                                            <Skeleton animation="wave" variant="text" sx={{ width: "30%", fontSize: 10 }} />
                                        </React.Fragment>  
                                    ) : (
                                        <React.Fragment>
                                            <p 
                                                className={styles.chat__name} 
                                                style={ chat.new_message ? { fontWeight: 'bold' } : { fontWeight: 'normal'}}
                                                >
                                                {(chat.data.name !== 'N/A' && chat.data.name !== null) ? chat.data.name : chat?.number?.length === 12 && chat?.number?.startsWith("91") ? chat?.number?.substring(2) : chat?.number}
                                            </p>
                                            <small 
                                                className={styles.chat__time} 
                                                style={ chat.new_message ? { color: '#00a884', fontWeight: 'bold' } : {}}
                                                >
                                                {chat.message_time}
                                            </small>
                                        </React.Fragment>
                                    )}
                                </div>{}
                                <div className="d-flex gap-1 align-items-center mt-2">
                                    {loading ? (
                                        <React.Fragment>
                                            <Skeleton animation="wave" variant="circular" sx={{ width: 14, height: 14 }}>
                                                <DoneAllRoundedIcon/>
                                            </Skeleton>
                                            <Skeleton animation="wave" variant="text" sx={{ width: "100%", fontSize: 12 }} />
                                        </React.Fragment>  
                                    ) : (
                                        <React.Fragment>
                                            {   
                                                chat.message.status == 'pending' ? ( <TimerOutlinedIcon className={styles.status__icon} /> ) :
                                                chat.message.status == 'sent' ? ( <DoneRoundedIcon className={styles.status__icon} /> ) :
                                                (chat.message.status == 'delivered' || chat.message.status == 'success') ? ( <DoneAllRoundedIcon className={styles.status__icon} /> ) :
                                                chat.message.status == 'read' ? ( <DoneAllRoundedIcon className={styles.success_status__icon} /> ) : 
                                                chat.message.status == 'failed' ? ( <ErrorOutlineRoundedIcon className={styles.failed_status__icon} /> ) :
                                                ''
                                            }    
                                            <p className={styles.chat__content} style={ chat.new_message ? { fontWeight: 'bold' } : { fontWeight: 'normal'}}>{truncatedContent(chat.message.content)}</p>
                                        </React.Fragment>
                                    )}
                                </div>
                                
                            </div>
                        </div>
                    </div>
                )
            })}
            {props.allChats && props.allChats.length === 0 ? <div>No Numbers Found</div> : ''}
            <InfiniteScrollObserver onIntersect={fetchMoreData} />
            <ChatCampaignDialog handle_by={chatType} />
            <audio 
                id="notification-sound" 
                src="https://dwrqao8j794zb.cloudfront.net/for-static-use/4ac131d8-0779-11ef-8bd2-0da35c508ab3-cace1367-d7fb-4009-8bba-1141394b33b7-notification_sound.wav" 
                preload="auto" 
                className="d-none"  
            ></audio>
        </div>
    )
}

export default ChatsNumber;

const getRandomColor = (firstLetter) => {
    const colorMap = {
        A: "#FF5733",
        B: "#52a836",
        C: "#FF3366",
        D: "#31e0b5",
        E: "#CC33FF",
        F: "#FFCC33",
        G: "#33e4ff",
        H: "#3366FF",
        I: "#FF33CC",
        J: "#d42fc0",
        K: "#afe312",
        L: "#33CCFF",
        M: "#FF6633",
        N: "#33FF33",
        O: "#FF6633",
        P: "#0fccd6",
        Q: "#4091c7",
        R: "#a277e6",
        S: "#ffb833",
        T: "#33CC66",
        U: "#CC6633",
        V: "#3366CC",
        W: "#66CC33",
        X: "#CC3366",
        Y: "#4c9ec7",
        Z: "#ff6699",
        0: "#FF5733",
        1: "#52a836",
        2: "#FF3366",
        3: "#31e0b5",
        4: "#CC33FF",
        5: "#FFCC33",
        6: "#33e4ff",
        7: "#3366FF",
        8: "#FF33CC",
        9: "#d42fc0"
    }
    return colorMap[firstLetter?.toUpperCase()];
}