import React,{ useEffect, useState } from 'react'
import axiosInstance from '../../plugins/axios';
import Cookies from 'js-cookie';

const UserGuide = () => {
    const [guide, setGuide] = useState('');
    
    useEffect(async () => {
        const token = Cookies.get('Token');
            if(!token){
                localStorage.removeItem('Token');
                window.location.href = '/login'
            }
            else{
                await getGuide();   
            }
    }, [])
    const getGuide = async () => {
        try {
            const { data } = await axiosInstance.get('/communications/journey-guidelines/');
            setGuide(data);
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <div className=""></div>
            <div className="mt-2">
                {guide && <div dangerouslySetInnerHTML={{ __html: guide }} ></div>}
            </div>
        </>
    )
}

export default UserGuide
