import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import axiosInstance from '../plugins/axios';

const { persistAtom } = recoilPersist();

export const allChatsState = atom({
    key: 'allChats',
    default: [],
    // effects_UNSTABLE: [persistAtom],
});

export const chatsNextURLState = atom({
    key: 'chatsNextUrl',
    default: []
});

export const getAllChats = selector({
    key: 'getAllChatsData',
    get: async () => {
        const response = await axiosInstance.get(`/api/v1/communications/whatsapp/all-chats/?being_handled_by=all`);
        const data = response.data;
        return data;
    },
});

export const chatsDetailState = atom({
    key: 'chatsDetails',
    default: {},
    // effects_UNSTABLE: [persistAtom],
});

export const userNameState = atom({
    key: 'userName',
    default: '',
    // effects_UNSTABLE: [persistAtom],
});

export const chatNextURLState = atom({
    key: 'chatNextURL',
    default: null,
    effects_UNSTABLE: [persistAtom],
});

export const selectedChatState = atom({
    key: 'selectedChat',
    default: null,
})

export const openCampaignDialogBoxState = atom({
    key: 'openCampaignDialogBox',
    default: false,
})

export const broadcastIdState = atom({
    key: 'broadcastId',
    default: null,
})
export const templatesState = atom({
    key: 'templates',
    default: [],
    // effects_UNSTABLE: [persistAtom],
});

export const isOptInState = atom({
    key: 'isOptIn',
    default: false,
    // effects_UNSTABLE: [persistAtom],
});

export const userCombinedIDState = atom({
    key: 'userCombinedID',
    default: null,
});

export const webSocketState = atom({
    key: 'webSocket',
    default: null,
});

export const openChatDocumentDialogState = atom({
    key: 'openChatDocumentDialog',
    default: false,
});

export const chatIDState = atom({
    key: 'chatID',
    default: null,
});

export const chatTypeState = atom({
    key: 'chatType',
    default: 'all',
});

export const msgStatusState = atom({
    key: 'msgStatus',
    default: 'all',
});

export const appliedFilterCountState = atom({
    key: 'appliedFilterCount',
    default: {
        msgStatus: 0,
        chatType: 0,
        broadcast: 0,
    },
});
