import React from 'react'
import { useRecoilState } from 'recoil';
import { openCreateTemplateBoxState } from '../../store/template';
import Card from '@mui/material/Card';
import styles from '../../assets/scss/page/template-page.module.scss';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { confirmationDialogState } from '../../store/journey-list'
import { templateListState, hiddenState } from '../../store/template'
import axiosInstance from '../../plugins/axios';
import { AlertOpenState } from '../../store/journey-list';
import AlertDialog from '../../components/basic-components/alert-dialog';


const TemplateList = ({item, index}) => {
	const [openDialog, setOpenDialog] = useRecoilState(openCreateTemplateBoxState);
	const [confirm, setConfirm] = useRecoilState(confirmationDialogState);
	const [list, setList] = useRecoilState(templateListState);
	const [values, setValues] = useRecoilState(AlertOpenState);
	const [hidden, setHidden] = useRecoilState(hiddenState);

  	const query = new URLSearchParams(location.search);
		const page = parseInt(query.get('page') || '1', 10);
	
	const handleOpenEdit = () => {
		setOpenDialog({
			open: true,
			type: 'edit',
			title: 'Edit Template',
			id: item.id
		});
	}

	const handleHide = () => {
		setConfirm({ 
			open: true,
			title: 'Are you sure ?',
			message: `Please confirm that you want to hide this template.`,
			onConfirm: () => toggleHideTemplate(true),
			onCancel: () => setConfirm({ ...confirm,title: 'Are you sure ?', message: `Please confirm that you want to hide this template.`, open: false }),
		})
	}

	const handleUnhide = () => {
		setConfirm({ 
			open: true,
			title: 'Are you sure ?',
			message: `Please confirm that you want to unhide this template.`,
			onConfirm: () => toggleHideTemplate(false),
			onCancel: () => setConfirm({ ...confirm,title: 'Are you sure ?', message: `Please confirm that you want to unhide this template.`, open: false }),
		})
	}

	const toggleHideTemplate = async (is_hidden) => {
		const payload = {
			id: item.id,
			is_hidden: is_hidden
		}
		try {
			const response = await axiosInstance.patch('/api/v1/communications/whatsapp/campaigns/template/', payload);
			if (response.status === 200) {
				const updatedList = list.filter(element => element.id !== item.id);
				setList(updatedList)
				setConfirm({ ...confirm,title: 'Are you sure ?', message: `Please confirm that you want to hide this template.`, open: false })
			} else{
				setConfirm({ ...confirm,title: 'Are you sure ?', message: `Please confirm that you want to hide this template.`, open: false })
				setValues({ ...values, open: true, msgType: 'error', alertMsg: 'Something went wrong.' })
			}
		} catch (error) {
			setConfirm({ ...confirm,title: 'Are you sure ?', message: `Please confirm that you want to hide this template.`, open: false })
			setValues({ ...values, open: true, msgType: 'error', alertMsg: 'Something went wrong.' })
			console.log(error);
		}
	}

	return (
		<Card variant="outlined" className={styles.template__card}>
			<div className="">{((page-1)*20)+index+1}. {item.name}</div>
			<div className='d-flex'>
                <div className="text-end pe-3">
				{hidden ? 
                    <Tooltip title="Unhide template">
                        <IconButton onClick={handleUnhide}>
                            <VisibilityIcon className={styles.edit__icon} />
                        </IconButton>
					</Tooltip>
					:
					<Tooltip title="Hide template">
						<IconButton onClick={handleHide}>
                            <VisibilityOffIcon className={styles.edit__icon} />
                        </IconButton>
					</Tooltip>
					}
                </div>
                <div className="text-end">
                    <Tooltip title="Edit template">
                        <IconButton onClick={handleOpenEdit}>
                            <EditIcon className={styles.edit__icon} />
                        </IconButton>
                    </Tooltip>
                </div>
			</div>
			<AlertDialog/>
		</Card>
	)
}

export default TemplateList
