import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist'
// import axiosInstance from "../plugins/axios";

const { persistAtom } = recoilPersist();

export const templateListState = atom({
    key: 'templateList',
    default: [],
    effects_UNSTABLE: [persistAtom],
});

export const openCreateTemplateBoxState = atom({
    key: 'openCreateTemplateBox',
    default: {
        open: false,
        type: '',
        title: '',
        id: '',
    },
});

export const hiddenState = atom({
    key: 'hidden',
    default: false,
    effects_UNSTABLE: [persistAtom],
});