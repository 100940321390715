import Cookies from 'js-cookie';

const checkAuth = () => {
    const token = getCookie('Token');
    if(!token){
        localStorage.removeItem('Token');
        return false;
    }
    else{
        return true;
    }
};
  
const getCookie = (name) => {
    const value = Cookies.get(name);
    return value;
};

export default checkAuth;