import React, { useState, useEffect } from 'react'
import styles from '../../assets/scss/components/chats.module.scss'
import { useRecoilState } from "recoil";
import { openChatDocumentDialogState } from '../../store/agent-chats';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Button from '@mui/material/Button';
import InfiniteScrollObserver from '../Observer/observer';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const ChatDocumentModal = ({selectedNumber, attachmentFiles, fetchDocumentAPI, fetchNextAPI}) => {
    const [openChatDialog, setOpenChatDialog] = useRecoilState(openChatDocumentDialogState);

    const [openViewModal, setOpenViewModal] = useState({ open: false, file: null });
    const [tabs, setTabs] = useState('media');

    const handleClose = () => {
        setOpenChatDialog(false);
        setTabs('media');
    };

    const openModalToView = (file) => {
        setOpenViewModal({ open: true, file: file });
    }

    const handleViewClose = () => {
        setOpenViewModal({ open: false, file: null });
    }

    const handleTabChange = (event, newValue) => {
        setTabs(newValue);
        fetchDocumentAPI(newValue);
    }
    const fetchMoreData = () => {
        fetchNextAPI();
    }

    return (
        <React.Fragment>
            <Dialog
                open={openChatDialog}
                onClose={handleClose}
                maxWidth={'md'}
                aria-labelledby="chat-document"
                aria-describedby="chat-document-description"
            >
                <DialogTitle style={{ minWidth: '350px', marginBottom: 0, paddingBottom: 0 }} id="chat-document" className='d-flex justify-content-between'>
                    <span className="">Attachments</span>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Tabs
                        value={tabs}
                        onChange={handleTabChange}
                        aria-label="media and documents"
                        className='mb-2'
                        style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff' }}
                        >
                        <Tab value="media" label="Media" />
                        <Tab value="document" label="Documents" />
                    </Tabs>
                    <Grid container spacing={2}>
                        {attachmentFiles && attachmentFiles.length ? attachmentFiles?.map((file, index) => {
                            return (
                                <>
                                    <Grid xs={3} key={file?.id} style={{ cursor: 'pointer' }}>
                                        <Item className='w-100' onClick={() => openModalToView(file)}>
                                            {
                                                file?.msg_type === 'image' 
                                                    ? <img src={file?.media_url} alt={file?.id} width="100%" height="100%"/> 
                                                    : file?.msg_type === 'video' 
                                                    ? <video src={file?.media_url} width="100%" /> 
                                                    : file?.msg_type === 'audio' 
                                                    ? <audio src={file?.media_url} controls width="100%" /> 
                                                    : file?.msg_type === 'document' 
                                                    ?  <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                        <div style={{ width: '300px'}} className="d-flex justify-content-center align-items-center mt-3">
                                                            <DownloadForOfflineIcon style={{ width: '40px', height: '40px' }} />
                                                        </div>
                                                        <p className='mt-2'>{file?.conversation_way === 'outgoing' ? 'Sent' : 'Received'}</p>
                                                        <p className='mt-1 mb-1' style={{ width: '600px'}}>{file?.message_time}</p>
                                                    </div>
                                                    : <div className={styles.chat__empty}>No attachments found</div>
                                            }
                                        </Item>
                                    </Grid>
                                </>
                            )
                        })
                        : <div className='m-3'>No attachments found</div>
                    }
                        <InfiniteScrollObserver onIntersect={fetchMoreData} />
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openViewModal.open}
                onClose={handleViewClose}
                maxWidth={'lg'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle style={{ minWidth: '650px' }} id="chat-document" className='d-flex justify-content-between'>
                    <div className="">{openViewModal?.file?.conversation_way === 'outgoing' ? 'Sent' : 'Received'}  
                    <small> ({openViewModal?.file?.message_time})</small>
                    </div>
                    <IconButton onClick={handleViewClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {
                        openViewModal?.file && (
                            <>
                                <div>
                                    {
                                        openViewModal?.file.msg_type === 'image' ? <img src={openViewModal?.file.media_url} width="100%" alt={openViewModal?.file?.id} /> : openViewModal?.file?.msg_type === 'video' ? <video src={openViewModal?.file?.media_url} controls width="100%" /> : openViewModal?.file?.msg_type === 'audio' ? <audio src={openViewModal?.file?.media_url} controls width="100%" /> : openViewModal?.file?.msg_type === 'document' ? <>
                                            <a href={openViewModal?.file?.media_url} target='_blank' download>
                                                <Button variant="contained">Download Attachment</Button>
                                            </a>
                                            <iframe className='mt-2' src={openViewModal?.file?.media_url} width="100%" height="800px"></iframe>
                                        </> : <div>No Attachment Available</div>
                                    }
                                </div>
                            </>
                        )
                    }
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default ChatDocumentModal;