import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const graphState = atom({
    key: 'graphValues',
    default: {},
    effects_UNSTABLE: [persistAtom],
});

export const uDataState = atom({
    key: 'uData',
    default: [0,0,0,0,0,0,0,0],
    effects_UNSTABLE: [persistAtom],
});

export const pDataState = atom({
    key: 'pData',
    default: [0,0,0,0,0,0,0,0],
    effects_UNSTABLE: [persistAtom],
});

export const xLabelsState = atom({
    key: 'xLabels',
    default: [0,0,0,0,0,0,0,0],
    effects_UNSTABLE: [persistAtom],
});