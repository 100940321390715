import React from "react";
import { Fonts, Image, List, Reply, CameraVideo, FileEarmarkText } from 'react-bootstrap-icons';
import "../../assets/css/sidebar.css";


export default () => {

	const onDragStart = (event, nodeType) => {
		event.dataTransfer.setData("application/reactflow", nodeType);
		event.dataTransfer.effectAllowed = "move";
	};
	

	
	return (
		<aside>
			<div className="description">
				Messages
			</div>
			<div className="row p-2">
				<div className="col-4 box_size text" 
					onDragStart={ (event) => onDragStart(event, "text") }
					draggable>
					<Fonts color="royalblue" size={ 24 } className='d-block' />
					<small className='text-muted box-text pt-1'>Text</small>
				</div>
				<div className="col-4 box_size image"
					onDragStart={ (event) => onDragStart(event, "image") }
					draggable>
					<Image color="royalblue" size={ 23 } className='d-block' />
					<small className='text-muted box-text pt-1'>Image</small>
				</div>
				<div className="col-4 box_size reply_list" 
					onDragStart={(event) => onDragStart(event, "reply_list")}
					draggable>
					<List color="royalblue" size={23} className='d-block' />
					<small className='text-muted box-text pt-1'>List</small>
				</div>
				<div className="col-4 box_size reply_btn" 
					onDragStart={(event) => onDragStart(event, "reply_button")}
					draggable>
					<Reply color="royalblue" size={23} className='d-block' />
					<small className='text-muted box-text pt-1'>Reply Button</small>
				</div>
				<div className="col-4 box_size image"
					onDragStart={ (event) => onDragStart(event, "video") }
					draggable>
					<CameraVideo color="royalblue" size={ 23 } className='d-block' />
					<small className='text-muted box-text pt-1'>Video</small>
				</div>
				<div className="col-4 box_size image"
					onDragStart={ (event) => onDragStart(event, "document") }
					draggable>
					<FileEarmarkText color="royalblue" size={ 23 } className='d-block' />
					<small className='text-muted box-text pt-1'>Documents</small>
				</div>

				{/* <Button variant="contained" size="small" 
					onClick={savePayload}>
					Save
				</Button> */}

				{/* <div
					className="dndnode"
					onDragStart={ (event) => onDragStart(event, "default") }
					draggable
				>
					Default Node
				</div>
				<div
					className="dndnode output"
					onDragStart={ (event) => onDragStart(event, "output") }
					draggable
				>
					Output Node
				</div> */}
			</div>
		</aside>
	);
};
