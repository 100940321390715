	import React from 'react';
	import CreateJourney from './pages/create-journey/createJourney';
	import { createBrowserRouter, RouterProvider } from "react-router-dom";
	import ErrorPage from './components/error-page/error-page';
	import HomePage from './components/homepage/home-page';
	import JourneyList from './pages/journey-list/journey-list';
	import VariablesPage from './pages/variables/variables-page';
	import ModelList from './pages/model-list/model-list';
	import JourneyDetails from './pages/journey-list/journetDetails';
	import UserGuide from './pages/guide/user-guide';
	import Layout from './components/layout/Layout';
	import LoginPage from './pages/login/login-page';
	import IntentDetails from './components/AI-models/intent-details';
	import TemplatePage from './pages/campaigns/template-page'
	import OptIns from './pages/campaigns/optins-page';
	import EditOptIn from './pages/campaigns/edit-optins';
	import Broadcasts from './pages/campaigns/broadcasts';
	import CampaignGuide from './pages/guide/campaign-guide';
	import IndentGuide from './pages/guide/intent-guide';
	import { createTheme } from '@mui/material/styles';
	import { ThemeProvider } from '@mui/material/styles';
	import ViewBroadcast from './pages/campaigns/view-broadcast';
	import Chats from './pages/agent-assist/chats';
	import JourneyAnalytics from './pages/journey-list/journey-analytics';

	const theme = createTheme({
	palette: {
		primary: {
			extraLight: '#e7e6ff',
			light: '#cccdf4',
			main: '#3f50b5',
			dark: '#5956D6',
			contrastText: '#fff',
		},
		secondary: {
			light: '#ff7961',
			main: '#f44336',
			dark: '#ba000d',
			contrastText: '#000',
		},
	},
	});

	const router = createBrowserRouter([
	{
		path: "/",
		element: <Layout />, // Use Layout component for sidebar
		errorElement: <ErrorPage />,
		children: [
			{
				path: "",
				element: <HomePage />
			},
			{
				path: "/create-journey",
				element: <CreateJourney />,
			},
			{
				path: "/journey-list/",
				element: <JourneyList />,
			},
			{
				path: "/journey/analytics/:uuid",
				element: <JourneyAnalytics />,
			},
			{
				path: "/variables",
				element: <VariablesPage />,
			},
			{
				path: "/model-list",
				element: <ModelList />,
			},
			{
				path: 'model-list/:id',
				element: <IntentDetails />,
			},
			{
				path: "ai-intent-guide",
				element: <IndentGuide />,
			},
			{
				path: "journey-list/:uuid",
				element: <JourneyDetails />,
			},
			{
				path: "/user-guide",
				element: <UserGuide />,
			},
			{
				path: "/login",
				element: <LoginPage />
			},
			{
				path: "/templates",
				element: <TemplatePage />
			},
			{
				path: "/opt-ins",
				element: <OptIns />
			},
			{
				path: "/opt-ins/:id",
				element: <EditOptIn />
			},
			{
				path: "/broadcasts",
				element: <Broadcasts />
			},
			{
				path: "/broadcasts/:id",
				element: <ViewBroadcast />
			},
			{
				path: "/campaign-guide",
				element: <CampaignGuide />
			},
			{
				path: "/agent-chats",
				element: <Chats />
			},
			
		],
	},
	]);

	export default function App() {

	return (
		<ThemeProvider theme={theme}>
			<RouterProvider router={router} />
		</ThemeProvider>
	);
	}
