import React from 'react'
import styles from '../../assets/scss/components/journey-status.module.scss';
import { Whatsapp } from 'react-bootstrap-icons'
import { useTheme } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import GroupIcon from '@mui/icons-material/Group';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PercentIcon from '@mui/icons-material/Percent';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SmsIcon from '@mui/icons-material/Sms';
import MapIcon from '@mui/icons-material/Map';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import AddchartIcon from '@mui/icons-material/Addchart';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const JourneyStatus = ({item}) => {
    const theme = useTheme();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [type, setType] = React.useState(null);
    const [location, setLocation] = React.useState(null);

    const handleCloseDemographicsDialog = () => {
        setOpenDialog(false);
        setType(null);
        setLocation(null);
    };

    const formatTime = (timeObject) => {
        const days = timeObject?.days ? `${timeObject.days}d ` : '';
        const hours = timeObject?.hours ? `${timeObject.hours}h ` : '';
        const minutes = timeObject?.minutes ? `${timeObject.minutes}m ` : '';
        const seconds = timeObject?.seconds ? `${timeObject.seconds}s` : '';
    
        return `${days}${hours}${minutes}${seconds}`.trim() || '0s';
    };

    const openDemographicsDialog = (type) => {
        setOpenDialog(true);
        setType(type);
        setLocation(item?.user_demographics[type]);
    }
    
    return (
        <>
            <div className={styles.analytics__grid}>
                <div className={styles.analytics__source}>
                    Whatsapp <Whatsapp className='' color='#4bcb59' />
                </div>
                <div className={styles.broadcast__status}>
                    <div className=''>
                        <div className="status_card mt-2">
                            <div className='d-flex p-2 justify-content-between align-items-center'>
                                <div className={styles.analytics__number}>{item?.unique_users}</div>
                                <div className={styles.analytics__icon}>
                                    <GroupIcon  style={{ color: theme.palette.primary.dark, fontSize: '2.5rem', backgroundColor: theme.palette.primary.extraLight, borderRadius: '50%', padding: '4px'}} />
                                </div>
                            </div>
                            <div className={styles.analytics__title}>
                                Journey Triggered to
                            </div>
                        </div>
                        <div className="status_card mt-2">
                            <div>
                                <div className='d-flex p-2 justify-content-between align-items-center'>
                                    <div className={styles.analytics__date}>
                                        {formatTime(item?.average_time_to_complete)}
                                    </div>
                                    <div className={styles.analytics__icon}>
                                        <AccessTimeIcon style={{ color: theme.palette.primary.dark, fontSize: '2.5rem', backgroundColor: theme.palette.primary.extraLight, borderRadius: '50%', padding: '4px'}} />
                                    </div>
                                </div>
                                <div className={styles.analytics__title}>
                                    Avg time to complete
                                </div>
                            </div>
                        </div>
                        <div className="status_card mt-2">
                            <div>
                                <div className='d-flex p-2 justify-content-between align-items-center'>
                                    <div className={styles.analytics__number}>{item?.messages_sent}</div>
                                    <div className={styles.analytics__icon}>
                                        <SmsIcon style={{ color: theme.palette.primary.dark, fontSize: '2.5rem', backgroundColor: theme.palette.primary.extraLight, borderRadius: '50%', padding: '4px'}} />
                                    </div>
                                </div>
                                <div className={styles.analytics__title}>
                                    Total Message Sent
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className=''>
                        <div className="status_card mt-2">
                            <div>
                                <div className='d-flex p-2 justify-content-between align-items-center'>
                                    <div className={styles.analytics__number}>{item?.average_messages_per_user ? item?.average_messages_per_user?.toFixed(2) : '-'}</div>
                                    <div className={styles.analytics__icon}>
                                        <AddchartIcon style={{ color: '#ff9900', fontSize: '2.5rem', backgroundColor: '#ffffe8', borderRadius: '50%', padding: '4px'}} />
                                    </div>
                                </div>
                                <div className={styles.analytics__title}>
                                    Avg messages sent/User
                                </div>
                            </div>
                        </div>

                        <div className="status_card mt-2">
                            <div>
                                <div className='d-flex p-2 justify-content-between align-items-center'>
                                    <div className={styles.analytics__number}>
                                        {formatTime(item?.median_time_to_complete)}
                                    </div>
                                    <div className={styles.analytics__icon}>
                                        <AccessTimeIcon style={{ color: '#ff9900', fontSize: '2.5rem', backgroundColor: '#ffffe8', borderRadius: '50%', padding: '4px'}} />
                                    </div>
                                </div>
                                <div className={styles.analytics__title}>
                                    Median time to complete
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                    <div className=''>
                        <div className="status_card mt-2">
                            <div className='d-flex p-2 justify-content-between align-items-center'>
                                <div className={styles.analytics__number}>{item?.completed_journey_unique_users}</div>
                                <div className={styles.analytics__icon}>
                                    <HowToRegIcon style={{ color: '#4bcb59', fontSize: '2.5rem', backgroundColor: '#e8f8e8', borderRadius: '50%', padding: '4px'}} />
                                </div>
                            </div>
                            <div className={styles.analytics__title}>
                                Total Completed Users
                            </div>
                        </div>
                        
                        <div className="status_card mt-2">
                            <div>
                                <div className='d-flex p-2 justify-content-between align-items-center'>
                                    <div className={styles.analytics__number}>{100 - (item?.drop_off_rate.replace('%', ''))}%</div>
                                    <div className={styles.analytics__icon}>
                                        <PercentIcon style={{ color: '#4bcb59', fontSize: '2.5rem', backgroundColor: '#e8f8e8', borderRadius: '50%', padding: '4px'}} />
                                    </div>
                                </div>
                                <div className={styles.analytics__title}>
                                    Completion Rate
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                    
                        <div className="status_card mt-2">
                            <div>
                                <div className='d-flex p-2 justify-content-between align-items-center'>
                                    <div className={styles.analytics__number}>{item?.total_users_left_journey_inbetween}</div>
                                    <div className={styles.analytics__icon}>
                                        <PersonRemoveIcon  style={{ color: '#ff0000', fontSize: '2.5rem', backgroundColor: '#ffe8e8', borderRadius: '50%', padding: '4px'}} />
                                    </div>
                                </div>
                                <div className={styles.analytics__title}>
                                    Total Dropped of Users
                                </div>
                            </div>
                        </div>
                        <div className="status_card mt-2">
                            <div>
                                <div className='d-flex p-2 justify-content-between align-items-center'>
                                    <div className={styles.analytics__number}>{item?.drop_off_rate}</div>
                                    <div className={styles.analytics__icon}>
                                        <PercentIcon style={{ color: '#ff0000', fontSize: '2.5rem', backgroundColor: '#ffe8e8', borderRadius: '50%', padding: '4px'}} />
                                    </div>
                                </div>
                                <div className={styles.analytics__title}>
                                    Dropped off Rate
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
                {/* <div className={styles.broadcast__status}>
                    <div className="status_card download_card mt-2">
                        <div className='d-flex p-2 justify-content-between align-items-center cursor-pointer' onClick={downloadFile}>
                            <div style={{color: '#3f3f3f', fontWeight: '600'}}>DOWNLOAD</div>
                            <CloudDownloadIcon style={{ color: theme.palette.primary.dark, fontSize: '2.5rem', backgroundColor: theme.palette.primary.extraLight, borderRadius: '50%', padding: '6px'}} />
                        </div>
                        <div className={styles.analytics__title}>
                            Attached File
                        </div>
                    </div>
                </div> */}
            </div>
            <div className={styles.analytics__grid}>
                <div className="" style={{color: '#3f3f3f', fontWeight: '600'}}>User Demographics</div>
                <div className="mt-2 row" style={{ margin: '0px'}}>
                    <div className="status_card mt-2 col cursor-pointer" onClick={() => openDemographicsDialog('city')} style={{marginRight: '10px'}}>
                        <div>
                            <div className='d-flex p-2 justify-content-between align-items-center'>
                                <div className={styles.analytics__number}>View</div>
                                <div className={styles.analytics__icon}>
                                    <MapIcon style={{ color: 'orange', fontSize: '2.5rem', backgroundColor: '#ffe8e8', borderRadius: '50%', padding: '4px'}} />
                                </div>
                            </div>
                            <div className={styles.analytics__title}>
                                City/ Type
                            </div>
                        </div>
                    </div>
                    <div className="status_card mt-2 col cursor-pointer" onClick={() => openDemographicsDialog('district')} style={{marginRight: '10px'}}>
                        <div>
                            <div className='d-flex p-2 justify-content-between align-items-center'>
                                <div className={styles.analytics__number}>View</div>
                                <div className={styles.analytics__icon}>
                                    <LocationCityIcon style={{ color: 'orange', fontSize: '2.5rem', backgroundColor: '#ffe8e8', borderRadius: '50%', padding: '4px'}} />
                                </div>
                            </div>
                            <div className={styles.analytics__title}>
                                District/ State
                            </div>
                        </div>
                    </div>
                    <div className="status_card mt-2 col cursor-pointer" onClick={() => openDemographicsDialog('district_region')}>
                        <div>
                            <div className='d-flex p-2 justify-content-between align-items-center'>
                                <div className={styles.analytics__number}>View</div>
                                <div className={styles.analytics__icon}>
                                    <PersonPinCircleIcon style={{ color: 'orange', fontSize: '2.5rem', backgroundColor: '#ffe8e8', borderRadius: '50%', padding: '4px'}} />
                                </div>
                            </div>
                            <div className={styles.analytics__title}>
                                District Region/ City
                            </div>
                        </div>
                    </div>
                    {/* <div className="">
                        City: <Button>View</Button>
                    </div>
                    <div className="">
                        District: <Button>View</Button>
                    </div>
                    <div className="">
                        District Region: <Button>View</Button>
                    </div> */}
                </div>
            </div>
            <Dialog
                open={openDialog}
                onClose={handleCloseDemographicsDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {type === 'city' ? 'City/ Type' : type === 'district' ? 'District/ State' : 'District Region/ City'}
                </DialogTitle>
                <DialogContent sx={{ width: '500px', maxHeight: '400px', overflowY: 'scroll' }}>
                    <table className="table table-striped" style={{width: '100%'}}>
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Location</th>
                                <th scope="col">Users</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                location && Object.entries(location).map(([key, value], index) => (
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{key === 'null' ? 'Others' : key}</td>
                                        <td>{value}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDemographicsDialog} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
  )
}

export default JourneyStatus
