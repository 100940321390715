import React from "react";
import styles from '../../assets/scss/components/variables.module.scss';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import Tooltip from '@mui/material/Tooltip';
import EditAddModel from '../../components/variables/edit-add-model';
import { useRecoilState } from 'recoil';
import { editAddModelState } from '../../store/variables'

const LocalVariable = (props) => {
    const [values, setValues] = useRecoilState(editAddModelState);

    const openEditModel = (item) => {
        let obj = {
            open: true,
            title: 'Edit Variable',
            formValues: {
                var_name: item.name,
                var_key: item.storage_key,
                id: item.id
            },
            type: 'edit'
        }
        setValues(obj);
    }

    return (
        <div className={styles.local__variable}>
            <div className="d-flex align-items-center mb-3">
                <h5 className="flex-grow-1">Local Variable</h5>
                <button 
                    className={styles.add__btn}
                    onClick={() => setValues({...values, open: true, title: 'Add Variable', formValues: {var_name: '', var_key: '', id: ''}, type: 'add'})}
                >
                    Add Variable
                </button>
            </div>
            {   props?.local?.length ?
                props.local.map((variable, index) => {
                    return (
                        <div key={index} className={styles.variable__item}>
                            <p className="mb-0 flex-grow-1">{variable.name}</p>
                            <Tooltip title="Edit Variable" arrow placement="top">
                                <ModeEditOutlinedIcon  
                                    className={styles.variable__item__icons} 
                                    onClick={() => openEditModel(variable)}
                                />
                            </Tooltip>
                        </div>
                    )
                }) :
                <p>No Local Variables Found</p>
            }
            <EditAddModel />
        </div>
    )    
}

export default LocalVariable;