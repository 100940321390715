import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist'
// import axiosInstance from "../plugins/axios";

const { persistAtom } = recoilPersist();

export const optinsListState = atom({
    key: 'optinsList',
    default: [],
    effects_UNSTABLE: [persistAtom],
});


export const openCreateOptinsBoxState = atom({
    key: 'openCreateOptinsBox',
    default: {
        open: false,
        title: '',
        id: '',
    },
});