import React, { useEffect, useState } from "react";
import { useRecoilState } from 'recoil';
import { editTypesState, nodesState } from '../../store/state';
import { variableListState } from '../../store/journey-list';
import TextArea from "../ui-components/text-area";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { Trash3 } from 'react-bootstrap-icons'
import { styled } from '@mui/system';
import { debouncedHandleText, handleVariableListDebounce} from '../../mixins/journey'
import { checkMessageError, checkHeaderAndFooterError} from '../../mixins/errors'

const ErrorText = styled('p')`
  color: red;
  font-size: 0.75rem;
  margin-bottom: 0px;
`;

const TextEdit = () => {
    const [editTypes, setEditTypes] = useRecoilState(editTypesState);
    const [nodes, setNodes] = useRecoilState(nodesState);
    const [initialValue, setInitialValue] = useState('');
    const [initialVariable, setInitialVariable] = useState(null);
    const [variableList, setVariableList] = useRecoilState(variableListState);
    
    const findIndex = nodes.findIndex(item => item.id === editTypes.selected_id);

    useEffect(() => {
        const findIndex = nodes.findIndex(item => item.id === editTypes.selected_id);
        if (findIndex !== -1) {
            setInitialValue(nodes[findIndex].message_to_send.template.message);
            setInitialVariable(nodes[findIndex].message_to_send.store_response_in);
        }
    }, [editTypes.selected_id]);



    const onHandleText = (e) => {
        const { value } = e.target;
        setInitialValue(value);
        debouncedHandleText(findIndex, nodes, setNodes)(value); 
    }


    const handleVariableList = (e) => {
        const { value } = e.target;
        const item = variableList.find(item => item.name === value)
        setInitialVariable(item);
        handleVariableListDebounce(findIndex, nodes, setNodes)(item);
    }

    const handleAddVariable = (newValue) => {
        setInitialValue(newValue);
    };

    return (
        <>  
            <div className="">
                <InputLabel id="select-event" style={{marginTop: '10px', fontWeight: 'bold', fontSize: '14px', marginBottom: '4px', marginLeft: '4px'}}>MESSAGE</InputLabel>
                <TextArea
                    id="text-basic"
                    onChange={onHandleText}
                    label="Edit Text"
                    minRows={3}
                    placeholder="What's on your mind"
                    variant="outlined" 
                    value={initialValue}
                    autoComplete="off"
                    error={checkMessageError(initialValue)}
                    errorMessage={checkMessageError(initialValue)}
                    onAddVariable={handleAddVariable}
                    showVariable={true}
                />

            </div>
            <FormControl fullWidth size="small" className="mt-4">
                <InputLabel id="select-variable">Store Response In</InputLabel>
                <Select
                    labelId="select-variable"
                    id="select-variable"
                    value={initialVariable ? initialVariable.name : ''}  
                    label="Store Response In"
                    onChange={handleVariableList}
                >
                    <MenuItem value={null}>--Store Response In--</MenuItem>
                    {variableList.map((item, index) => <MenuItem className={item.is_global ? 'global_response' : ''}  key={index} value={item.name}>{item.name}</MenuItem>)}
                </Select>
                <small className="mt-1">NOTE: Underlined values are global variables.</small>
            </FormControl>
        </>
    )
}

export default TextEdit;
