import { Handle, Position } from 'reactflow';
import React, { useState } from 'react';
import '../../assets/css/text.css';
import { useRecoilState } from 'recoil';
import { Trash3 } from 'react-bootstrap-icons';
import {
  editBarOpenState,
  editTypesState,
  nodesState,
  edgesState,
} from '../../store/state';
import { handleDeleteDebounced } from '../../mixins/journey'

function Text({ isConnectable, nodeId }) {
	const [editBarOpen, setEditBarOpen] = useRecoilState(editBarOpenState);
	const [editTypes, setEditTypes] = useRecoilState(editTypesState);
	const [nodes, setNodes] = useRecoilState(nodesState);
	const [edges, setEdges] = useRecoilState(edgesState);
	const [showDeleteButton, setShowDeleteButton] = useState(false);

	const opensideBar = () => {
		setEditBarOpen(!editBarOpen);
		setEditTypes({ selected_id: nodeId, selected_type: 'text' });
	};

	const handleMouseEnter = () => {
		setShowDeleteButton(true);
	};

	const handleMouseLeave = () => {
		setShowDeleteButton(false);
	};

	const handleDelete = (event) => {
		event.stopPropagation();
		handleDeleteDebounced(nodes, setNodes, edges, setEdges, nodeId)();
	};  
	

  // Get the text value from the nodes state based on nodeId
  const nodeText =
    nodes.find((node) => node.id === nodeId)?.message_to_send?.template
      ?.message || 'What’s on your mind?';

  return (
    <>
		<div
			className='text-updater-node'
			style={{ maxWidth: '300px' }}
			onClick={opensideBar}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<Handle
			type='target'
			position={Position.Left}
			isConnectable={isConnectable}
			id='a'
			/>
			<div>
			<div className='heading__div'>
				<h1 className='heading-text'>Text</h1>
			</div>
			<div className='content__div' style={{textAlign: 'left'}} dangerouslySetInnerHTML={{ __html: nodeText.replace(/\n/g, '<br>') }} />
			</div>
		</div>
		<Handle
			type='source'
			position={Position.Right}
			id='b'
			style={{
			backgroundColor: '#5956d6',
			border: '1px solid #5956d6',
			padding: '5px',
			position: 'absolute',
			right: '-8px',
			top: '50%',
			transform: 'translateY(-50%)',
			boxShadow: '0 0 0 0.25rem #c5cad9',
			zIndex: '1',
			}}
		/>
		<Handle
			id='fallback'
			type='source'
			position={Position.Right}
			style={{
				backgroundColor: '#ff3964',
				border: '1px solid #ff3964',
				padding: '5px',
				position: 'absolute',
				right: '-6px',
				top: '90%',
				transform: 'translateY(-50%)',
				boxShadow: '0 0 0 0.25rem #c5cad9',
			}}
		/>
		{showDeleteButton && (
			<div
			className='deleteButton'
			onClick={handleDelete}
			onMouseEnter={() => setShowDeleteButton(true)}
			onMouseLeave={() => setShowDeleteButton(false)}
			>
			<Trash3 color='#8c8ace' size={20} className='' />
			</div>
		)}
    </>
  );
}

export default Text;
